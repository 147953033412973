import { FaUpload } from 'react-icons/fa';
import { CLOUD_URL } from 'constants.js';
import './tree.scss';
import UUID from 'uuid/v4';
import i18n from 'i18n/config';
import { post } from 'utils/fetch';
import keyboardEvents from '../utils/keyboard';
import { historyPush, save } from '../utils/history';

export const nodeTemplate = {
  shortcuts: [],
  children: [],
  rows: null,
  collumns: null,
  pageMode: 'item',
  states: [
    {
      index: 0,
      image1: 'communicate',
      writtenText: 'Novo estado',
      spokenText: 'Novo estado',
      onlyText: false,
    },
  ],
};

export default function NewItem({ functions }) {
  if (
    functions.currentItem.isMock === true ||
    functions.mode !== 'edit' ||
    functions.currentItem.isBookMode
  ) {
    return null;
  }

  const itemsCount = functions.currentItem.children.length;

  const newNode = (e, data) => {
    if (e) e.stopPropagation();
    historyPush(functions);
    const parent = functions.currentItem;

    const newNodeData = data || { id: UUID(), ...nodeTemplate };
    newNodeData.parent = parent;
    parent.children.push(newNodeData);
    // Why I need to do this:
    newNodeData.children = [];
    functions.setEdit(newNodeData);
    functions.setSelectedState(newNodeData.states[0]);

    save(functions);
  };

  const fileUpload = (e) => {
    const file = e.target.files[0];
    const url = `${CLOUD_URL}/content/${functions.uuid}/zip`;
    const formData = new FormData();
    formData.append('file', file);

    post(url, formData, 'multipart/form-data').then((res) => {
      functions.addParentReference(res.data, null);
      newNode(null, res.data);
    });
  };

  const captureKeyEvents = (evt) => {
    keyboardEvents(evt, functions, newNode);
  };

  return (
    <div className="item">
      <li
        className="new-item"
        onClick={(e) => newNode(e)}
        tabIndex="0"
        onKeyDownCapture={(e) => captureKeyEvents(e)}
        data-row={Math.floor(itemsCount / functions.settings.collumns)}
        data-collumn={itemsCount % functions.settings.collumns}
      >
        <div
          className="image"
          style={{
            backgroundImage: `url(https://storage.googleapis.com/livox-images/full/plus_sign.png`,
          }}
          alt={i18n.t('New Item')}
        />
        <span> {i18n.t('New Item')} </span>
        <form
          className="delete upload hidden"
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => fileUpload(e)}
        >
          <label htmlFor="upload-zip-inside">
            <FaUpload title={i18n.t('Upload from .zip file here')} />
          </label>
          <input
            className="hidden"
            type="file"
            id="uploadZip"
            accept="application/zip"
          />
        </form>
      </li>
    </div>
  );
}
