import i18n from 'i18n/config';

const isDevelopment =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const DEFAULT_URL = isDevelopment
  ? `http://${window.location.hostname}:8000/api`
  : `${window.location.origin}/api`;

export const CLOUD_URL = isDevelopment
  ? `http://${window.location.hostname}:3001/cloud`
  : `${window.location.origin}/cloud`;

export const GENDER_OPTIONS = [
  { value: 'M', label: i18n.t('Male') },
  { value: 'F', label: i18n.t('Female') },
];

export const LICENSES_STATUS_OPTIONS = [
  { value: 1, label: i18n.t('Available') },
  { value: 2, label: i18n.t('Active') },
  { value: 3, label: i18n.t('Inactive') },
];