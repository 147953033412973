import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stepper, Picker } from './Interface';
import { AppContext } from '../Reducer';


export default function DisplaySettings() {
    const { holder } = React.useContext(AppContext);
    const { t } = useTranslation();

    // const [orientation, setOrientation] = useState(getOrientation());
    // const labels = orientation === "landscape" ? ["Collumns", "Rows"] : ["Rows", "Collumns"];
    /*
    function getOrientation() {
        return window.innerWidth > window.innerHeight ? "landscape" : "portrait";
    }
    */

    window.onorientationchange = function () {
        // setOrientation(getOrientation());
    };

    const pageModes = {
        "item": "Inside items",
        // "swipe": "Swipe (WIP)",
        "fixed": "Fixed",
    }

    // const textPositionOptions = [{
    //     label: "Above Image",
    //     value: "top"
    // }, {
    //     label: "Below Image",
    //     value: "bottom"
    // }]

    const pageOptions = Object.entries(pageModes).map(([value, label]) => ({ label, value }))

    return (
        <div className="display-settings settings-section">
            <div className="title-bar">
                <h2> {t('Display')} </h2>
            </div>
            <div className="content">
                <Stepper label="Rows" attribute="rows" upperLimit={10} lowerLimit={2} />
                <Stepper label="Collumns" attribute="collumns" upperLimit={10} lowerLimit={2} />
                <Stepper label="Font Size" attribute="cardFontSize" increment={2} upperLimit={36} lowerLimit={16} />
                <Picker label="Paging Mode" attribute="pageMode" options={pageOptions} />
                {/* <Toggler label={"Hide All Images"} 
                    description={"Can be toggled on to help language literacy process and training."}
                attribute={"hideImages"} options={textPositionOptions}/>

                <Picker label={"Text Position"} attribute={"textPosition"} options={textPositionOptions}/> */}
            </div>
            <div className="preview">
                <h3> {t('Preview')} </h3>
                <div className="item-preview"
                    style={{
                        width: window.innerWidth / holder.selectedUser.settings.collumns - 20,
                        height: (window.innerHeight - 50) / holder.selectedUser.settings.rows - 20,
                    }}>
                    <div className="images">
                        <div className="image Media " alt="talk" style={{ backgroundImage: `url('https://storage.googleapis.com/livox-images/full/talk.png')` }} />
                    </div>
                    <div className="attribute written-text">
                        <span className="text"> {t('Quick chat')}</span>
                    </div>
                </div>

            </div>

        </div>

    )
}
