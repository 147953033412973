import React from 'react';
import './changelog.scss';
import { getUserData } from 'utils/currentUser';

export default function Changelog() {

    if (getUserData().language === "pt-BR") {
        return (
        <div className="changelog">
            <h3 id="whats-new-on-studio---july-22.-the-expansion-update">Novidades do Studio - 22 de julho. Atualização de expansão.</h3>
            <ul>
                <li>
                    O Livox studio tem uma nova cara! Agora os cartões mostram se ele irá ser expandido e tocar musica/video ao clicar!
                <img src="https://storage.googleapis.com/livox-images/release-notes/Screen%20Shot%202020-07-17%20at%2001.20.19.png" alt="here" />
                </li>
                <li>
                    A interface web agora também expande imagens ao tocar!
                <img src="https://storage.googleapis.com/livox-images/release-notes/expand.gif" alt="here" />
                </li>
                <li>
                    Para vocês que também trabalham com mil abas abertas, agora fica mais fácil de saber qual conteudo está sendo editado em cada uma!
                <img src="https://storage.googleapis.com/livox-images/release-notes/Screen%20Shot%202020-07-17%20at%2001.18.35.png" alt="Thousand tabs" />
                </li>
                <li>
                    A pesquisa na galeria e sugestões ao escrever estão muito mais rapidos!
                </li>
            </ul>
                <strong> Mais atualizações estão em andamento e seu feedback é muito importante para nós. Se você tiver alguma sugestão / problema, envie um email para <a href="mailto:contato@livox.com.br"> contato@livox.com.br</a>. </strong>
            </div>
        )
    }

    return (
        <div className="changelog">
            <h3 id="whats-new-on-studio---july-22.-the-expansion-update">What’s new on Studio - July, 22. The expansion update</h3>
            <ul>
                <li>
                    Livox Studio has a new UI! New features include whether an item will be played expanded or has music or video!
                <img src="https://storage.googleapis.com/livox-images/release-notes/Screen%20Shot%202020-07-17%20at%2001.20.19.png" alt="here" />
                </li>
                <li>
                    Showing big images is now fully implemented on the web, with a cool new animation!
                <img src="https://storage.googleapis.com/livox-images/release-notes/expand.gif" alt="here" />
                </li>
                <li>
                    You work with a thousand tabs, we get it! Finding out which content is which is easier. Now icons will stay in the tab bar!
                <img src="https://storage.googleapis.com/livox-images/release-notes/Screen%20Shot%202020-07-17%20at%2001.18.35.png" alt="Thousand tabs" />
                </li>
            </ul>
            <p>Other fixes:</p>
            <ul>
                <li>Performance in mobile is better now, and many more improvements to come.</li>
                <li>When the device on mobile is rotated, rows and columns will switch.</li>
                <li>
                    Gallery search and suggestions are way more performant.
                </li>

            </ul>

            <strong>More updates are underway and your feedback is very important for us, if you have any suggestion/problem you can email us at <a href="mailto:contato@livox.com.br">contato@livox.com.br</a>.</strong>
        </div>
    )
}