import React from 'react';
import Table from './table/Table.js';
import Pagination from './Pagination';
import Search from './search/Search';
import AttributesSearch from './AttributesSearch';
import Export from './Export';
import { fetchData, defaultState } from '../fetch';
import './dataview.scss';
import i18n from 'i18n/config';
import PageSizer from './PageSizer.js';
import Buttons from './Buttons.js';
import ParentDescription from './ParentDescription.js';
import qs from 'qs';

const jsonToQueryString = (json) =>
  `?${Object.keys(json)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`)
    .join('&')}`;

export default class DataView extends React.Component {
  constructor(props) {
    super(props);
    const state = defaultState();
    state.formOpen = false;

    let params;
    if (this.props.params) {
      params = qs.parse(this.props.params, { ignoreQueryPrefix: true });
    } else {
      params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    }

    if (this.props.parentData && this.props.parentData.initialQueryParams) {
      params = { ...params, ...this.props.parentData.initialQueryParams };
    }
    state.query = { ...params };
    state.attributes = this.props.attributes
      .filter((attr) => attr.searchKey)
      .map((attr) => {
        if (attr.searchKey === true) {
          return { [attr.key]: '' };
        }
        return { [attr.searchKey]: '' };
      });
    state.limit = 20;
    state.offset = 0;
    state.dropdowns = {};
    this.state = state;
    this.endpoint = this.props.endpoint + jsonToQueryString(params);
  }

  componentDidMount() {
    if (this.props.title) {
      document.title = `${i18n.t(this.props.title)} - Livox`;
    }
    fetchData(this);
  }

  onParamsChange(params) {
    this.endpoint = this.props.endpoint + params;
    fetchData(this);
  }

  updateQuery(key, value) {
    const newQuery = {
      ...this.state.query,
      [key]: value,
    };

    if (key === 'limit' || key === 'offset') {
      this.setState({ [key]: parseInt(value) });
    }

    if (key !== 'offset') {
      this.setState({ offset: 0 });
      newQuery.offset = 0;
    }

    const newurl =
      window.location.origin +
      window.location.pathname +
      jsonToQueryString(newQuery);
    window.history.replaceState({ path: newurl }, '', newurl);

    this.setState({ query: newQuery }, () =>
      this.onParamsChange(jsonToQueryString(this.state.query))
    );
  }

  setQuery(values) {
    this.setState({ query: values }, () =>
      this.onParamsChange(jsonToQueryString(this.state.query))
    );
  }

  setDropdownActive(key, mode) {
    this.setState({ dropdowns: { ...this.state.dropdowns, [key]: mode } });
  }

  onFormSuccess() {
    fetchData(this);
  }

  render() {
    return (
      <div>
        <div className="data-header">
          <h1>{i18n.t(this.props.title)}</h1>
          <ParentDescription data={this.props.parentData} />
          <Buttons links={this.props.links} id={this.props.id} />
        </div>
        <div className="table-control">
          <Search updateQuery={this.updateQuery.bind(this)} />
          <div className="table-actions">
            <this.props.customQuery
              updateQuery={this.updateQuery.bind(this)}
              setQuery={this.setQuery.bind(this)}
            />
            <PageSizer
              pagination={this.state.query.pagination}
              updateQuery={this.updateQuery.bind(this)}
              loading={this.state.loading}
              limit={this.props.limit}
            />
            <Export
              updateQuery={this.updateQuery.bind(this)}
              loading={this.state.loading}
              data={this.state.data}
              results={this.state.data.results}
            />
          </div>
        </div>
        <div className="master-table">
          <div className="scroller">
            {/* TODO FIX DROPDOWN SEARCH setDropdownActive(key, mode) */}
            <AttributesSearch
              attributes={this.props.attributes}
              updateQuery={this.updateQuery.bind(this)}
              dropdowns={this.state.dropdowns}
            />
            <Table
              attributes={this.props.attributes}
              results={this.state.data.results}
              loading={this.state.loading}
              updateQuery={this.updateQuery.bind(this)}
              setDropdownActive={this.setDropdownActive.bind(this)}
            />
          </div>
        </div>

        {!this.state.loading ? (
          <Pagination
            data={this.state.data}
            updateQuery={this.updateQuery.bind(this)}
            pagination={this.state.query.pagination}
            offset={this.state.offset}
            limit={this.state.limit}
          />
        ) : (
          <div className="loader" />
        )}
      </div>
    );
  }
}
