import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from 'routes';
import { Helmet } from 'react-helmet';
import i18n from 'i18n/config';
import StoreSearchView from './SearchView';
import StoreHome from './home/Home';
import Publication from './publication/Publication';
import StoreNavbar from './common/Navbar';
import StoreAdmin from './admin/Admin';
import './store.scss';
import AccountBoards from './boards/Boards';
import StoreProfile from './account/Profile';
import FormUpdateStoreProfile from './account/EditProfile';
import PendingApproval from './admin/PendingApproval';
import StorePromoted from './promoted/Promoted';

export default function StoreIndex({ match }) {
  return (
    <>
      <Helmet
        defaultTitle={i18n.t('Livox Portal')}
        titleTemplate={`%s - ${i18n.t('Livox Portal')}`}
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta
          name="description"
          content={i18n.t('A content store made for you!')}
        />
      </Helmet>
      <StoreNavbar />
      <div className="store-container">
        <div className="livox-store">
          <Switch>
            <Route
              path={`${match.path}/${ROUTES.STORE_SEARCH.path}`}
              component={StoreSearchView}
            />
            <Route
              path={`${match.path}/${ROUTES.STORE_PUBLICATION.path}`}
              component={Publication}
            />
            <Route
              path={`${match.path}/${ROUTES.STORE_PROFILE.path}`}
              component={StoreProfile}
            />
            <Route path={ROUTES.STORE_ADMIN.path} component={StoreAdmin} />
            <Route
              exact
              path={ROUTES.ACCOUNT_CONTENT.path}
              component={AccountBoards}
            />
            <Route
              exact
              path={ROUTES.STORE_PROFILE_EDIT.path}
              component={FormUpdateStoreProfile}
            />
            <Route
              exact
              path={ROUTES.PENDING_APPROVAL.path}
              component={PendingApproval}
            />
            <Route
              exact
              path={ROUTES.STORE_PROMOTED.path}
              component={StorePromoted}
            />

            <Route component={StoreHome} />
          </Switch>
        </div>
      </div>
    </>
  );
}
