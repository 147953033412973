import { post } from 'utils/fetch';
import i18n from 'i18n/config';
import { CLOUD_URL } from 'constants.js';
import Toolbar from './Toolbar';
import Media from '../../../../utils/Media';
import { historyPush, save } from '../utils/history';
import Controls from './Controls';
import CategoryType from 'components/studio/categoryType';

export default function Header({ functions }) {
  const updateMetadata = (e) => {
    const title = e.target.value;
    const data = {
      title,
    };
    post(`${CLOUD_URL}/content/${functions.uuid}/update`, data);

    if (title && title !== '') {
      document.title = `${title} - Livox Studio`;

      const editNode = functions.tree;
      historyPush(functions);
      editNode.writtenText = title;
      save(functions);
    }
  };

  const hasStates = functions.tree.states && functions.tree.states.length > 0;

  const imageHeaderClick = () => {
    functions.gotoRoot();
    if (functions.mode !== 'edit') return;
    functions.setEdit(functions.tree);
  };

  const getImage = () => {
    if (functions.categoryType === CategoryType.GAME) {
      return functions.metadata.coverImage;
    }

    if (hasStates) {
      return functions.tree.states[0].image1;
    }

    return functions.tree.image1;
  };

  return (
    <div className="header container">
      <div className="board-controls">
        <div className="media-container view-hide" onClick={imageHeaderClick}>
          <Media
            filename={getImage()}
            placeholder
            contentId={functions.contentId}
          />
        </div>
        <div className="header-title">
          <input
            className="title view-hide"
            placeholder={i18n.t('Click here to add a title')}
            onBlur={updateMetadata}
            defaultValue={functions.metadata && functions.metadata.title}
            readOnly={functions.mode !== 'edit'}
          />
          <Toolbar functions={functions} />
        </div>
      </div>
      <Controls functions={functions} />
    </div>
  );
}
