import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { ROUTES, routePath } from 'routes';
import { AppContext } from '../Reducer';
import UserAvatar from '../common/Avatar';
import Modal from 'utils/modal';
import "./users.scss";
import { useTranslation } from 'react-i18next';
import { FiAtSign, FiCalendar, FiUser, FiUserPlus } from 'react-icons/fi';
import CreateUserForm from '../setup/CreateUserForm';
import locales from '../utils/locales';

export function Users() {
    const { holder } = React.useContext(AppContext);
    const { t } = useTranslation();

    // TODO move this
    return (
        <div className="users-setting settings-section">
            <div className="title-bar">
                <h2>{t("Users")}</h2>
                <NavLink exact to={ROUTES.LIVOX_SETTINGS_USERS_CREATE.path} className="btn">
                    <button>
                        <FiUserPlus /> <span> {t("New User")} </span>
                    </button>
                </NavLink>
            </div>
            <div className="users">

                {Object.entries(holder.users).map(([id, user]) => 
                <NavLink exact key={user.uuid} to={routePath(ROUTES.LIVOX_SETTINGS_USERS_VIEW, { id: user.uuid })}>
                    <div className="user" key={id}>
                        <div className="user-card">
                            <UserAvatar user={user} size={70} />
                            <span className="user-name"> {user.name} </span>
                        </div>
                    </div>
                </NavLink>
                )}

            </div>
        </div>
    );
}
export function UserInfo({ match }) {
    const { holder, dispatch } = React.useContext(AppContext);
    const { t } = useTranslation();

    const [modal, setModal] = useState(false);
    const [deletionStatus, setDeletion] = useState("none");

    const uuid = match.params.id;
    // TODO move this
    const user = holder.users[uuid];
    console.log(user);

    function deleteUser() {
        setDeletion("processing");
        dispatch({ type: 'DELETE_USER', payload: { uuid } });
    }

    if (deletionStatus === "processing") {
        return (
            <div className="deleting">
                {t("Deleting User...")}
            </div>
        );
    }

    return (
        <div className="users-setting settings-section">
            <div className="title-bar">
                <h2>{user.name}</h2>
                <NavLink exact to={routePath(ROUTES.LIVOX_SETTINGS_USERS_EDIT, { id: uuid })}>
                    <button>
                        <span className=""> {t("Edit")} </span>
                    </button>
                </NavLink>

            </div>
            <div className="user-info">
                <UserAvatar user={user} size={100} />
                <div className="user-table">
                    <div className="key">
                        <FiUser /> Name
                        </div>
                    <div className="value">
                        {user.name}
                    </div>
                    <div className="key">
                        <FiCalendar /> Birth date
                        </div>
                    <div className="value">
                        {user.birth_date}
                    </div>
                    <div className="key">
                        <FiAtSign /> Language
                        </div>
                    <div className="value">
                        {locales[user.language]} - {user.voiceURI}
                    </div>
                </div>
                <button className="delete" onClick={() => setModal(true)}>
                    <span className=""> {t("Delete User")} </span>
                </button>
                <Modal active={modal}
                    onCancel={() => setModal(false)}
                    textModal="Deleting this user will remove all boards, images and information related to it. This cannot be undone!"
                    onConfirm={deleteUser}
                    textConfirm={`Delete "${user.name}" user`}
                    colorConfirm="#fb161b" />

            </div>
        </div>
    );
}
export function UserEdit({ match }) {
    const { holder, dispatch } = React.useContext(AppContext);
    const { t } = useTranslation();

    const uuid = match.params.id;
    // TODO move this
    const user = holder.users[uuid];

    return (
        <div className="users-setting settings-section">
            <div className="title-bar">
                <h2>{t("Edit User")}</h2>
            </div>
            <CreateUserForm user={user} />
        </div>
    )
}