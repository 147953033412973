import React, { useState, useEffect } from 'react';
import i18n from "i18n/config"
import { CLOUD_URL } from 'constants.js';
import { get } from 'utils/fetch';
import './profile.scss'
import { Helmet } from "react-helmet";
import { FaCaretLeft, FaEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import parameterize from 'parameterize';
import { getUserData } from 'utils/currentUser';
import { ROUTES } from 'routes';
import defaultPicture from "media/navbar-logo.png";
import Card from '../common/card';


export default function StoreProfile(props) {

    const {UserId} = props.match.params
    const [profile, setProfile] = useState(null)
    const endpoint = `/authusers/user/${UserId}`;

    useEffect(() => {
        get(endpoint).then(res => setProfile(res))
    }, [endpoint, UserId]);

    if (profile === null) return "Loading..."

    function imgError(image) {
        image.currentTarget.onerror = "";
        image.currentTarget.src = defaultPicture;
        return true;
    }


    return (
        <>
            <Helmet>
                <title>{profile.name}</title>
                <meta name="description"
                    content={profile.full_name.substr(0, 200) || i18n.t('A content store made for you!')} />
                <link rel="canonical" href={`https://mylivox.com/store/profile/${parameterize(profile.full_name)}/${props.match.params.UserId}/`} />
            </Helmet>
            <div className="back-link">
                <Link to="/store">
                    <FaCaretLeft />
                    {i18n.t('Back to Livox Portal')}
                </Link>
            </div>
            <div className="profile-view">
                <div className="store-sidebar">
                    <div className="image">
                        <img src={profile.picture || defaultPicture}
                            className="Media"
                            alt="User Avatar"
                            onError={imgError}
                        />
                    </div>
                    <div className="metadata" />
                    <div className="abuse" />
                </div>
                <div className="content">
                    <div className="header">
                        <div className="user-data">
                            <h1 className="title">
                                {profile.full_name}
                            </h1>
                            <p>{profile.professional_type !== "Other" ? profile.professional_type : null}</p>
                            <p>{profile.biography}</p>
                        </div>
                        <ProfileEdit UserId={UserId} />
                    </div>
                    <div className="description hidden">
                        {profile.description || i18n.t('No description available.')}
                    </div>

                    <h2 className="title">
                        {i18n.t('Publications')}
                    </h2>
                    <ProfileBoards UserId={props.match.params.UserId} />
                </div>
            </div>
        </>
    );
}

function ProfileEdit({ UserId }) {

    if (UserId !== getUserData().uuid) return null
    return (
        <div className="action edit">
            <Link to={ROUTES.STORE_PROFILE_EDIT.path}>
                <button>
                    <FaEdit /> {i18n.t('Edit')}
                </button>
            </Link>
        </div>
    )
}

function ProfileBoards({ UserId }) {

    const [boards, setBoards] = useState(null)

    useEffect(() => {
        get(`${CLOUD_URL}/user/${UserId}`).then(res => setBoards(res.results))
    }, [UserId]);

    if (boards === null) return "Loading..."

    return (
        <div className="boards">
            {boards.length > 0 ?
                boards.map(board => <Card board={board} key={board.id} />)
                :
                i18n.t('No publications yet')
            }
        </div>
    )
}