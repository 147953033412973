import { Link, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import i18n from 'i18n/config';
import { FaShoppingBag, FaGooglePlay } from 'react-icons/fa';
import AuthForm from './AuthForm';
import GenericRegister from './Register';
import logo from '../../media/full-logo.png';
import { ROUTES } from '../../routes';
import './auth.scss';
import PasswordResetForm from './PasswordResetForm';
import packageInfo from '../../../package.json';
import { ActiveUser } from './ActiveUser';

const { version } = packageInfo;

export default function Auth(props) {
  return (
    <div className="login-background">
      <Helmet>
        <link rel="canonical" href="https://www.mylivox.com/signup/" />
      </Helmet>
      <div className="login-modal">
        <img src={logo} alt="logo" />
        <Switch>
          <Route
            exact
            path={ROUTES.PASSWORD_RESET.path}
            component={PasswordResetForm}
          />
          <Route exact path={ROUTES.ACTIVE_USER.path} component={ActiveUser} />
          <Route
            exact
            path={ROUTES.GENERIC_REGISTER.path}
            render={() => <GenericRegister loginSuccess={props.loginSuccess} />}
          />
          <Route
            render={() => (
              <div className="login-form auth">
                <AuthForm loginSuccess={props.loginSuccess} />
                <Link to={ROUTES.PASSWORD_RESET.path}>
                  {ROUTES.PASSWORD_RESET.name}
                </Link>
              </div>
            )}
          />
        </Switch>
        <span className="version">v{version}</span>
      </div>
      <div className="login-modal store-shortcut">
        <Link to={ROUTES.STORE.path}>
          <FaShoppingBag />
          <span>{i18n.t('Go to Livox Store')}</span>
        </Link>
      </div>
      <div className="login-modal store-shortcut">
        <Link
          to={{
            pathname:
              'https://play.google.com/store/apps/details?id=br.com.livox',
          }}
          target="_blank"
        >
          <FaGooglePlay />
          <span>{i18n.t('Go to Play Store')}</span>
        </Link>
      </div>
    </div>
  );
}
