import React from 'react';
import { FaEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import i18n from 'i18n/config';
import { CLOUD_URL } from 'constants.js';
import Media from 'utils/Media';
import timeSince from 'utils/timeSince';
import { routePath, ROUTES } from 'routes';
import Author from '../common/Author';
import CategoryBadges from '../common/badges';
import { Attribute, renderSearchTable } from '../../../utils/dataview';

export default class PendingApproval extends React.Component {
  constructor() {
    super();

    this.title = 'Pending Approval';
    this.endpoint = `${CLOUD_URL}/publications/pending`;
    this.attributes = [
      new Attribute(
        'Cover Art',
        'coverImage',
        0.5,
        null,
        (image, { ContentId }) => (
          <Media
            filename={image}
            placeholder={false}
            contentId={ContentId}
            style={{ width: 90, height: 75 }}
          />
        ),
        false
      ),
      new Attribute('Title', 'title', 1, null, (_, data) => (
        <PublishedContent data={data} />
      )),
      new Attribute('Categories', 'Categories', 1, null, (categories) =>
        CategoryBadges({ categories })
      ),
      new Attribute('Author', 'createdAt', 0.5, null, (_, data) => (
        <PublishedMetadata data={data} />
      )),
      new Attribute(
        'Review',
        'id',
        0.5,
        null,
        (id, { ContentId }) => (
          <Link
            to={routePath(ROUTES.REVIEW_PUBLICATION, {
              mode: 'review',
              uuid: id,
              contentId: ContentId,
            })}
          >
            <button>
              <FaEdit />
            </button>
          </Link>
        ),
        false
      ),
    ];
  }

  //
  componentDidMount() {
    document.title = `${i18n.t(this.title)} - Livox`;
  }

  render() {
    return renderSearchTable(this);
  }
}

function PublishedContent({ data }) {
  return (
    <div>
      <div>{data.title}</div>
      <div style={{ color: 'var(--base-subtext)', fontSize: '14px' }}>
        {data.description}
      </div>
    </div>
  );
}

function PublishedMetadata({ data }) {
  return (
    <div>
      <Author id={data.Content && data.Content.authorId} />
      <div style={{ color: 'var(--base-subtext)', fontSize: '14px' }}>
        {timeSince(data.createdAt)}
      </div>
    </div>
  );
}
