export function initializeSettings(saved) {
    saved = saved || {};

    const defaultSettings = {
        // Ammount of collumns
        collumns: 6,
        // Ammount of rows
        rows: 4,
        // Passing mode
        // "swipe" - TODO, display as a navigation drawer
        // "fixed" TODO, display pagination on bottom
        // "item", display pagination as a item
        pageMode:  "item",
        // Size of the card text
        cardFontSize: 20,
        // Voice speed
        voiceSpeed: 100,
        // Pitch Rate
        pitchValue: 100,
        // Preferred Language
        voiceLanguage: undefined,
        // Preferred Voice Actor
        voiceURI: undefined,
        // Natural Conversation
        naturalConversationEnabled: false,
        // Keywords to listen for
        naturalConversationKeywords: [],
        // Speak Navigation Arrows
        arrowUtterance: false,
        // Manual freeze time
        touchFreezeTime: 0,
        // Enable intellitouch
        intellitouchEnabled: false,

        // TODOs
        // Prevent Actions when speaking
        utteranceFreezeActions: false,
    }

    // Merge saved with defaults
    return {...defaultSettings, ...saved};

}

export function onSettingsChange(settings) {

    // Update styling
    const root = document.documentElement;
    root.style.setProperty('--collumns-amount', settings.collumns);
    root.style.setProperty('--rows-amount', settings.rows);
    root.style.setProperty('--card-font', `${settings.cardFontSize  }px`);

}

