
import React, { useState, useEffect } from 'react';
import './navbar.scss';
import { getUserData } from 'utils/currentUser';
import defaultPicture from "media/default.png";

export default function UserImage() {

    const [picture, setPicture] = useState(null);

    function imgError(image) {
        image.currentTarget.onerror = "";
        image.currentTarget.src = defaultPicture;
        return true;
    }

    useEffect(() => {
        const data = getUserData();

        const {picture} = data
        let url;
        if (picture) {
            if (picture.startsWith('http://') || picture.startsWith('https://')) {
                url = picture;
            } else {
                url = `http://localhost:8000${  picture}`
            }
        }

        setPicture(url);

    }, [])


    return (
        <div className="user-picture">
            <img src={picture || defaultPicture} onError={imgError} alt="User" />
        </div>
    )


}