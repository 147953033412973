import React, { useEffect, useState } from "react";
import { get } from "utils/fetch";
import { CLOUD_URL } from "constants.js";
import Media from "utils/Media";
import { useTranslation } from "react-i18next";

export default function StoreStats() {
  const { t } = useTranslation();

  const [stats, setStats] = useState(null);
  useEffect(() => {
    get(`${CLOUD_URL}/stats/`).then((res) => {
      setStats(res);
    });
  }, []);

  if (stats === null) return null;

  return (
    <div className="stats store-stats">
      <h2 className="title"> {t("Store Statistics")} </h2>

      <div className="section">
        <div className="number">
          <h2 className="title"> {t("Boards")} </h2>
          <div className="count">{stats.boardsCount.toLocaleString()}</div>
        </div>
        <div className="number">
          <h2 className="title"> {t("Creators")} </h2>
          <div className="count">{stats.uniqueUsers.toLocaleString()}</div>
        </div>
        <div className="number">
          <h2 className="title"> {t("Published")} </h2>
          <div className="count">{stats.publishedCount.toLocaleString()}</div>
        </div>
        <div className="number">
          <h2 className="title"> {t("Unique Downloads")} </h2>
          <div className="count">{stats.downloadsCount.toLocaleString()}</div>
        </div>
      </div>

      <div className="section">
        <div className="number">
          <h2 className="title"> {t("Items")} </h2>
          <div className="count">{stats.itemsCount.toLocaleString()}</div>
        </div>
        <div className="number">
          <h2 className="title"> {t("SymbolStyx usage")} </h2>
          <div className="count">
            {stats.symbolsCount.toLocaleString()}
            <div className="detail">
              ({stats.differentSymbols.toLocaleString()} unique)
            </div>
          </div>
        </div>
        <div className="number">
          <h2 className="title"> {t("Uploaded Images")} </h2>
          <div className="count">
            {stats.uploadedImagesCount.toLocaleString()}
          </div>
        </div>
        <div className="number">
          <h2 className="title"> {t("Uploaded Audio/Video")} </h2>
          <div className="count">{stats.mediaCount.toLocaleString()}</div>
        </div>
        <div className="number">
          <h2 className="title"> {t("Multiple images")} </h2>
          <div className="count">{stats.secondImageCount.toLocaleString()}</div>
        </div>
      </div>

      <h4 className="subtitle"> {t("Most used symbols")} </h4>
      <div className="symbol-usage">
        {stats.sortedSymbols.map(([filename, count]) => (
          <div className="symbol" key={filename}>
            <Media filename={filename} />
            <div className="count">{count}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
