import cloneDeep from 'lodash.clonedeep';
import { post } from '../../../../utils/fetch';
import { CLOUD_URL } from '../../../../constants';
import removeParentReference from './stringifier';

export const historyPush = (functions) => {
  const newTree = cloneDeep(functions.tree);
  functions.sethistory([...functions.history, newTree]);
};

function update(functions, data) {
  if (functions.overrides.onBoardChange) {
    functions.overrides.onBoardChange(data);
  } else {
    post(`${CLOUD_URL}/content/${functions.uuid}/update`, data)
      .then((res) => {
        if (res.success) {
          functions.setSaving('saved');
        } else {
          functions.setSaving('fail');
        }
      })
      .catch((error) => {
        console.error(error);
        functions.setSaving('fail');
      });
  }
}

export const save = (functions) => {
  if (functions.mode !== 'edit') {
    console.error('Trying to save in view mode');
    return;
  }
  let payload = {};
  try {
    payload = JSON.parse(JSON.stringify(functions.tree, removeParentReference));
  } catch (e) {
    console.log(functions.tree);
    console.error(e);
    functions.setSaving('fail');
    alert('An unexpected error ocurred, please reload your window');
    throw e;
  }

  const data = { data: payload, time: new Date() };

  if (functions.saveTimeout) {
    clearTimeout(functions.saveTimeout);
  }
  functions.setSaving('saving');
  const timeout = setTimeout(() => update(functions, data), 1000);
  functions.setSaveTimeout(timeout);
};

export const undo = (functions) => {
  // Save path on old tree
  let currentPath = functions.currentItem;
  const fullPath = [];
  while (currentPath.parent !== null) {
    fullPath.push(currentPath.id);
    currentPath = currentPath.parent;
  }

  if (functions.history.length > 0) {
    const state = functions.history.pop();
    functions.setTree(state);

    // Path restoration on tree
    currentPath = state;
    while (fullPath.length > 0) {
      const pathId = fullPath.pop();
      currentPath =
        currentPath.children.find((child) => child.id === pathId) ||
        currentPath;
    }

    functions.setItem(currentPath);

    save(functions);
  }
};
