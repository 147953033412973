import React from 'react';
import "./settings.scss"
import { FiMonitor, FiVolume2, FiSliders, FiCpu, FiInfo, FiUsers, FiShield } from 'react-icons/fi';
import { Route, Switch, NavLink } from "react-router-dom";
import { ROUTES } from 'routes';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../Reducer';
import CreateUserForm from '../setup/CreateUserForm';
import { Users, UserInfo, UserEdit } from './Users';
import DisplaySettings from './Display';
import SoundSettings from './Sound';
import ControlSettings from './Controls';
import AISettings from './AI';

const userSettings = [
    {
        name: "Display",
        icon: FiMonitor,
        route: ROUTES.LIVOX_SETTINGS_DISPLAY
    }, {
        name: "Voice",
        icon: FiVolume2,
        route: ROUTES.LIVOX_SETTINGS_SOUND
    }, {
        name: "Controls",
        icon: FiSliders,
        route: ROUTES.LIVOX_SETTINGS_CONTROLS
        // }, {
        //     name: "Intellibar",
        //     icon: FiCreditCard,
        //     route: ROUTES.LIVOX_WEB
    }, {
        name: "Artificial Inteligence",
        icon: FiCpu,
        route: ROUTES.LIVOX_SETTINGS_AI
    }
];

const generalSettings = [
    {
        name: "Users",
        icon: FiUsers,
        route: ROUTES.LIVOX_SETTINGS_USERS
    }, {
        name: "Holder",
        icon: FiShield,
        route: ROUTES.LIVOX_SETTINGS_HOLDER
        // }, {
        //     name: "Security",
        //     icon: FiLock,
        //     route: ROUTES.LIVOX_SE
    }, {
        name: "About",
        icon: FiInfo,
        route: ROUTES.LIVOX_SETTINGS_ABOUT
    }
];
export default function LivoxSettings() {
    const { holder } = React.useContext(AppContext);
    const { t } = useTranslation();

    return (
        <div className="settings-screen">
            <div className="menu-container">

                <div className="menu">
                    <div className="user-settings">
                        <h3> {holder.selectedUser.name.split(" ")[0]}'s Settings</h3>
                        <ul>
                            {userSettings.map(setting =>
                                <li key={setting.route.path}>
                                    <NavLink exact to={setting.route.path}>
                                        <setting.icon /> <span> {t(setting.name)} </span>
                                    </NavLink>
                                </li>

                            )}
                        </ul>
                    </div>
                    <div className="user-settings">
                        <h3> General </h3>
                        <ul>
                            {generalSettings.map(setting =>
                                <li key={setting.name}>
                                    <NavLink exact to={setting.route.path}>
                                        <setting.icon /> <span> {t(setting.name)} </span>
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="main">
                <Switch>

                    <Route exact path={ROUTES.LIVOX_SETTINGS_DISPLAY.path} component={DisplaySettings} />
                    <Route exact path={ROUTES.LIVOX_SETTINGS_SOUND.path} component={SoundSettings} />
                    <Route exact path={ROUTES.LIVOX_SETTINGS_CONTROLS.path} component={ControlSettings} />
                    <Route exact path={ROUTES.LIVOX_SETTINGS_AI.path} component={AISettings} />

                    <Route exact path={ROUTES.LIVOX_SETTINGS_USERS.path} component={Users} />
                    <Route exact path={ROUTES.LIVOX_SETTINGS_USERS_CREATE.path} component={CreateUserForm} />
                    <Route exact path={ROUTES.LIVOX_SETTINGS_USERS_VIEW.path} component={UserInfo} />
                    <Route exact path={ROUTES.LIVOX_SETTINGS_USERS_EDIT.path} component={UserEdit} />

                    <SettingsMenu holder={holder} />
                </Switch>
            </div>
        </div>
    )
}

function SettingsMenu({ holder }) {
    const { t } = useTranslation();

    return (
        <div className="settings-main-menu">
            <h2> {t("Settings")} </h2>
            <h3> {holder.selectedUser.name.split(" ")[0]}'s Settings</h3>
            <ul>
                {userSettings.map(setting =>
                    <li key={setting.route.path}>
                        <NavLink exact to={setting.route.path}>
                            <setting.icon /> <span> {t(setting.name)} </span>
                        </NavLink>
                    </li>

                )}
            </ul>

            <h3> General Settings</h3>
            <ul>
                {generalSettings.map(setting =>
                    <li key={setting.route.path}>
                        <NavLink exact to={setting.route.path}>
                            <setting.icon /> <span> {t(setting.name)} </span>
                        </NavLink>
                    </li>

                )}
            </ul>

        </div>

    )
}