import React, { useEffect, useState } from 'react';
import { get } from 'utils/fetch';
import { CLOUD_URL } from 'constants.js';
import i18n from 'i18n/config';

export default function Categories({ selected, setSelected }) {
  const categoryTemplate = [
    {
      name: 'Communication',
      color: '#005aab',
      subcat: [],
    },
    {
      name: 'Education',
      color: '#059a36',
      subcat: [],
    },
    {
      name: 'Fun',
      color: '#af0208',
      subcat: [],
    },
    {
      name: 'Game',
      color: '#FF4500',
      subcat: [],
    },
  ];

  const [categories, setCategories] = useState([]);

  useEffect(
    () => {
      get(`${CLOUD_URL}/store/categories`).then((res) => {
        res.results.forEach((subcat) => {
          const masterCat = categoryTemplate.find(
            (cat) => cat.name.toLowerCase() === subcat.classification
          );
          masterCat.subcat.push(subcat);
        });
        setCategories(categoryTemplate);
      });
    }, // eslint-disable-next-line
        []);

  return (
    <div className="categories">
      {categories.map((cat) => (
        <Category
          cat={cat}
          key={cat.name}
          selected={selected}
          setSelected={setSelected}
        />
      ))}
    </div>
  );
}

function Category({ cat, setSelected, selected }) {
  return (
    <div className="category" style={{ color: cat.color }}>
      <h2 className="title">{i18n.t(cat.name)}</h2>
      <div className="flex">
        <div className="content">
          <div className="subcat">
            {cat.subcat.map((subcat) => (
              <label key={subcat.name}>
                <input
                  type="checkbox"
                  onClick={() => setSelected([...selected(), subcat.id])}
                  defaultChecked={selected().find((id) => subcat.id === id)}
                />
                <span> {i18n.t(subcat.name)} </span>
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
