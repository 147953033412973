import { ContextMenuTrigger } from 'react-contextmenu';
import Tree from './Tree';
import Path from './Path';

export default function Main({ functions, isStore }) {
  function saveContext(e) {
    const RIGHT_CLICK_CODE = 2;
    if (e.button === RIGHT_CLICK_CODE) {
      functions.setCtxItem(null);
    }
  }

  return (
    <ContextMenuTrigger
      holdToDisplay={-1}
      id="item-context-menu"
      attributes={{ className: 'tree' }}
      disable={functions.mode === 'view'}
    >
      <div
        id="tree"
        className={`tree ${functions.currentItem.pageMode}-page-mode`}
        onMouseDownCapture={saveContext}
      >
        <Path functions={functions} />
        <Tree functions={functions} uuid={functions.uuid} isStore={isStore}>
          {functions.currentItem.children}
        </Tree>
      </div>
    </ContextMenuTrigger>
  );
}
