import i18n from 'i18n/config';
import { FaCheck } from 'react-icons/fa';

export default function ColorPicker(props) {
  const { functions } = props;

  const colors = [
    '#FFFFFF',
    '#A3A3A3',
    '#5D6367',
    '#2564AD',
    '#5E89BC',
    '#57C2EC',
    '#5CAEB6',
    '#5DBA27',
    '#BBE535',
    '#FFFF00',
    '#FFBB22',
    '#E6703D',
    '#DB404C',
    '#FF6482',
    '#F64E4A',
    '#BE8AAE',
    '#DA8FFF',
    '#8C50F7',
    '#C1987F',
    '#CD7879',
    '#9087B9',
  ];

  return (
    <div className="section color-picker">
      <h2 className="header">{i18n.t('Colors')}</h2>

      <div className="content scrollable">
        {colors.map((color) => (
          <div
            className={`btn color ${
              functions.selectedState.backgroundColor?.toUpperCase() === color
                ? 'picked'
                : ''
            }`}
            key={color}
            style={{ backgroundColor: color }}
            onClick={() =>
              functions.setAttributeIndex('backgroundColor', color)
            }
          >
            <FaCheck />
          </div>
        ))}
      </div>
    </div>
  );
}
