import React from 'react';
import PublicationForm from './Publish';
import ApprovalForm from './Approve';
import RejectionForm from './Reject';

export default function Modals({functions}) {
    if (!functions.activeModal) return null;
    return (
        <div className="modal">
            <div className="modal-content">
                {functions.activeModal === "publish" ? <PublicationForm functions={functions}/> : null}
                {functions.activeModal === "approve" ? <ApprovalForm functions={functions}/> : null}
                {functions.activeModal === "reject" ? <RejectionForm functions={functions}/> : null}
            </div>
        </div>
    )
}
