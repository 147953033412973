import { useEffect } from 'react';
import './sidebar.scss';
import { FaTimes } from 'react-icons/fa';
import { historyPush, save } from '../utils/history';
import ItemEdit from './ItemEdit';
import Shortcuts from './Shortcuts';
import Settings from './Settings';
import Changelog from './Changelog';
import AIRecommend from './AIRecommend';

export default function Sidebar({ functions }) {
  useEffect(() => {
    const pageMode = functions.currentItem.pageMode || 'item';

    const setPageMode = (mode) => {
      const newTree = functions.tree;
      const newSettings = functions.settings;
      newTree.pageMode = mode;
      newSettings.pageMode = mode;
    };

    const collumns = functions.tree.collumns
      ? functions.tree.collumns
      : functions.settings.collumns;

    const setCollumns = (ammount) => {
      functions.updateSetting('collumns', ammount);
      const newTree = functions.tree;
      newTree.collumns = ammount;
    };

    const rows = functions.tree.rows
      ? functions.tree.rows
      : functions.settings.rows;

    const setRows = (ammount) => {
      functions.updateSetting('rows', ammount);
      const newTree = functions.tree;
      newTree.rows = ammount;
    };
    setPageMode(pageMode);
    setRows(rows);
    setCollumns(collumns);
  }, [functions.tree]);

  const setAttribute = (attr, value) => {
    const editNode = functions.editingNode;
    historyPush(functions);
    editNode[attr] = value;
    save(functions);
    functions.forceUpdate(Math.random());
  };

  // funcao para setar atributo de card com mais de um estado
  const setAttributeIndex = (attr, value) => {
    const states = functions.editingNode.states.map((state) => {
      if (state.index === functions.selectedState?.index) {
        const updatedState = { ...state, [attr]: value };
        functions.setSelectedState(updatedState);
        return updatedState;
      }
      return state;
    });

    functions.editingNode.states = states;
    historyPush(functions);
    save(functions);
    functions.forceUpdate(Math.random());
  };

  const addNewState = (obj) => {
    const editNode = functions.editingNode;
    historyPush(functions);
    editNode.states = [...functions.editingNode.states, obj];
    save(functions);
    functions.forceUpdate(Math.random());
    functions.setSelectedState(obj);
  };

  const removeState = (index) => {
    const states = functions.editingNode.states.filter(
      (state) => state.index !== index
    );
    functions.editingNode.states = states;
    if (states && states.length > 0) {
      functions.setSelectedState(states[0]);
    }
    save(functions);
    functions.forceUpdate(Math.random());
  };

  functions.removeState = removeState;
  functions.addNewState = addNewState;
  functions.setAttributeIndex = setAttributeIndex;
  functions.setAttribute = setAttribute;

  function getRenderMode() {
    switch (functions.sidebarMode) {
      case 'item':
        return <ItemEdit functions={functions} />;
      case 'settings':
        return <Settings functions={functions} />;
      case 'shortcuts':
        return <Shortcuts functions={functions} />;
      case 'ai-recommendations':
        return <AIRecommend functions={functions} />;
      case 'changelog':
        return <Changelog functions={functions} />;
      default:
        return null;
    }
  }

  return (
    <div className={`sidebar ${functions.sidebarMode}-mode`}>
      <div className="close-mobile" onClick={() => functions.setSidebar(null)}>
        <FaTimes />
      </div>
      {getRenderMode()}
    </div>
  );
}
