import React from 'react';
import { FaSearch } from 'react-icons/fa';
import qs from 'qs';

export default class AttributesSearch extends React.Component {
  constructor(props) {
    super(props);
    const params = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    this.totalSize = this.props.attributes.reduce(
      (acc, cur) => acc + cur.size,
      0
    );
    const state = { ...params };
    state.timeout = {};
    this.timer = 350;
    this.state = state;
  }

  handleChange(event) {
    const attr = event.target.getAttribute('data-attr');
    const { value } = event.target;
    clearTimeout(this.state.timeout[attr]);
    const timeOut = setTimeout(
      () => this.searchAttribute(attr, value),
      this.timer
    );
    this.setState({ [attr]: value, timeout: { [attr]: timeOut } });
  }

  searchAttribute(attr, value) {
    this.props.updateQuery(attr, value);
  }

  render() {
    return (
      <table className="attribute-search">
        <thead>
          <tr>
            {this.props.attributes.map((item) => {
              // eslint-disable-next-line
                            this.state.timeout[item.key] = null;
              return (
                <th
                  key={item.key}
                  style={{ width: `${(item.size * 100) / this.totalSize}%` }}
                >
                  {item.searchKey ? (
                    <div className="attr-form-field">
                      {typeof item.searchKey === 'function' ? (
                        <item.searchKey
                          handleChange={this.handleChange.bind(this)}
                        />
                      ) : (
                        <>
                          <FaSearch />
                          {Array.isArray(item.searchKey) ? (
                            <input
                              onChange={this.handleChange.bind(this)}
                              data-attr={
                                this.props.dropdowns &&
                                this.props.dropdowns[item.key]
                              }
                            />
                          ) : (
                            <input
                              onChange={this.handleChange.bind(this)}
                              data-attr={item.searchKey}
                              value={this.state[item.searchKey]}
                            />
                          )}
                        </>
                      )}
                    </div>
                  ) : null}
                </th>
              );
            })}
          </tr>
        </thead>
      </table>
    );
  }
}
