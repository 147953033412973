import React from 'react';
import { ContextMenu, MenuItem } from "react-contextmenu";
import { FiCopy, FiScissors, FiClipboard, FiCrosshair, FiTrash } from "react-icons/fi";
import "./item-menu.scss";
import i18n from "i18n/config"
import StudioClipboard from './clipboard';
import { historyPush, save } from './history';

export default function ItemMenu({ functions }) {

    const isMac = navigator.platform.includes("Mac");

    const deleteContextItem = () => {

        // TODO Deduplicate from item.js
        const node = functions.ctxItem;

        functions.setEdit(null);
        functions.setSidebar(null)

        // Is root node
        if (node.parent === null) {
            return false;
        }

        historyPush(functions);
        node.parent.children = node.parent.children.filter((child) => child.id !== node.id);
        save(functions);

    }

    return (
        <ContextMenu id="item-context-menu" className="item-menu">
            <MenuItem onClick={() => StudioClipboard.cut(functions.ctxItem, functions)} disabled={functions.ctxItem === null}>
                <span>
                    <FiScissors /> {i18n.t("Cut")}
                </span>
                <span className="shortcut">
                    {isMac ? "⌘" : "CTRL "}X
                </span>
            </MenuItem>
            <MenuItem onClick={() => StudioClipboard.copy(functions.ctxItem, functions)} disabled={functions.ctxItem === null}>
                <span>
                    <FiCopy /> {i18n.t("Copy")}
                </span>
                <span className="shortcut">
                    {isMac ? "⌘" : "CTRL "}C
                </span>

            </MenuItem>
            <MenuItem onClick={() => StudioClipboard.paste(functions.currentItem, functions)} disabled={!StudioClipboard.canPaste()}>
                <span>
                    <FiClipboard /> {i18n.t("Paste")}
                </span>
                <span className="shortcut">
                    {isMac ? "⌘" : "CTRL "}V
                </span>
            </MenuItem>
            <MenuItem onClick={() => StudioClipboard.paste(functions.ctxItem, functions)} 
                disabled={!StudioClipboard.canPaste() || !functions.ctxItem || functions.ctxItem.parent?.isBookMode }>
                <span>
                    <FiCrosshair /> {i18n.t("Paste Inside")}
                </span>
                <span className="shortcut" />
            </MenuItem>
            <MenuItem divider />
            <MenuItem onClick={deleteContextItem} disabled={!functions.ctxItem}>
                <span>
                    <FiTrash /> {i18n.t("Delete")}
                </span>
                <span className="shortcut">
                    Del
                </span>
            </MenuItem>
        </ContextMenu>

    )
}