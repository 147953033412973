import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'core-js';  
import { getUserData, getUserLogin } from 'utils/currentUser';

if (process.env.NODE_ENV === 'production'){
    Sentry.init({
        dsn: "https://93d64aa5e5a74d3f9eddc95ba8e046ef@o392100.ingest.sentry.io/5239153",
        environment: process.env.NODE_ENV
    });
    if (getUserLogin()){       
        Sentry.configureScope((scope) => {
            scope.setUser({
                "email": getUserData().email,
                "username": getUserData().full_name
            });
        });
    }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
