import React, { useEffect, useState } from 'react';
import { FaCaretRight } from 'react-icons/fa';
import { get } from 'utils/fetch';
import { CLOUD_URL } from 'constants.js';
import i18n from 'i18n/config';
import qs from 'qs';
import { getUserData } from 'utils/currentUser';
import Card from '../common/card';
import Filters from './Filters';
import StoreCategories from './Categories';
import './home.scss';
import FeaturedSection from './Featured';
/*
import { getUserData } from 'utils/currentUser';
*/

export default function Store({ history, location }) {
  const [query, setQuery] = useState('');

  console.log('CLOUD_URL', CLOUD_URL);
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  let local = 'en-US';

  if (params.local) {
    local = params.local;
  } else if (getUserData().language === 'pt-BR') {
    local = 'pt-BR';
  }

  // let local = "any"
  // const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  // if (params.local){
  //     local = params.local
  // }
  const [localization, setLocalization] = useState(local);

  return (
    <>
      <div className="readjust-margin">
        <FeaturedSection localization={localization} />
      </div>

      <div className="store-home">
        <div className="categories mobile-hidden">
          <StoreCategories />
        </div>
        <div className="main">
          <Filters
            {...{ query, setQuery, localization, setLocalization, history }}
          />
          <div className={`${query !== '' ? 'hidden' : ''}`}>
            <Section
              name="Top Rated Items"
              endpoint={`${CLOUD_URL}/store/popular`}
              localization={localization}
            />
            <Section
              name="New Releases"
              endpoint={`${CLOUD_URL}/store/recent`}
              localization={localization}
            />
            <Section
              name="Alternative Communication"
              endpoint={`${CLOUD_URL}/store/category/communication`}
              localization={localization}
            />
            <Section
              name="Education for all ages"
              endpoint={`${CLOUD_URL}/store/category/education`}
              localization={localization}
            />
            <Section
              name="Just for fun!"
              endpoint={`${CLOUD_URL}/store/category/fun`}
              localization={localization}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function Section({ name, endpoint, localization }) {
  const [boards, setBoards] = useState([]);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    get(`${endpoint}?lang=${localization || 'any'}`).then((res) =>
      setBoards(res.results)
    );
  }, [endpoint, localization]);

  return (
    <div className="section">
      <h2>{i18n.t(name)}</h2>
      <div className="boards">
        {boards.slice(0, limit).map((board) => (
          <Card
            board={board}
            showLanguage={localization === 'any'}
            key={board.id}
          />
        ))}
      </div>
      {boards.length > limit ? (
        <div className="more-items" onClick={() => setLimit(limit + 10)}>
          {i18n.t('See more')} <FaCaretRight />
        </div>
      ) : null}
    </div>
  );
}
