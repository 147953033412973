import cloneDeep from 'lodash.clonedeep';
import { historyPush, save } from './history';

let draggingElement = null;

export const handleDragStart = (e, node) => {
    e.target.classList.add('dragging');
    e.dataTransfer.effectAllowed = 'move';
    draggingElement = node;
}

export const handleDragOver = (e, node) => {
    if (draggingElement === node) {
        return false;
    }

    e.target.classList.add('over');
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move'
}

export const handleDrop = (e, node, functions) => {

    historyPush(functions);

    if (draggingElement === node) {
        draggingElement = null;
        return false;
    }

    e.target.classList.remove('over');

    // Save children for inconsistency
    const children = cloneDeep(draggingElement.children);
    // Remove from list
    draggingElement.parent.children = draggingElement.parent.children.filter((child) => child.id !== draggingElement.id);
    draggingElement.parent = node;
    
    node.children.push(draggingElement);

    draggingElement.children = children;
    functions.forceUpdate(Math.random());

    // const newTree = cloneDeep(functions.tree);
    // functions.setTree(newTree);

    save(functions);
}

export const handleGutterDragOver = (e, node) => {
    if (draggingElement === node) {
        return false;
    }

    e.target.classList.add('over');
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
}

export const handleGutterDrop = (e, node, functions, index, p) => {
    if (draggingElement === node) {
        draggingElement = null;
        return false;
    }
    e.target.classList.remove('over');

    const {children} = draggingElement.parent;
    // Find index of actual node
    const pos = children.map((e) => e.id).indexOf(draggingElement.id);
    // If forward
    if (index > pos) index--;

    if (index === pos) {
        return false;
    }

    historyPush(functions);
    // Move object
    children.splice(index, 0, children.splice(pos, 1)[0]);
    save(functions);

}

