import React from 'react';

export default function UserAvatar({ user, size }) {

    if (!user.avatar) {
        return (
            <div className="user-avatar">
                <div className="image-placeholder" style={{height: size, width: size}}>
                    <span className="placeholder-text" style={{fontSize: size / 3, padding: `${size / 3}px 0`}}>
                        {user.name.split(" ").slice(0,4).map(name => name[0])}
                    </span>
                </div>
            </div>
        )
    }

    return (
        <div className="user-avatar">
            <img
                style={{ height: size, width: size }}
                alt={`${user.name} avatar`}
                src={user.avatar}
            />
        </div>

    )
}