import { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { get, post } from 'utils/fetch';
import { CLOUD_URL } from 'constants';
import './aiRecommend.scss';
import UUID from 'uuid/v4';
import { getUserData } from 'utils/currentUser';
import { useTranslation } from 'react-i18next';
import GraphemeSplitter from 'grapheme-splitter';
import { nodeTemplate } from '../main/NewItem';
import { historyPush, save } from '../utils/history';
import { isEmoji } from '../utils/emoji';

// TODO translation keys
export default function AIRecommend({ functions }) {
  const [prompt, setPrompt] = useState(functions.currentItem.writtenText);
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const { t } = useTranslation();

  const addItem = async ({ written_text, spoken_text, image_id }) => {
    function visibleLength(str) {
      const splitter = new GraphemeSplitter();
      return splitter.countGraphemes(str);
    }

    const imageUpload = async (file) => {
      try {
        const url = `${CLOUD_URL}/content/${functions.metadata.id}/image-upload`;
        const formData = new FormData();
        formData.append('file', file);

        const res = await post(url, formData, 'multipart/form-data');

        return res.filename;
      } catch (err) {
        alert(err && err.errors.message);
        throw err;
      }
    };

    const emojiToImage = async (emoji) => {
      const canvas = document.createElement('canvas');
      canvas.width = 300;
      canvas.height = 300;

      const ctx = canvas.getContext('2d');

      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const fontSize = 200 / visibleLength(image_id);
      ctx.font = `${fontSize}px Arial`;

      const x = (canvas.width - visibleLength(image_id) * fontSize * 1.25) / 2;
      const y = (fontSize / 2 + canvas.height) / 2;

      ctx.fillText(emoji, x, y);

      let image;
      await new Promise((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            image = blob;
            resolve();
          },
          'image/jpeg',
          1.0
        );
      });
      return image;
    };

    try {
      historyPush(functions);
      const parent = functions.currentItem;

      const image = await emojiToImage(image_id);
      const imageFilename = await imageUpload(image);

      const newNodeData = {
        ...nodeTemplate,
        id: UUID(),
        writtenText: written_text,
        spokenText: spoken_text,
        states: [
          {
            id: UUID(),
            index: 0,
            writtenText: written_text,
            spokenText: spoken_text,
            image1: imageFilename,
          },
        ],
      };

      newNodeData.parent = parent;
      parent.children.push(newNodeData);
      newNodeData.children = [];
      save(functions);
    } catch (err) {
      console.error(err);
    }
  };

  async function searchPrompt() {
    const { language } = getUserData();
    setLoading(true);
    try {
      const { success, apiError, data } = await get(
        `${CLOUD_URL}/ai/cards-recommendation?topic=${prompt}&lang=${language}`
      );
      if (success) {
        setResults(data);
        setLoading(false);
        setError(null);
      } else {
        setError(apiError);
        setResults([]);
        setLoading(false);
      }
    } catch (e) {
      setError(e?.message || 'Error');
      setResults([]);
      setLoading(false);
    }
  }

  function getInnerBox() {
    if (isLoading) {
      const SHIMMER_ITEMS = 10;
      return (
        <div className="loading">
          {[...Array(SHIMMER_ITEMS).keys()].map((i) => (
            <div key={i} className="shimmer box">
              <div className="shimmer round" />
              <div className="shimmer block" />
            </div>
          ))}
        </div>
      );
    }

    if (error) {
      return (
        <div className="error explanation-box">
          {t('AI Error')}: <br /> <br /> {error}
        </div>
      );
    }

    if (results.length > 0) {
      return (
        <div className="content scrollable proposed-cards">
          {results
            .filter(
              ({ written_text, spoken_text, image_id }) =>
                written_text !== undefined &&
                spoken_text !== undefined &&
                image_id !== undefined &&
                isEmoji(image_id)
            )
            .map(({ written_text, spoken_text, image_id }) => (
              <div
                className="card-proposal"
                onClick={() => addItem({ written_text, spoken_text, image_id })}
              >
                <div className="image">{image_id}</div>
                <div className="text">{written_text}</div>
              </div>
            ))}
        </div>
      );
    }

    return (
      <div className="explanation-box">
        <h3> {t('Create items using AI!')} </h3>
        <p> {t('Get cards recommendations for every situation!')}</p>
        <p> {t('Examples:')}</p>

        <ul>
          <li> {t('Breakfast items')}</li>
          <li> {t('Teeth brushing steps')}</li>
          <li> {t('Quiz option choices')}</li>
          <li> {t('and many more!')}</li>
        </ul>
      </div>
    );
  }

  return (
    <div className="section ai-recommend">
      <h2 className="header">{t('Cards suggestion with AI')}</h2>
      <div className="content search">
        <input
          type="search"
          onChange={(e) => setPrompt(e.currentTarget.value)}
          value={prompt}
        />
        <button type="submit" onClick={searchPrompt}>
          <FiSearch />
        </button>
      </div>
      {getInnerBox()}
    </div>
  );
}
