// eslint-disable-next-line import/extensions
import { ArrayFunctions } from "./types";

const Arrays: ArrayFunctions = {
  drop(array, amount) {
    return array.slice(amount > 0 ? amount : 0);
  },
  take(array, amount) {
    return array.slice(0, amount > 0 ? amount : 0);
  },
  dropWhile(array, predicate) {
    const i = array.findIndex((e) => !predicate(e));
    return array.slice(i > 0 ? i : 0);
  },
  takeWhile(array, predicate) {
    const i = array.findIndex((e) => !predicate(e));
    return array.slice(0, i > 0 ? i : 0);
  },
  last(array) {
    if (array.length === 0) return null;
    return array[array.length - 1];
  },
  first(array) {
    if (array.length === 0) return null;
    return array[0];
  },
};

export default Arrays;
