import { LegacyRef, forwardRef, useMemo } from 'react';
import './allBoardToPrint.scss';
import { getMediaURL } from 'utils/Media';
import i18n from 'i18n/config';

type StateType = {
  index: number;
  spokenText: string;
  writtenText: string;
  backgroundColor: string;
  borderColor: string;
  image1: string;
  onlyText: boolean;
};

type AllBoardToPrintProps = {
  functions: {
    settings: {
      collumns: number;
      rows: number;
      printNavigationCard: boolean;
      printAllPages: boolean;
    };
    currentItem: {
      writtenText: string;
      children: { id: number; backgroundColor: string; states?: StateType[] }[];
      parent: { id: string };
    };
    pageOffset: number;
  };
};

const itemHeightList = [
  { rows: 2, height: 42.1 },
  { rows: 3, height: 26.2 },
  { rows: 4, height: 18.2 },
  { rows: 5, height: 13.5 },
  { rows: 6, height: 10.3 },
  { rows: 7, height: 8 },
  { rows: 8, height: 6.4 },
  { rows: 9, height: 5.2 },
  { rows: 10, height: 4.2 },
];

const itemFontSizeList = [
  { columns: 2, fontSize: 20 },
  { columns: 3, fontSize: 20 },
  { columns: 4, fontSize: 20 },
  { columns: 5, fontSize: 20 },
  { columns: 6, fontSize: 18 },
  { columns: 7, fontSize: 16 },
  { columns: 8, fontSize: 14 },
  { columns: 9, fontSize: 12 },
  { columns: 10, fontSize: 10 },
];

export const AllBoardToPrint = forwardRef(
  (props: AllBoardToPrintProps, ref: LegacyRef<HTMLDivElement> | undefined) => {
    const { functions } = props;
    const { collumns, rows, printNavigationCard, printAllPages } =
      functions.settings;

    const items = collumns * rows;
    const totalGrids = Math.ceil(functions.currentItem.children.length / items);

    const gridList = useMemo(() => {
      if (!printAllPages) {
        let printItems = items + functions.pageOffset;
        let hasNext = false;
        if (
          printNavigationCard &&
          functions.currentItem.children.length > printItems - 1
        ) {
          printItems -= 1;
          hasNext = true;
        }
        const hasPrevious =
          (printNavigationCard && functions.currentItem.parent !== null) ||
          functions.pageOffset > 0;
        if (hasPrevious) printItems -= 1;

        return [
          {
            id: 1,
            start: functions.pageOffset,
            end: printItems,
            hasNext:
              hasNext && functions.currentItem.children.length > printItems,
            hasPrevious,
          },
        ];
      }

      const totalItens = functions.currentItem.children?.length || 0;
      let totalItensLoad = 0;
      let id = 0;
      let countStart = 0;
      let countEnd = 0;
      const gridListItems = [];
      let gridCount = 0;
      let hasMoreItens = true;

      while (hasMoreItens === true) {
        let hasNext = false;
        let hasPrevious = false;
        countStart = totalItensLoad === 0 ? gridCount * items : totalItensLoad;
        countEnd = totalItensLoad + items;
        if (printNavigationCard) {
          if (gridCount === 0 && totalGrids > 1) {
            hasNext = true;
            countEnd -= 1;
          }
          if (totalItensLoad < totalItens) {
            if (!hasNext) {
              hasNext = true;
              countEnd -= 1;
            }
            if (
              (!hasPrevious &&
                gridCount === 0 &&
                functions.currentItem.parent !== null) ||
              (!hasPrevious && gridCount !== 0)
            ) {
              hasPrevious = true;
              countEnd -= 1;
            }
          }

          if (gridCount > 0 && totalItens < totalItensLoad) {
            if (!hasPrevious && gridCount !== 0) {
              hasPrevious = true;
              countEnd -= 1;
            }
          }

          // caso no proximo grid só tenha 1 item, não é necessário o botão next pois já coloca o item no grid atual
          if (totalItens <= countEnd + 1) {
            if (hasNext) {
              hasNext = false;
              countEnd += 1;
            }
          }
        }

        totalItensLoad = countEnd;
        id += 1;
        if (totalItens <= totalItensLoad) {
          hasMoreItens = false;
          hasNext = false;
        }
        gridListItems.push({
          id,
          start: countStart,
          end: countEnd,
          hasNext: printNavigationCard ? hasNext : false,
          hasPrevious: printNavigationCard ? hasPrevious : false,
        });
        gridCount += 1;
      }
      return gridListItems;
    }, [functions]);

    return (
      <div
        ref={ref}
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          marginBlockStart: '5px',
        }}
      >
        <style type="text/css" media="print">
          {'@page { size: landscape; }'}
        </style>
        <span style={{ height: '1vh' }}>
          {functions.currentItem.writtenText} - Livox Studio
        </span>
        {gridList.map((item) => (
          <div
            key={item.id}
            className="all-cards"
            style={{
              gridTemplateColumns: `repeat(${collumns}, minmax(0, 1fr))`,
              gridTemplateRows: `repeat(${rows}, minmax(0, 1fr))`,
            }}
          >
            {item.hasPrevious && (
              <div
                className="item Back"
                style={{
                  gridRowStart: `${rows}`,
                  height: `${
                    itemHeightList.find((item) => item.rows === rows)?.height ||
                    40
                  }vh`,
                  fontSize: `${
                    itemFontSizeList.find((item) => item.columns === collumns)
                      ?.fontSize || 20
                  }px`,
                }}
              >
                <img src={getMediaURL('left_arrow')} alt="left_arrow" />
                <span className="written-text"> {i18n.t('Back')} </span>
              </div>
            )}
            {functions.currentItem?.children
              ?.slice(item.start, item.end)
              .map((node) => {
                return (
                  <div
                    key={node.id}
                    className="item"
                    style={{
                      backgroundColor:
                        node.states && node.states.length > 0
                          ? node.states[0].backgroundColor
                          : '',
                      borderRadius: '10px',
                      border:
                        node.states && node.states.length > 0
                          ? node.states[0].borderColor &&
                            node.states[0].borderColor !== '#FFFFFF' &&
                            node.states[0].borderColor
                            ? `5px solid ${node.states[0].borderColor}`
                            : '1px solid black'
                          : '',
                      height: `${
                        itemHeightList.find((item) => item.rows === rows)
                          ?.height || 40
                      }vh`,
                      fontSize: `${
                        itemFontSizeList.find(
                          (item) => item.columns === collumns
                        )?.fontSize || 20
                      }px`,
                    }}
                  >
                    {node.states &&
                      node.states.length > 0 &&
                      !node.states[0].onlyText && (
                        <img
                          src={getMediaURL(
                            node.states && node.states.length > 0
                              ? node.states[0].image1 || 'communicate'
                              : 'communicate'
                          )}
                          alt={
                            node.states && node.states.length > 0
                              ? node.states[0].writtenText || 'communicate'
                              : 'communicate'
                          }
                        />
                      )}
                    <span className="written-text-print">
                      {node.states && node.states.length > 0
                        ? node.states[0].writtenText
                        : ''}
                    </span>
                  </div>
                );
              })}
            {item.hasNext && (
              <div
                className="item Next"
                style={{
                  gridRowStart: `${rows}`,
                  gridColumnStart: `${collumns}`,
                  height: `${
                    itemHeightList.find((item) => item.rows === rows)?.height ||
                    40
                  }vh`,
                  fontSize: `${
                    itemFontSizeList.find((item) => item.columns === collumns)
                      ?.fontSize || 20
                  }px`,
                }}
              >
                <img src={getMediaURL('right_arrow')} alt="right_arrow" />
                <span className="written-text"> {i18n.t('Next')} </span>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
);
