import i18n from 'i18n/config';
import { FaCheck } from 'react-icons/fa';
import { FiTrash } from 'react-icons/fi';

export default function BorderColorPicker(props) {
  const { functions } = props;

  const colors = [
    '#FFFFFF',
    '#A3A3A3',
    '#5D6367',
    '#2564AD',
    '#5E89BC',
    '#57C2EC',
    '#5CAEB6',
    '#5DBA27',
    '#BBE535',
    '#FFFF00',
    '#FFBB22',
    '#E6703D',
    '#DB404C',
    '#FF6482',
    '#F64E4A',
    '#BE8AAE',
    '#DA8FFF',
    '#8C50F7',
    '#C1987F',
    '#CD7879',
    '#9087B9',
  ];

  return (
    <div className="section color-picker">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginRight: '30px',
        }}
      >
        <h2 className="header">{i18n.t('Border')}</h2>
        <div
          className="remove"
          onClick={() => functions.setAttributeIndex('borderColor', '')}
        >
          <FiTrash />
        </div>
      </div>

      <div className="content scrollable">
        {colors.map((borderColor) => (
          <div
            className={`btn color ${
              functions.selectedState.borderColor?.toUpperCase() === borderColor
                ? 'picked'
                : ''
            }`}
            key={borderColor}
            style={{ backgroundColor: borderColor }}
            onClick={() =>
              functions.setAttributeIndex('borderColor', borderColor)
            }
          >
            <FaCheck />
          </div>
        ))}
      </div>
    </div>
  );
}
