import i18n from 'i18n/config';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes';
import parameterize from 'parameterize';

const categoryTemplate = [
  {
    name: 'Communication',
    color: '#005aab',
    subcat: [],
  },
  {
    name: 'Education',
    color: '#05ad3d',
    subcat: [],
  },
  {
    name: 'Fun',
    color: '#af0208',
    subcat: [],
  },
  {
    name: 'Game',
    color: '#FF4500',
    subcat: [],
  },
];

export default function CategoryBadges({ categories }) {
  const list = categories || [];

  return list.map((cat) => {
    const category = categoryTemplate.find(
      ({ name }) => name.toLowerCase() === cat.classification
    );
    if (category) {
      const { color } = category;
      return (
        <div
          className="badge"
          key={cat?.name}
          style={{ color, borderColor: color }}
        >
          <Link
            to={`${ROUTES.STORE_SEARCH.path}/?category=${parameterize(
              cat?.name
            )}`}
          >
            {i18n.t(cat?.name)}
          </Link>
        </div>
      );
    }
    return <div className="badge" />;
  });
}
