import React, { useEffect, useState } from 'react';
import { get } from 'utils/fetch';
import { CLOUD_URL } from 'constants.js';
import i18n from "i18n/config"
import { Link } from 'react-router-dom';
import Card from './common/card';

export default function StoreSearchView({ location }) {

    const [boards, setBoards] = useState(false);
    useEffect(() => {
        get(CLOUD_URL + location.pathname + location.search).then(res => setBoards(res.results))
    }, [location]);

    return (
        <div className="store">
            <h1>
                {i18n.t('Livox Store')}
            </h1>
            <h3>
                {i18n.t(location.state && location.state.title)}
            </h3>
            <div className="boards">
                { boards ?
                    boards.length > 0 ?
                        boards.map(board => <Card board={board} key={board.id} />)
                        :
                        <p>
                            <Link to="/store">
                                {i18n.t("Sorry. No boards found")}          
                            </Link>
                        </p>
                    :
                    i18n.t("Loading...")
                }
                
            </div>
        </div>
    )
}
