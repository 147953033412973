import React from 'react';
import { Link } from 'react-router-dom';
import { FaPlusCircle } from 'react-icons/fa';
import { routePath } from 'routes.js';
import './Buttons.scss';
import i18n from 'i18n/config';

export default class Buttons extends React.Component {
  constructor(props) {
    super(props);
    this.buttons = this.props.links.map((link) => {
      if (link.name) {
        return (
          <Link key={link.path} to={routePath(link, { id: this.props.id })}>
            <button className="form-button">
              <FaPlusCircle />
              {i18n.t(link.name)}
            </button>
          </Link>
        );
      }
      return link;
    });
  }

  render() {
    return <div className="card-button">{this.buttons}</div>;
  }
}
