import React from 'react';
import { Route, Switch } from "react-router-dom";
import { ROUTES } from 'routes';

import "./livox.scss"
import StoreIndex from 'components/store/Index';
import { createBrowserHistory } from 'history';
import { fetchUserData, getUserLogin, loginSuccess } from 'utils/currentUser';
import Auth from 'components/auth/Auth';
import LivoxHome from './Home';
import LivoxEdit from './Edit';
import LivoxSettings from './settings/Settings';
import Navbar from './common/Navbar';
import Setup from './setup/Setup';
import LivoxPlay from './Play';
import UserAvatar from './common/Avatar';
import { AppContext } from './Reducer';

export default function LivoxWeb() {

    const { holder, dispatch } = React.useContext(AppContext);

    const root = document.documentElement;
    root.style.setProperty('--innerHeight', `${window.innerHeight  }px`);
    document.body.style.setProperty('background-color', "green");

    window.onresize = function () {
        root.style.setProperty('--innerHeight', `${window.innerHeight  }px`);
    }

    const history2 = createBrowserHistory();
    history2.listen((location) => {
        console.log(location)
    });

    if (!getUserLogin()){

        function onLoginSuccess(token){
            loginSuccess(token);
            fetchUserData().then((data) => {               
                dispatch({ type: "CHANGE_APP_DATA", payload: { hasHolder: data.has_holder  }})
            });
        }

        return (
            <Auth loginSuccess={onLoginSuccess} />
        )
    }

    if (!holder.hasFinishedTutorial) {
        return (
            <Setup />
        )
    }


    if (!holder.selectedUser) {
        return (
            <div className="livox-app">
                <div className="user-picker">
                    <h1> Please select an user to continue</h1>
                    <div className="users">
                        {
                            Object.entries(holder.users).map(([id, user]) =>
                                <button className="user" key={id}
                                    onClick={() => dispatch({ type: "CHANGE_USER", payload: { id } })}>
                                    <div className="user-card">
                                        <UserAvatar user={user} size={100} />
                                        <span className="user-name"> {user.name} </span>
                                    </div>
                                </button>
                            )}
                    </div>

                </div>
            </div>
        )
    }

    return (
        <div className="livox-app">
            <Navbar />
            <div className="main">
                <Switch>
                    <Route path={ROUTES.LIVOX_WEB.path} exact component={LivoxHome} />
                    <Route path={ROUTES.LIVOX_WEB_STORE.path} component={StoreIndex} />
                    <Route path={ROUTES.LIVOX_WEB_PLAY.path} component={LivoxPlay} />
                    <Route path={ROUTES.LIVOX_WEB_SETTINGS.path} component={LivoxSettings} />
                    <Route path={ROUTES.LIVOX_WEB_EDIT.path} component={LivoxEdit} />
                </Switch>
            </div>
        </div>

    )
}
