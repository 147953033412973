import React from "react";
import { CLOUD_URL } from "constants.js";
import { InputField, renderForm, getFormParams } from "utils/form";
import Categories from "./categories";
import ImageField from "./imageField";
import { FaCheck } from "react-icons/fa";
import i18n from "i18n/config";
import "./publish.scss";
import { getUserData } from "utils/currentUser";

export const LANGUAGE_OPTIONS = [
  { value: "pt-BR", label: i18n.t("Portuguese (BR)") },
  { value: "en-US", label: i18n.t("English") },
  { value: "ar-SA", label: i18n.t("Arabic") },
  { value: "de-DE", label: i18n.t("German") },
];

export default class PublicationForm extends React.Component {
  constructor(props) {
    super(props);

    this.title = "Publish Content";

    if (props.mode === "edit") {
      this.contentId = props.ContentId;
      this.endpoint = `${CLOUD_URL}/content/${this.contentId}/publish/`;
      this.title = "Edit Content";
      this.onCancel = props.onCancel;
      this.actionName = "Update";
    } else {
      this.contentId = props.functions.uuid;
      this.endpoint = `${CLOUD_URL}/content/${this.contentId}/publish/`;
      this.title = "Publish Content";
      this.onCancel = props.functions.setModal;
      this.actionName = "Publish";
    }

    const state = {};
    state.coverImage = "";
    state.selected = [];
    state.loaded = false;
    state.success = false;

    state.fields = [
      <ImageField
        key="image"
        uuid={this.contentId}
        getImage={this.getImageUrl.bind(this)}
        setImage={this.setImageUrl.bind(this)}
      />,
      new InputField("Published Title", "title", true, {
        size: 0.77,
        max: 140,
      }),
      new InputField("Description", "description", true, {
        type: "textarea",
        size: 1,
        min: 10,
      }),
      new InputField("Language", "language", true, {
        type: "select",
        options: LANGUAGE_OPTIONS,
        size: 1,
        defaultValue: "pt-BR",
      }),
      <Categories
        key="categories"
        selected={this.getSelected.bind(this)}
        setSelected={this.setSelected.bind(this)}
      />,
      <span className="tos" key="tos">
        {" "}
        {i18n.t(
          "By clicking 'Publish' you are agreeing that you have read and accepted our"
        )}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`http://localhost:3000/store-terms/${
            getUserData().language
          }.html`}
        >
          {" "}
          {i18n.t("terms of use")}
        </a>
        .
      </span>,
    ];

    this.state = state;
  }

  getImageUrl() {
    return this.state.coverImage;
  }

  setImageUrl(file) {
    this.setState({ coverImage: file });
  }

  getSelected() {
    return this.state.selected;
  }

  setSelected(selected) {
    this.setState({ selected });
  }

  beforeSubmitting(values) {
    values.coverImage = this.state.coverImage;
    values.categories = this.state.selected;
  }

  componentDidMount() {
    const params = getFormParams(this);
    const that = this;
    params.then(({ default_values }) => {
      that.setState({
        loaded: true,
      });

      if (default_values !== null) {
        that.setState({
          coverImage: default_values.coverImage,
          selected: default_values.Categories.map((cat) => cat.id),
        });
      }
    });
  }

  onFormSuccess() {
    alert(
      i18n.t(
        "You have successfully submitted this board for reviewing, please wait up to 48 hours to be approved."
      )
    );
    window.location.href = "/store/self";
    // this.setState({ success: true });
  }

  onFormCancel() {
    this.onCancel();
  }

  onFormFail(err) {
    let errors = "";
    err.forEach((error) => {
      errors += `${i18n.t(error.message)}\n`;
    });
    alert(errors);
  }

  render() {
    if (!this.state.loaded) {
      return <h2>{i18n.t("Loading...")}</h2>;
    }

    return (
      <>
        {this.state.success ? (
          <div className="success-publishing">
            <FaCheck />
            <h1>
              {i18n.t(
                "You have successfully submitted this board for reviewing, please wait up to 48 hours to be approved."
              )}
            </h1>
            <button onClick={() => this.onCancel()}> {i18n.t("Close")} </button>
          </div>
        ) : (
          renderForm(this)
        )}
      </>
    );
  }
}
