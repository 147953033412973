import React, { useEffect, useState } from 'react';
import './featured.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { get } from 'utils/fetch';
import { CLOUD_URL } from 'constants.js';
import { Link, useRouteMatch } from 'react-router-dom';
import { ROUTES, routePath } from 'routes';
import parameterize from 'parameterize';
import { getUserData } from 'utils/currentUser';
import { FiStar } from 'react-icons/fi';
import i18n from "i18n/config"
import qs from 'qs';

export default function FeaturedSection({ localization, location }) {

    let languageQuery;
    if (location){
        const query = qs.parse(location.search, { ignoreQueryPrefix: true });
        languageQuery = query.lng
    }

    const [promoted, setPromoted] = useState(null);

    const languageMap = {
        "ar-SA": "ar-SA",
        "pt-BR": "pt-BR",
        "en": "en-US",
    }

    let local = languageQuery || languageMap[getUserData().language] || localStorage.getItem("i18nextLng") || "en-US";
    localization = localization || "any";
    if (localization !== "any") {
        local = localization;
    }

    useEffect(() => {
        get(`${CLOUD_URL}/promoted/`).then(({ results }) => {

            const localContent = results.filter(result => result.Content.Publications[0].language === local);
            setPromoted(localContent);
        });
    }, [local]);

    if (promoted === null) return null

    return (
        <div className="featured-area">
            <Carousel
                infiniteLoop
                centerMode={false}
                showThumbs={false}
                centerSlidePercentage={95}
            >
                {
                    promoted.map(promo =>
                        <FeaturedContent key={promo.ContentId} content={promo} />
                    )
                }

            </Carousel>
        </div>
    )
}

export function FeaturedContent({ content }) {
    const match = useRouteMatch();

    return (
        <Link to={`${match.path}/${routePath(ROUTES.STORE_PUBLICATION, { title: parameterize(content.title), ContentId: content.ContentId })}?utm_source=banner`}>
            <div className="featured" style={{
                backgroundImage: `url(${content.image || `https://storage.googleapis.com/uploaded-items/banners/${content.ContentId}?q=${content.updatedAt}`})`,
                backgroundPosition: `${content.posX || 50}% ${content.posY || 50}%`
            }}>
                <div className="featured-badge">
                    <FiStar /> {i18n.t('Featured')}
                </div>
                <div className="description">
                    <div className="title">
                        {content.title}
                    </div>
                    <div className="subtitle">
                        {content.subtitle}
                    </div>
                </div>
            </div>
        </Link>
    )
}
