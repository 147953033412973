import React from 'react';
import './auth.scss';
import i18n from 'i18n/config';
import { InputField, renderForm } from '../../utils/form';

export default class GenericRegister extends React.Component {
  constructor(props) {
    super(props);

    this.endpoint = `/authusers/generic-register/`;
    const state = {};
    state.fields = [
      new InputField('Name', 'name', true, { size: 1 }),
      new InputField('E-mail', 'username', true, { type: 'email', size: 1 }),
      new InputField('Password', 'password', true, {
        type: 'password',
        size: 0.5,
      }),
      new InputField('Confirm Password', 'password_confirm', true, {
        type: 'password',
        size: 0.5,
      }),
    ];

    this.actionName = 'Register';
    this.state = state;
  }

  jsonToQueryString(json) {
    return `?${Object.keys(json)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`
      )
      .join('&')}`;
  }

  onFormSuccess(res) {
    alert(res.message);
    window.location.pathname = '/';
  }

  beforeSubmitting(values) {
    values.language = i18n.language;
  }

  render() {
    return renderForm(this);
  }
}
