import { get } from './fetch';

const CURRENT_VERSION = 9;

export const permissions = {
  Superuser: 7,
  'Portal Admin': 6,
  'Portal Support': 5,
  'Distributor Admin': 4,
  'Distributor Support': 3,
  'Customer Admin': 2,
  'Customer Support': 1,
  Holder: 0,
};

const storageGet = (key) => window.localStorage.getItem(key);

const storageSet = (key, value) => {
  window.localStorage.setItem(key, value);
};

export const getUserLogin = () => {
  if (storageGet('loggedIn') === null) {
    storageSet('loggedIn', 'false');
  }
  return storageGet('loggedIn') === 'true';
};

export const loginSuccess = (token) => {
  storageSet('loggedIn', 'true');
  storageSet('token', token);

  // TODO forbid same user
  const users = JSON.parse(storageGet('users')) || [];
  users.push({ token });
  storageSet('users', JSON.stringify(users));
};

export const switchUser = (token) => {
  storageSet('token', token);

  const users = JSON.parse(storageGet('users'));
  const userIndex = users.findIndex((data) => data.token === token);
  storageSet('currentUser', JSON.stringify(users[userIndex].data));
  window.location.reload();
};

export const getLoggedUsers = () => {
  const users = JSON.parse(storageGet('users'));
  const valid = users.filter((user) => !!user.data);

  return valid;
};

export const logOut = () => {
  const users = JSON.parse(storageGet('users'));
  const userIndex = users.findIndex(
    (data) => data.token === storageGet('token')
  );
  users.splice(userIndex, 1);

  if (users.length === 0) {
    storageSet('loggedIn', 'false');
    storageSet('token', '');
    storageSet('currentUser', null);
  } else {
    storageSet('token', users[0].token);
    storageSet('currentUser', JSON.stringify(users[0].data));
  }

  storageSet('users', JSON.stringify(users));
};

export const getUserData = () => {
  if (
    storageGet('version') === null ||
    storageGet('version') < CURRENT_VERSION ||
    storageGet('currentUser') === 'undefined'
  ) {
    storageSet('loggedIn', 'false');
    storageSet('token', '');
    storageSet('currentUser', null);
    storageSet('version', CURRENT_VERSION);
    storageSet('users', '[]');

    window.location.reload();
    // fetchUserData().then(() => JSON.parse(storageGet('currentUser')) || []);
  }
  return JSON.parse(storageGet('currentUser')) || {};
};

export const fetchUserData = () => {
  const languageMap = {
    ar_SA: 'ar-SA',
    pt_BR: 'pt-BR',
    pt_PT: 'pt-BR',
    en_US: 'en',
  };

  return new Promise((resolve, reject) => {
    get('/authusers/myuser/').then((data) => {
      data.language = languageMap[data.language] || 'en';
      storageSet('version', CURRENT_VERSION);
      console.log('data', data);
      storageSet('currentUser', JSON.stringify(data));

      const users = JSON.parse(storageGet('users'));
      const userIndex = users.findIndex(
        (data) => data.token === storageGet('token')
      );
      users[userIndex] = { ...users[userIndex], data };
      storageSet('users', JSON.stringify(users));

      resolve(data);
    });
  });
};

export const getUserLevel = () => permissions[getUserData().permissions];

export const hasPermission = (permission) => {
  // if (permission === "Holder" && getUserData().has_holder === false){
  //     return false
  // }

  const userPermission = getUserData().permissions;

  const permissionLevel = permissions[permission];
  const userLevel = permissions[userPermission];

  if (
    userLevel === undefined ||
    (permission && !Number.isInteger(permissionLevel))
  ) {
    console.error('Permission does not exist', userPermission, permission);
  }

  return !permission || userLevel >= permissionLevel;
};

export const userLanguage = () =>
  getUserData().language || storageGet('i18nextLng') || 'en';

export const setUserLanguage = (lng) => {
  const data = getUserData();
  data.language = lng;
  storageSet('currentUser', JSON.stringify(data));
  window.location.reload();
};
