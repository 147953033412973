import cloneDeep from 'lodash.clonedeep';
import UUID from 'uuid/v4';
import { historyPush, save } from './history';
import removeParentReference from './stringifier';

function refreshItem(node, parent) {
  node.id = UUID();
  node.parent = parent;
  node.children?.forEach((child) => refreshItem(child, parent));
}

// Changes all references and Ids
function clipboardClone(node, functions) {
  const targetNode = cloneDeep(node);
  refreshItem(targetNode, null);
  localStorage.setItem(
    'clipboard',
    JSON.stringify(targetNode, removeParentReference)
  );
}

export default class StudioClipboard {
  static canPaste() {
    return !!localStorage.getItem('clipboard');
  }

  static copy(target, functions) {
    clipboardClone(target, functions);
  }

  static cut(target, functions) {
    const node = target;

    // should sync with Item.js
    const isEditingNode = (node) =>
      functions.editingNode && functions.editingNode.id === node.id;

    if (isEditingNode(node)) {
      functions.setEdit(null);
      functions.setSidebar(null);
    }

    // Is root node
    if (node.parent === null) {
      return false;
    }

    historyPush(functions);

    clipboardClone(target, functions);
    node.parent.children = node.parent.children.filter(
      (child) => child.id !== node.id
    );
    save(functions);
  }

  static multiSelectCards(node, functions) {
    functions.setMultiSelectCards((prev) => {
      if (prev.some((card) => card.id === node.id)) {
        return prev.filter((card) => card.id !== node.id);
      }
      return [...prev, node];
    });
  }

  static cutMultiSelectCards(functions) {
    functions.multiSelectCards.forEach((node) => {
      node.parent.children = node.parent.children.filter(
        (child) => child.id !== node.id
      );
      save(functions);
    });

    this.pasteMultiSelectCards(functions, false);

    functions.setIsActiveMultiSelectCards(false);
    functions.setIsActivePasteMultiSelectCards(false);
    functions.setIsActiveCutMultiSelectCards(false);
    functions.setMultiSelectCards([]);
  }

  static pasteMultiSelectCards(functions, resetMultiSelectStates = true) {
    if (
      functions.multiSelectCards &&
      functions.isActiveMultiSelectCards &&
      (functions.isActivePasteMultiSelectCards ||
        functions.isActiveCutMultiSelectCards)
    ) {
      const clipboardData = functions.multiSelectCards;

      historyPush(functions);

      // Refresh UUID for the next paste
      const targetNode = cloneDeep(clipboardData);
      targetNode.forEach((item) => refreshItem(item, null));
      const newNodeData = targetNode.map((item) => ({
        ...item,
        parent: functions.currentItem,
      }));
      functions.currentItem.children.push(...newNodeData);

      save(functions);
    }
    if (resetMultiSelectStates) {
      functions.setIsActiveMultiSelectCards(false);
      functions.setIsActivePasteMultiSelectCards(false);
      functions.setIsActiveCutMultiSelectCards(false);
      functions.setMultiSelectCards([]);
    }
  }

  static deleteAllNodes(e, functions) {
    if (e) e.stopPropagation();

    //    if (isEditingNode(node)) {
    functions.setEdit(null);
    functions.setSidebar(null);
    //    }

    // Is root node
    const hasRootNode = functions.multiSelectCards.some((node) => {
      if (node.parent === null) {
        return true;
      }
      return false;
    });

    if (hasRootNode) return;

    historyPush(functions);
    functions.currentItem.children = functions.currentItem.children.filter(
      (child) =>
        !functions.multiSelectCards.some((card) => card.id === child.id)
    );
    save(functions);

    functions.setIsActiveMultiSelectCards(false);
    functions.setIsActivePasteMultiSelectCards(false);
    functions.setIsActiveCutMultiSelectCards(false);
    functions.setMultiSelectCards([]);
  }

  static paste(target, functions) {
    const localStorageData = JSON.parse(localStorage.getItem('clipboard'));
    if (localStorageData) {
      functions.addParentReference(localStorageData, null);
    }

    if (!localStorageData) {
      console.log('No clipboard!');
      return;
    }

    const clipboardData = localStorageData;

    historyPush(functions);
    const newNodeData = clipboardData;
    newNodeData.parent = target;
    target.children.push(newNodeData);

    // Refresh UUID for the next paste
    clipboardClone(clipboardData, functions);

    save(functions);
  }
}
