import React, { useEffect, useState } from 'react';
import { get } from 'utils/fetch';
import './sponsorDashboard.scss';
import Chart from 'react-google-charts';
import { FiArrowLeft, FiMail, FiMapPin, FiPhone } from 'react-icons/fi';
import DatePicker from 'utils/data-view/DatePicker';
import i18n from 'i18n/config';
import { DashboardCard } from '../portal/view/views/dashboard/DashboardSection';

export default function SponsorDashboard({ match }) {
  const [response, setResponse] = useState({});

  const uuid = match.params.customer_id;
  const [days, setDays] = useState(30);

  useEffect(() => {
    get(`/customers/dashboard/${uuid}?days=${days}`).then((res) => {
      setResponse(res);
    });
  }, [uuid, days]);

  const cardsData = {
    sections: [
      {
        title: 'Licenses',
        ammount: response.licenses,
        rate: response.new_licenses,
      },
      {
        title: 'Devices',
        ammount: response.devices,
      },
      {
        title: 'Users',
        ammount: response.users,
      },
    ],
  };

  const handleDateChange = (e) => {
    setDays(e);
  };

  return (
    <div className="">
      <div className="dashboard-grid dashboard-section">
        <div className="header">
          <FiArrowLeft
            onClick={() => window.history.back()}
            aria-label="Go back"
            tabIndex="1"
          />
          {response.customer_name ? (
            <h2> {response.customer_name} - Dashboard</h2>
          ) : (
            <h2> Loading Dashboard...</h2>
          )}
        </div>
        {response.customer_name ? (
          <div className="contact-info">
            <div>
              <FiMail /> {response.customer_email || '(Not Supplied)'}
            </div>
            <div>
              <FiPhone />
              {response.customer_phone}
            </div>
            <div>
              <FiMapPin /> {response.customer_address}
            </div>
          </div>
        ) : null}
        <DashboardCard card={cardsData} />
        {!response.usage || !response.deficiency_dist ? (
          <div className="chart-loader" />
        ) : (
          <div className="graphs">
            <div className="card">
              <div
                className="header"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <h3 className="title">{i18n.t('Device usage (%)')}</h3>

                <DatePicker handleDateChange={handleDateChange} />
              </div>

              <Chart
                width="100%"
                height="250px"
                chartType="LineChart"
                loader={<div className="chart-loader" />}
                data={response.usage}
                options={{
                  legend: 'none',
                  vAxis: {
                    viewWindowMode: 'explicit',
                    viewWindow: {
                      min: 0,
                    },
                  },
                }}
              />
            </div>
            <div className="card">
              <div className="header">
                <h3 className="title">{i18n.t('Disability distribution')}</h3>
              </div>
              <Chart
                width="100%"
                height="250px"
                chartType="PieChart"
                loader={<div className="chart-loader" />}
                data={response.deficiency_dist}
                options={{
                  pieSliceText: 'value',
                  pieHole: 0.42,
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
