import { useState, useEffect, useMemo } from 'react';
import i18n from 'i18n/config';
import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi';
import './settings.scss';
import { ColorSchemeToggler } from 'utils/darkmode';
import { LanguagePicker } from 'utils/language-picker';
import { save } from '../utils/history';

function getOrientation() {
  return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
}

export default function Settings({ functions }) {
  const [orientation, setOrientation] = useState(getOrientation());

  const setCollumns = (ammount) => {
    functions.updateSetting('collumns', ammount);
    const newTree = functions.tree;
    newTree.collumns = ammount;
    save(functions);
  };

  const collumns = useMemo(() => {
    const actualColumns = functions.tree.collumns
      ? functions.tree.collumns
      : functions.settings.collumns;
    setCollumns(actualColumns);
    return actualColumns;
  }, [functions.tree.collumns, functions.settings.collumns]);

  const setRows = (ammount) => {
    functions.updateSetting('rows', ammount);
    const newTree = functions.tree;
    newTree.rows = ammount;
    save(functions);
  };

  const rows = useMemo(() => {
    const actualRows = functions.tree.rows
      ? functions.tree.rows
      : functions.settings.rows;
    setRows(actualRows);
    return functions.tree.rows ? functions.tree.rows : functions.settings.rows;
  }, [functions.tree.rows, functions.settings.rows]);

  window.onorientationchange = function () {
    setOrientation(getOrientation());
  };

  useEffect(() => {
    setRows(rows);
    setCollumns(collumns);
  }, [
    functions.tree.collumns,
    functions.settings.collumns,
    functions.tree.rows,
    functions.settings.rows,
  ]);

  return (
    <div className="section settings">
      <h2 className="header">{i18n.t('Display')}</h2>

      <div className="setting">
        {orientation === 'landscape' ? (
          <span>{i18n.t('Collumns')}</span>
        ) : (
          <span>{i18n.t('Rows')}</span>
        )}
        <div className="stepper">
          <FiMinusCircle
            onClick={() => collumns > 2 && setCollumns(collumns - 1)}
          />
          <span className="count">{collumns}</span>
          <FiPlusCircle
            onClick={() => collumns < 10 && setCollumns(collumns + 1)}
          />
        </div>
      </div>

      <div className="setting">
        {orientation === 'landscape' ? (
          <span>{i18n.t('Rows')}</span>
        ) : (
          <span>{i18n.t('Collumns')}</span>
        )}

        <div className="stepper">
          <FiMinusCircle onClick={() => rows > 2 && setRows(rows - 1)} />
          <span className="count">{rows}</span>
          <FiPlusCircle onClick={() => rows < 10 && setRows(rows + 1)} />
        </div>
      </div>

      <div className="setting">
        <span>{i18n.t('Livox IntelliBar (Beta)')}</span>
        <div className="stepper">
          <label className="form-switch">
            <input
              type="checkbox"
              checked={functions.settings.intellibar}
              onChange={(e) =>
                functions.updateSetting('intellibar', e.target.checked)
              }
            />
            <i />
          </label>
        </div>
      </div>

      <h2 className="header">{i18n.t('Print')}</h2>

      <div className="setting">
        <span>{i18n.t('Print navigation card')}</span>
        <div className="stepper">
          <label htmlFor="printNavigationCard" className="form-switch">
            <input
              id="printNavigationCard"
              type="checkbox"
              checked={functions.settings.printNavigationCard}
              onChange={(e) =>
                functions.updateSetting('printNavigationCard', e.target.checked)
              }
            />
            <i />
          </label>
        </div>
      </div>

      <div className="setting">
        <span>{i18n.t('Print all pages')}</span>
        <div className="stepper">
          <label htmlFor="printAllPages" className="form-switch">
            <input
              id="printAllPages"
              type="checkbox"
              checked={functions.settings.printAllPages}
              onChange={(e) =>
                functions.updateSetting('printAllPages', e.target.checked)
              }
            />
            <i />
          </label>
        </div>
      </div>

      <h2 className="header">{i18n.t('System')}</h2>

      <ColorSchemeToggler />

      <div className="setting">
        <span style={{ alignSelf: 'center' }}>{i18n.t('Language')}</span>
        <LanguagePicker />
      </div>
    </div>
  );
}
