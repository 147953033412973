import './tree.scss';
import i18n from 'i18n/config';
import Media from 'utils/Media';
import { useEffect } from 'react';
import Item from './Item';
import NewItem from './NewItem';

export function Paginator({ onClick, icon, functions, active, order, name }) {
  if (functions.mode !== 'view' || !active) return null;

  function clickHandler() {
    if (!functions.getAllowedClick()) {
      return;
    }

    if (functions.settings.arrowUtterance) {
      functions.speak(i18n.t(name));
    }

    // Bubble Up
    onClick();
  }
  function intellitouchClick() {
    if (functions.settings.intellitouchEnabled) {
      clickHandler();
    }
  }

  return (
    <div className={`item ${name}`}>
      <li
        className="paginator"
        onClick={clickHandler}
        onTouchStart={intellitouchClick}
      >
        <Media
          filename={icon}
          placeholder
          contentId={functions.contentId}
          style={{
            backgroundColor: 'white',
          }}
        />
        {functions.currentItem.pageMode !== 'book' && (
          <span className="written-text"> {i18n.t(name)} </span>
        )}
      </li>
    </div>
  );
}

export default function Tree({ functions, children, isStore }) {
  const { collumns } = functions.settings;
  const { rows } = functions.settings;
  let itemsPerPage = collumns * rows;
  let hasNext = functions.pageOffset + itemsPerPage <= children.length;
  let hasPrev =
    functions.pageOffset > 0 || functions.currentItem.parent !== null;

  let items = [];
  let rollbackOffset = 0;
  let realItems;
  if (functions.mode !== 'view') {
    items = children;
  } else if (
    functions.currentItem.pageMode === 'book' &&
    functions.mode === 'view'
  ) {
    itemsPerPage = 3;
    hasNext = 1;
    hasPrev = 1;
    realItems = 1;
    if (functions.currentItem) {
      items = [functions.currentItem]
        .concat(children)
        .slice(functions.pageOffset, functions.pageOffset + realItems);
    } else {
      children.slice(functions.pageOffset, functions.pageOffset + realItems);
    }
    rollbackOffset = -1;
  } else {
    let discount = 0;
    if (
      functions.currentItem.pageMode === 'item' ||
      !functions.currentItem.pageMode
    ) {
      discount = hasNext + hasPrev;
    }

    // Discount in paginator on item mode
    realItems = itemsPerPage - discount;
    items = children.slice(
      functions.pageOffset,
      functions.pageOffset + realItems
    );

    if (
      functions.currentItem.pageMode === 'item' ||
      !functions.currentItem.pageMode
    ) {
      // If we go back on a page, will it be the first?
      rollbackOffset = functions.pageOffset - itemsPerPage + 1 !== 0;
    } else {
      rollbackOffset = -1;
    }
  }

  function goBack() {
    if (
      functions.pageOffset === 0 &&
      functions.currentItem.parent !== null &&
      functions.currentItem.pageMode !== 'book'
    ) {
      // Return to parent
      functions.setItem(functions.currentItem.parent);
    } else if (functions.currentItem.pageMode !== 'book') {
      // Go back in page, preventing a negative offset
      const offSet = Math.max(
        functions.pageOffset - itemsPerPage + rollbackOffset + 1,
        0
      );
      functions.setOffset(offSet);
    } else if (
      functions.currentItem.pageMode === 'book' &&
      functions.pageOffset > 0
    ) {
      functions.setOffset(functions.pageOffset - 1);
    } else if (functions.pageOffset === 0) {
      if (functions.currentItem.parent) {
        functions.setItem(functions.currentItem.parent);
        functions.setOffset(0);
      }
    }
  }

  function goNext() {
    if (functions.currentItem.children.length > functions.pageOffset + 1) {
      functions.setOffset(functions.pageOffset + realItems);
    } else if (
      functions.currentItem.pageMode === 'book' &&
      functions.currentItem.children.length > functions.pageOffset
    ) {
      functions.setOffset(functions.pageOffset + realItems);
    } else if (functions.currentItem.parent) {
      functions.setItem(functions.currentItem.parent);
      functions.setOffset(0);
    } else {
      functions.setOffset(0);
    }
  }

  useEffect(() => {
    if (
      functions.mode === 'view' &&
      functions.currentItem.pageMode === 'book'
    ) {
      if (functions.pageOffset === 0) {
        if (
          functions.currentItem.states &&
          functions.currentItem.states.length > 0
        ) {
          functions.speak(functions.currentItem.states[0].spokenText);
        } else {
          functions.speak(functions.currentItem.spokenText);
        }
      } else if (
        functions.currentItem.children[functions.pageOffset - 1].states &&
        functions.currentItem.children[functions.pageOffset - 1].states.length >
          0
      ) {
        functions.speak(
          functions.currentItem.children[functions.pageOffset - 1].states[0]
            .spokenText
        );
      } else {
        functions.speak(
          functions.currentItem.children[functions.pageOffset - 1].spokenText
        );
      }
    }
  }, [functions.mode, functions.pageOffset, functions.currentItem.pageMode]);

  useEffect(() => {
    functions.setOffset(0);
  }, [functions.mode]);

  // On swipe pagination on view mode we render everything at once with no pagination
  if (functions.mode === 'view' && functions.currentItem.pageMode === 'swipe') {
    const partitionedItems = [];
    for (let i = 0; i < children.length; i += itemsPerPage) {
      partitionedItems.push(children.slice(i, i + itemsPerPage));
    }

    return (
      <>
        {partitionedItems.map((partition, index) => (
          <div key={index} className="items">
            {partition.map((node, index) => (
              <Item
                node={node}
                functions={functions}
                index={index}
                key={node.id}
              />
            ))}
          </div>
        ))}
      </>
    );
  }

  return (
    <div className="items">
      <style type="text/css" media="print">
        {
          '\
      @page { size: landscape; }\
      '
        }
      </style>
      <Paginator
        name="Back"
        order={2}
        onClick={goBack}
        functions={functions}
        active={hasPrev}
        icon="left_arrow"
      />
      {items.map((node, index) => (
        <Item node={node} functions={functions} index={index} key={node.id} />
      ))}
      <Paginator
        name="Next"
        order={4}
        onClick={goNext}
        functions={functions}
        active={hasNext}
        icon="right_arrow"
      />
      {functions.currentItem.pageMode === 'book' &&
        functions.mode === 'view' && (
          <h2 style={{ color: 'black', position: 'absolute', right: 30 }}>
            {functions.pageOffset + 1}/
            {functions.currentItem.children.length + 1}
          </h2>
        )}
      <NewItem functions={functions} />
    </div>
  );
}
