import { FiDelete, FiTrash } from 'react-icons/fi';
import { BiUserVoice } from 'react-icons/bi';

import Item from '../main/Item';
import './intellibar.scss';

export default function Intellibar({ functions }) {
  // const [currentPlaying, setPlaying] = useState(0);
  if (functions.mode !== 'view' || !functions.settings.intellibar) {
    return null;
  }

  function removeLast() {
    const newItems = [...functions.intellibar];
    newItems.pop();
    functions.setIntellibar(newItems);
  }

  function addUtterance(node) {
    return new Promise((resolve) => {
      const utterance = functions.speak(node.spokenText);
      utterance.addEventListener('end', () => {
        resolve();
      });
    });
  }

  async function playItems() {
    const playlist = [...functions.intellibar];
    for (const node of playlist) {
      await addUtterance(node);
    }
  }

  return (
    <div className="intellibar">
      <ul className="intellibar-items">
        {functions.intellibar.map((node, index) => (
          <li key={node.randomId} className="item">
            <Item
              node={node}
              functions={functions}
              index={index}
              key={node.id}
            />
          </li>
        ))}
      </ul>
      <div className="intellibar-controls">
        <button
          disabled={functions.intellibar.length === 0}
          className="play"
          onMouseDown={playItems}
          type="button"
        >
          <BiUserVoice />
        </button>
        <button
          disabled={functions.intellibar.length === 0}
          className="delete"
          onMouseDown={removeLast}
          type="button"
        >
          <FiDelete />
        </button>
        <button
          disabled={functions.intellibar.length === 0}
          className="delete"
          onMouseDown={() => functions.setIntellibar([])}
          type="button"
        >
          <FiTrash />
        </button>
      </div>
    </div>
  );
}
