import React, { useState, useEffect } from 'react';
import { Toggler, updateSetting } from './Interface';
import { FiMic, FiMicOff, FiXCircle } from 'react-icons/fi';
import { AppContext } from '../Reducer';
import i18n from 'i18n/config';
import { getUserData } from 'utils/currentUser';
import "./ai.scss";
import { useTranslation } from 'react-i18next';

export default function AISettings() {

    const { holder, dispatch } = React.useContext(AppContext);
    const canPlaySpeech = ('webkitSpeechRecognition' in window);
    const {settings} = holder.selectedUser;
    const keywords = new Set(settings.naturalConversationKeywords);
    const { t } = useTranslation();

    function addShortcut(text) {
        keywords.add(text);
        updateSetting("naturalConversationKeywords", [...keywords], dispatch)
    }

    function deleteKeyword(text) {
        keywords.delete(text);
        updateSetting("naturalConversationKeywords", [...keywords], dispatch)
    }

    // On natural conversation enable instance first name

    useEffect(() => {
        if (settings.naturalConversationEnabled) {
            addShortcut(holder.selectedUser.name.split(" ")[0]);
        }
    }, [addShortcut, holder.selectedUser.name, settings.naturalConversationEnabled])


    return (
        <div className="ai-settings settings-section">
            <div className="title-bar">
                <h2> {t('Artificial Inteligence')} </h2>
            </div>
            <div className="content">
                <h3>{t('Natural Conversation')} </h3>
                <Toggler label="Enable natural conversation"
                    description="Remote voice commands that can show appropriate boards depending on context. Requires a microphone and internet conection."
                    attribute="naturalConversationEnabled"
                    disabled={!canPlaySpeech}
                    disabledMessage="Speech recognition is currently unavailable in your browser"
                />

                <div className="keywords app-setting">
                    <div className="configure">
                        <div>
                            <div className="label">
                                {t("Trigger words")}
                            </div>
                            <div className="description">
                                <span>
                                    {t("Registered keywords that Livox will listen before issuing an action. Keywords are case insensitive.")}
                                </span>
                            </div>
                        </div>

                        <div>
                            <div className="buttons">
                                <Recognition addShortcut={addShortcut} />
                            </div>
                        </div>
                    </div>
                    <div className="keywords-list">
                        <ul>
                            {keywords.map(keyword =>
                                <li key={keyword}>
                                    <button onClick={() => deleteKeyword(keyword)}>
                                        <FiXCircle />
                                    </button>

                                    <span className="keyword">
                                        {keyword}
                                    </span>
                                </li>
                            )}
                        </ul>
                        {(!keywords || keywords.size === 0) ?
                            <span className="no-keywords"> {t("No keywords created")}</span>
                            :
                            null
                        }

                    </div>
                </div>


            </div>

        </div>

    )
}


function Recognition({ addShortcut }) {
    const canPlaySpeech = ('webkitSpeechRecognition' in window);
    const [status, setStatus] = useState("idle");
    const [recognition, setRecognition] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (canPlaySpeech) {
            let recognition;
            recognition = new window.webkitSpeechRecognition();
            recognition.lang = getUserData().language;

            recognition.onstart = function (event) {
                console.log('Speech has started');
                setStatus("recording");
                console.log(event);
            }

            recognition.onend = function (event) {
                console.log(status)
                setStatus("idle");
                console.log('Speech has ended');
                console.log(event);
            }

            recognition.onspeechend = function (event) {
                console.log('Speech has stopped being detected');
                recognition.stop();
                setStatus("idle");
                console.log(event);

            }

            recognition.onerror = function (event) {
                console.log('Speech has crashed');
                console.log(event);
                setStatus("error");

                if (event.error === "not-allowed") {
                    alert(i18n.t("You have blocked this website of using the Microphone."));
                } else if (event.error === "no-speech") {
                    alert(i18n.t("Couldn't detect speech, please check your microphone settings."));
                } else {
                    alert(i18n.t("Error: ") + event.error);
                }
            }

            recognition.onresult = function (event) {
                console.log(event);
                console.log(`Detected ${  event.results[0][0].transcript}`)
                addShortcut(event.results[0][0].transcript)
            }

            setRecognition(recognition)
        }
    },
        // eslint-disable-next-line
        [])

    function onPlayStart() {

        if (status === "recording") {
            recognition.stop();
            setStatus("idle");
            return;
        }

        recognition.start();

        // Check if the user allowed
        setStatus("recording");

    }

    function Button() {
        switch (status) {
            case "idle":
                return (
                    <button onClick={onPlayStart}>
                        <FiMic className="idle" title={i18n.t('Record a new shortcut via voice')} />
                        <span> {t("Record")} </span>
                    </button>
                )
            case "recording":
                return (
                    <button onClick={onPlayStart}>
                        <FiMicOff className="recording" title={i18n.t('Record a new shortcut via voice')} />
                        <span> {t("Cancel")} </span>
                    </button>
                )
            case "error":
                return (
                    <button onClick={onPlayStart}>
                        <FiMicOff className="error" title={i18n.t('Error while recording shorcut, please try again')} />
                        <span> {t("Error!")} </span>
                    </button>
                )
            default:
        }
    }

    return (
        <div className={`speech ${status} view-hide`}>
            {canPlaySpeech ?
                <Button />
                :
                <FiMicOff title={i18n.t('Your browser is not compatible with the Speech Recognition API, but you can manually enter the shortcuts below')} />
            }
        </div>
    )
}