import React, {useState} from 'react';
import { FaAngleDown } from 'react-icons/fa';
import i18n from "i18n/config"
import './ParentDescription.scss';

export default function ParentDescription({ data }) {

    const allowed = ["address", "city", "state", "zipcode", "phone", "country_code", 
        "first_name", "last_name", 'gender_display', 'age', 'diagnosis_name']
    const [dropdownOpen, setdropdownOpen] = useState(false)

    if (Object.entries(data).length === 0){
        return null;
    }

    const entries = Object.entries(data).filter(([key]) => allowed.includes(key)).map(([key, value], index) => 
        <div className="column" key={key} style={{marginRight: 30}}>
            <small className="ui header small" style={{ display: "block" }}>
                {i18n.t(key.charAt(0).toUpperCase() + key.slice(1).replace("_", " "))}
            </small>
            <span style={{fontWeight: 'bold'}}>{value}</span>
        </div>
    )

    return (
        <>
            <div className="parent-title">{`${data.name} - ${data.email}`}</div>
            <div className="card" style={{width: '100%', display: 'flex'}}  onClick={() => setdropdownOpen(!dropdownOpen)}>
                <div className={`card-content parent-data ${dropdownOpen ? "open" : "closed"}`} style={{position: 'relative', width: '95%'}}>
                    <div className="grid-container" style={{width: "95%"}}>
                        {entries}
                    </div>
                </div>
                <div className={`parent-data dropdown ${dropdownOpen ? "open" : "closed"}`}>
                    <FaAngleDown />
                </div>
            </div>
        </>        
    )

}