/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
export enum ActionTypes {
  ADD_CONTENT = "add_content",
  SET_FOCUSED = "set_focused",
  EXPAND_CARD = "expand_card",
  ADD_STATE = "add_state",
  UPDATE_STATE = "update_state",
  UPDATE_CARD = "update_card"
}
