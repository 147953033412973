import i18n from 'i18n/config';
import Media from 'utils/Media';
import { FiTrash } from 'react-icons/fi';

type StateType = {
  index: number;
  spokenText: string;
  writtenText: string;
};

type StatesProps = {
  functions: {
    editingNode: { states: [] };
    selectedState: { index: number };
    addNewState: (state: StateType) => void;
    removeState: (index: number) => void;
    setSelectedState: (state: StateType) => void;
  };
};

export default function States(props: StatesProps) {
  const { functions } = props;

  const { states } = functions.editingNode;

  function handleAddNewState() {
    let index = 0;
    if (functions.editingNode.states) {
      index = functions.editingNode?.states?.length;
    }
    const newState = {
      index,
      writtenText: 'Novo estado',
      spokenText: 'Novo estado',
      image1: 'communicate',
      backgroundColor: '#FFFFFF',
      borderColor: null,
      isVisible: true,
    };
    functions.addNewState(newState);
  }

  function handleSelectState(state: StateType) {
    functions.setSelectedState(state);
  }

  function handleRemoveState(index: number) {
    functions.removeState(index);
  }

  return (
    <div>
      <h2 className="header">{i18n.t('Estados')}</h2>
      <br />
      <div className="states">
        {states?.map((item: any) => (
          <div className="state-card" key={item.index}>
            <div
              key={item.index}
              className={`state-card ${
                functions.selectedState.index === item.index ? 'editing' : ''
              }`}
              onClick={() => handleSelectState(item)}
              style={{
                backgroundColor: item.backgroundColor,
                border: item.borderColor ? `3px solid ${item.borderColor}` : '',
                justifyContent: 'center',
              }}
            >
              {!item.onlyText && <Media filename={item.image1} />}
              <span className="written-text">
                {item.image1 ? item.writtenText : i18n.t('No image')}
              </span>
            </div>
            {item.index !== 0 && (
              <div
                className="remove"
                onClick={() => handleRemoveState(item.index)}
              >
                <FiTrash />
              </div>
            )}
          </div>
        ))}
        <div className="state-card" onClick={() => handleAddNewState()}>
          <img
            src="https://storage.googleapis.com/livox-images/full/plus_sign.png"
            alt={i18n.t('New state')}
          />
          <span>{i18n.t('New state')}</span>
        </div>
      </div>
    </div>
  );
}
