import React, { useState } from 'react';
import { FaWhatsapp, FaFacebook, FaTwitter, FaCopy } from 'react-icons/fa';
import i18n from "i18n/config";
import Modal from 'utils/modal';
import { FiShare2 } from 'react-icons/fi';
import { toast } from 'react-toastify';

export function Share({ publication, canonUrl }) {
    const [modal, setModal] = useState(false);
    const copyToClipboard = () => {
        const el = document.createElement('textarea');
        el.value = `${publication.title  } - ${  canonUrl}`;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toast.success("Copied to Clipboard!");
    };
    if (!navigator.share) {
        const shareModal = (<div className="share-modal">
            <h3>Share</h3>
            <div className="share-options">
                <a target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${canonUrl}`} style={{ backgroundColor: "#1778F2" }}>
                    <FaFacebook /> Facebook
                    </a>
                <a target="_blank" rel="noopener noreferrer" href={`whatsapp://send?text=${publication.title}\n${publication.description} - ${canonUrl}`} style={{ backgroundColor: "#128C7E" }}>
                    <FaWhatsapp /> Whatsapp
                    </a>
                <a target="_blank" rel="noopener noreferrer" href={`http://twitter.com/share?text=${publication.title}&url=${canonUrl}`} style={{ backgroundColor: "#1DA1F2" }}>
                    <FaTwitter /> Twitter
                    </a>
                <div onClick={copyToClipboard} style={{ backgroundColor: "#888" }}>
                    <FaCopy /> Copy to Clipboard
                    </div>
            </div>
        </div>);
        return (<>
            <Modal active={modal} content={shareModal} onCancel={() => setModal(false)} />
            <div className="action share">
                <button onClick={() => setModal(true)}>
                    <FiShare2 /> {i18n.t('Share')}
                </button>
            </div>
        </>);
    }
    function sharePublication() {
        const data = {
            title: publication.title,
            text: publication.description,
            url: window.location.href,
        };
        navigator.share(data);
    }
    return (<div className="action share">
        <button onClick={sharePublication}>
            <FiShare2 /> {i18n.t('Share')}
        </button>
    </div>);
}
