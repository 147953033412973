import { Component } from 'react';
import { FaSearch } from 'react-icons/fa';
import './search.scss';
import i18n from 'i18n/config';

function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

class Search extends Component {
  debouncedUpdateQuery = debounce((value) => {
    this.props.updateQuery('search', value);
  }, 500);

  handleChange(event) {
    this.setState({ search: event.target.value });
    this.debouncedUpdateQuery(event.target.value);
  }

  render() {
    return (
      <div className="search-bar">
        <div className="icon">
          <FaSearch />
        </div>
        <input
          onChange={this.handleChange.bind(this)}
          type="search"
          placeholder={i18n.t('Search')}
        />
      </div>
    );
  }
}

export default Search;
