import React from 'react';
import en from 'media/flags-square/en.png';
import ar from 'media/flags-square/ar-SA.png';
import pt from 'media/flags-square/pt-BR.png';

export default function Flag({ publication }) {
    const languages = {
        "en-US": en,
        "ar-SA": ar,
        "pt-BR": pt
    };

    if (!languages[publication.language]) return null;

    return (
        <div className="language">
            <img src={languages[publication.language]} alt={publication.language} title={publication.language} />
        </div>
    );
}
