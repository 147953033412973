import React from 'react';
import { InputField, renderForm } from 'utils/form';
import { CLOUD_URL } from 'constants.js';

export default class ApprovalForm extends React.Component {
  constructor(props) {
    super(props);

    this.title = 'Reject Publication';
    this.endpoint = `${CLOUD_URL}/publications/${props.functions.uuid}/reject/`;
    const state = {};
    state.fields = [
      new InputField('Reason', 'rejectReason', true, {
        size: 1,
        type: 'textarea',
        max: 255,
      }),
    ];
    this.encoding = 'application/json';
    this.state = state;
    this.actionName = 'Reject';
  }

  onFormCancel() {
    this.props.functions.setModal();
  }

  onFormSuccess() {
    window.history.back();
  }

  render() {
    return renderForm(this);
  }
}
