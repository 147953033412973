import React, { useCallback, useEffect, useState } from 'react';
import './publication.scss';
import { CLOUD_URL } from 'constants.js';
import { get, post } from 'utils/fetch';
import { FaCaretLeft, FaRegStar, FaRegEdit } from 'react-icons/fa';
import i18n from 'i18n/config';
import { getUserData, getUserLogin } from 'utils/currentUser';
import Modal from 'utils/modal';
import { Helmet } from 'react-helmet';
import Media from 'utils/Media';
import { Link } from 'react-router-dom';
import {
  FiStar,
  FiCornerUpRight,
  FiDownload,
  FiTrash,
  FiCloud,
  FiBook,
} from 'react-icons/fi';
import PublicationForm from 'components/studio/creator/modal/Publish';
import downloadZip from 'components/studio/creator/utils/download';
import parameterize from 'parameterize';
import Studio from 'components/studio/Studio';
import { AppContext } from 'components/livox-web/Reducer';
import { routePath, ROUTES } from 'routes';
import { DropDownList } from 'components/common/ddl';
import { Share } from './Share';
import FormPromotion from '../promoted/FormPromotion';
import Author from '../common/Author';
import Language from '../common/flags';
import CategoryBadges from '../common/badges';

function Categories({ publication }) {
  if (!publication.Categories.length) {
    return null;
  }

  return (
    <div className="badges">
      <CategoryBadges categories={publication.Categories} />
    </div>
  );
}

function Features({ publication }) {
  const features = [];

  if (publication.hasShortcuts) {
    features.push(
      <div className="feature-badge shortcut">
        <div
          className=""
          title="This allows for the user to open a board with voice commands"
        >
          <FiCornerUpRight /> {i18n.t('Voice Shortcuts')}
        </div>
      </div>
    );
  }

  if (publication.hasBookMode) {
    features.push(
      <div className="feature-badge book-mode">
        <div className="" title="Has a story inside!">
          <FiBook /> {i18n.t('Book Mode')}
        </div>
      </div>
    );
  }

  return <div className="features">{features}</div>;
}

function ImportButton({ publication }) {
  const appCtx = React.useContext(AppContext);
  // Check if it is inside the app
  if (!appCtx) {
    return null;
  }

  function getPublication() {
    appCtx.dispatch({
      type: 'DOWNLOAD_STORE_CONTENT',
      payload: { publication },
    });
  }

  return (
    <div className="action download">
      <button type="button" onClick={getPublication}>
        <FiCloud /> {i18n.t('Get')}
      </button>
    </div>
  );
}

function DownloadButton({ publication }) {
  const [downloading, setDownloading] = useState(false);
  const [modal, setModal] = useState(false);
  const options = [
    { name: 'v1', label: i18n.t('Livox4') },
    { name: 'v2', label: i18n.t('Livox5+') },
  ];

  const getZip = (version) => {
    setDownloading(true);

    get(`${CLOUD_URL}/store/${publication.ContentId}/download`);

    downloadZip({
      tree: publication.data,
      metadata: publication,
      contentId: publication.ContentId,
      version,
    }).then((status) => {
      setDownloading(false);
    });
  };

  const loginWarning = () => {
    setModal(true);
  };

  const onSelect = useCallback((option) => {
    if (!getUserLogin()) return loginWarning();
    getZip(option);
  });

  const [ddlVisible, setDdlVisible] = useState(false);

  const loginModal = (
    <Modal
      active={modal}
      onCancel={() => setModal(false)}
      textModal="You must be logged in to view this content"
      onConfirm={() =>
        (document.location.href = `/?next=${window.location.pathname}`)
      }
      textConfirm="Register"
    />
  );

  if (downloading) {
    return (
      <div className="action download downloading">
        <div className="loader" />
        <button type="button" disabled>
          {i18n.t('Downloading...')}
        </button>
      </div>
    );
  }
  /* getUserLogin() ? getZip : loginWarning */
  return (
    <div className="action download">
      {loginModal}
      <button type="button" onClick={() => setDdlVisible(!ddlVisible)}>
        <FiDownload /> {i18n.t('Download')}
      </button>
      <DropDownList
        options={options}
        visible={ddlVisible}
        updateVisible={setDdlVisible}
        onSelect={onSelect}
      />
    </div>
  );
}

export function EditButton({ ContentId }) {
  const [modal, setModal] = useState(false);
  if (getUserData().permissions !== 'Superuser') return null;

  return (
    <div className="promote-btn">
      <Modal
        active={modal}
        content={
          <PublicationForm
            ContentId={ContentId}
            mode="edit"
            onCancel={() => setModal(false)}
          />
        }
      />
      <div className="action promote">
        <div>
          <button type="button" onClick={() => setModal(true)}>
            <FiCloud />
            <span>{i18n.t('Resubmit')}</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export function EditBoardButton({ ContentId }) {
  if (getUserData().permissions !== 'Superuser') return null;

  return (
    <div className="promote-btn">
      <div className="action promote">
        <Link
          to={routePath(ROUTES.STUDIO_CREATOR, {
            uuid: ContentId,
            mode: 'edit',
          })}
        >
          <button type="button">
            <FaRegEdit />
            <span>{i18n.t('Edit')}</span>
          </button>
        </Link>
      </div>
    </div>
  );
}

export function PromoteButton({ ContentId }) {
  const [modal, setModal] = useState(false);
  if (getUserData().permissions !== 'Superuser') return null;

  return (
    <div className="promote-btn">
      <Modal
        active={modal}
        content={
          <FormPromotion
            contentId={ContentId}
            onCancel={() => setModal(false)}
          />
        }
      />
      <div className="action promote">
        <div>
          <button type="button" onClick={() => setModal(true)}>
            <FaRegStar />
            <span>{i18n.t('Promote')}</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export function DeleteButton({ id }) {
  function handleDeleteContent() {
    post(`${CLOUD_URL}/publications/${id}/delete/`).then((res) => {
      window.history.back();
    });
  }

  const [modal, setModal] = useState(false);
  return (
    <div>
      <Modal
        active={modal}
        onCancel={() => setModal(false)}
        textModal="Are you sure you want to delete this content?"
        onConfirm={handleDeleteContent}
        textConfirm="Remove"
        colorConfirm="#fb161b"
      />
      <div
        className={`action delete ${
          getUserData().permissions !== 'Superuser' ? 'hidden' : ''
        }`}
      >
        <div>
          <button type="button" onClick={() => setModal(true)}>
            <FiTrash />
            <span>{i18n.t('Delete')}</span>
          </button>
        </div>
      </div>
    </div>
  );
}

function Score({ publication }) {
  if (!publication.Content.score) {
    return (
      <div className="content-rating">
        <span>
          <FiStar /> {i18n.t('No votes yet')}
        </span>
      </div>
    );
  }

  return (
    <div className="content-rating">
      <FiStar className="filled" />
      <span> {publication.Content.score.toFixed(1)}</span>
    </div>
  );
}
function Evaluate({ publication }) {
  const [userVote, setVote] = useState(null);

  const endpoint = `${CLOUD_URL}/store/${publication.ContentId}/vote`;

  useEffect(() => {
    get(endpoint).then((res) => {
      setVote(res.selfVote);
    });
  }, [endpoint]);

  function vote(amm) {
    const isUndo = userVote === amm;

    if (!getUserLogin()) {
      return alert(i18n.t('You must be logged in to vote on this content!'));
    }

    if (isUndo) {
      setVote(null);
      // setScore(score - userVote)
      amm = 0;
    } else {
      // setScore(score + amm)
      setVote(amm);
    }

    post(endpoint, { isUndo, vote: amm }).then((res) => {
      // console.log(res)
    });
  }

  const stars = [];

  for (let index = 1; index <= 5; index += 1) {
    stars.push(
      <FiStar
        key={index}
        className={userVote >= index ? 'filled' : null}
        onClick={() => vote(index)}
      />
    );
  }

  return (
    <div className="evaluate">
      <h4>{userVote !== null ? i18n.t('Your rating') : i18n.t('Rate this')}</h4>
      <div className="stars">{stars}</div>
    </div>
  );
}

export default function Publication(props) {
  const id = props.match.params.ContentId;
  const { title } = props.match.params;
  const canonUrl = `https://mylivox.com/store/board/${title}/${id}/`;
  const [publication, setPublication] = useState(null);
  const endpoint = `${CLOUD_URL}/store/app/${id}`;

  useEffect(() => {
    get(endpoint).then((res) => {
      if (!getUserLogin()) {
        const languageMap = {
          'ar-SA': 'ar',
          'pt-BR': 'pt-BR',
          'en-US': 'en',
        };
        i18n.changeLanguage(languageMap[res.result.language]);
      }
      setPublication(res.result);
    });
  }, [endpoint]);

  if (!publication) {
    return 'Loading...';
  }

  const params = {
    uuid: publication.id,
    contentId: id,
    mode: 'view',
  };

  // Google structured data
  const googleData = {
    '@context': 'http://schema.org',
    '@type': 'SoftwareApplication',
    name: publication.title,
    description: publication.description,
    image: `https://storage.googleapis.com/uploaded-items/${publication.coverImage}`,
    applicationCategory:
      (publication.Categories[0] && publication.Categories[0].name) || '',
    datePublished: publication.createdAt,
    operatingSystem: 'Requires Livox app.',
    author: {
      '@type': 'Person',
      name: publication.Content.authorId,
      url: `https://mylivox.com/store/profile/user/${publication.Content.authorId}`,
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: publication.Content.score || 4,
      reviewCount: Math.floor(publication.Content.score * 251),
    },
    offers: {
      '@type': 'Offer',
      price: 0,
      priceCurrency: 'USD',
      category: 'free',
    },
  };

  return (
    <>
      <Helmet>
        <html lang={publication.language} />
        <title>{publication.title}</title>
        <meta
          name="description"
          content={
            publication.description.substr(0, 200) ||
            i18n.t('A content store made for you!')
          }
        />
        <link
          rel="canonical"
          href={`https://mylivox.com/store/board/${parameterize(
            publication.title
          )}/${id}/`}
        />
        <script name="schema:software-application" type="application/ld+json">
          {JSON.stringify(googleData)}
        </script>
      </Helmet>
      <div className="back-link hidden">
        <Link to="/store">
          <FaCaretLeft />
          {i18n.t('Back to Livox Portal')}
        </Link>
      </div>
      <div className="store-view">
        <div className="content">
          <div className="header">
            <div className="image">
              <Media
                filename={publication.coverImage}
                placeholder
                contentId={publication.ContentId}
              />
              <Language publication={publication} />
            </div>
            <div className="info">
              <div className="metadata">
                <h1 className="title">{publication.title}</h1>
                <Author id={publication.Content.authorId} />
                <Categories publication={publication} />
                <Features publication={publication} />
              </div>
            </div>
            <div className="evaluation">
              <Score publication={publication} />
              <Evaluate publication={publication} />
            </div>
          </div>
          <div className="actions">
            <PromoteButton ContentId={publication.ContentId} />
            <EditButton ContentId={publication.ContentId} />
            <EditBoardButton ContentId={publication.ContentId} />
            <DeleteButton publication={publication.id} />
            <Share publication={publication} canonUrl={canonUrl} />
            <DownloadButton publication={publication} />
            <ImportButton publication={publication} />
          </div>

          <div className="description">
            {publication.description || i18n.t('No description available.')}
          </div>
          <div className="device">
            <div className="frame">
              {publication.id ? (
                <Studio
                  match={{ params }}
                  data={publication.data}
                  meta={publication}
                  isStore
                />
              ) : (
                'Loading'
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
