import React, { useEffect, useState } from 'react';
import { get } from 'utils/fetch';
import './sponsorOverview.scss';
import Chart from "react-google-charts";
import { Link } from 'react-router-dom';
import { routePath, ROUTES } from 'routes';
import { getUserData } from 'utils/currentUser';

export default function SponsorOverview({ match }) {

    const [cards, setCards] = useState()
    const [usageData, setUsage] = useState()
    const [error, setError] = useState()

    const {uuid} = match.params;

    useEffect(() => {
        get(`/sponsors/dashboard/overview/${uuid}`).then(res => setCards(res.results)).catch(res => setError(res.message))
        get(`/sponsors/dashboard/engagement/${uuid}`).then(res => setUsage(res.results))
    }, [uuid]);

    if (error){
        return(interstitial(error))
    }

    if (!cards){
        return(interstitial("Loading..."))
    }

    function interstitial(message){
        return (
            <div className="sponsor-overview">
                <div className="sponsor-header">
                    <h2>
                        {message}
                    </h2>
                </div>
            </div>
        )
    }

    return (
        <div className="sponsor-overview">
            <div className="sponsor-header">
                <h2>
                    Welcome, {getUserData().full_name}
                </h2>
                <p className="stats">
                    You are sponsoring {cards.length} project(s), 
                    bringing alternative communication to {cards.reduce((a,b) => a + b.users, 0)} users!
                </p>
                <p className="instructions">
                    SELECT A CARD BELOW TO SEE DETAILS
                </p>
            </div>
            <div className="cards">
                {cards.map(card =>
                    <Link key={card.id} to={routePath(ROUTES.SPONSOR_DASHBOARD, {customer_id: card.id})}>
                        <div className="customer-card">
                            <div className="header">
                                <h2>{card.name}</h2>
                                <p>{card.devices} devices</p>
                                <p>{card.users} users</p>
                            </div>
                            {usageData ?
                            <Chart
                                width={75}
                                height={75}
                                chartType="Gauge"
                                loader={
                                    <div className="gauge-loader" />
                                }
                                data={[
                                    ['Label', 'Value'],
                                    ['% Usage', Math.ceil(usageData.find(({id}) => id === card.id).usage)],
                                ]}
                                options={{
                                    redFrom: 90,
                                    redTo: 100,
                                    yellowFrom: 75,
                                    yellowTo: 90,
                                    minorTicks: 5,
                                }}
                            />
                            :
                            <div className="gauge-loader" />
                            }
                        </div>
                    </Link>
                )}
            </div>
        </div>
    )
}