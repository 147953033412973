import React, { useReducer } from 'react';
import { ROUTES } from 'routes';

import removeParentReference from 'components/studio/creator/utils/stringifier';
import { toast } from 'react-toastify';
import { initializeSettings, onSettingsChange } from './utils/settingsInitializer.js';
import LivoxWeb from './Index';

export const AppContext = React.createContext();

// Debug
const reloadData = false;
const mockData = false;

const version = 1;

if (mockData) {
    if (reloadData || localStorage.getItem("livoxWebVersion") < version) {
        console.log("Reloaded")
        localStorage.setItem("livoxWebUser", JSON.stringify(require("./utils/mock.json")));
        localStorage.setItem("livoxWebBoards", JSON.stringify(require("./utils/boards.json")));
        localStorage.setItem("livoxWebVersion", version);
    }

} else if (!localStorage.getItem("livoxWebVersion")){

    const defaultUser = {
        "hasSeenTutorial": true,
        "licenseKey": "",
        "hasHolder": true,
        "users": {}
    }

    localStorage.setItem("livoxWebUser", JSON.stringify(defaultUser));
    localStorage.setItem("livoxWebBoards", JSON.stringify({}));
    localStorage.setItem("livoxWebVersion", version);

}

function saveState(state) {

    function removeBoardFromUser(key, value) {
        if (key === "board") {
            return undefined;
        } 
            return value;
        
    }

    // Split and serialize data
    const saveData = { ...state, selectedUser: null, boards: null };
    console.log(saveData);

    localStorage.setItem("livoxWebUser", JSON.stringify(saveData, removeBoardFromUser));
    localStorage.setItem("livoxWebBoards", JSON.stringify(state.boards, removeParentReference));

}

function initialize() {

    // Data retrieval
    const data = JSON.parse(localStorage.getItem("livoxWebUser"));
    data.boards = JSON.parse(localStorage.getItem("livoxWebBoards"));
    if (data.selectedUserId && data.users[data.selectedUserId]) {
        // Initialize a user and mix with defaults
        data.selectedUser = data.users[data.selectedUserId];
        data.selectedUser.settings = initializeSettings(data.selectedUser.settings);
        onSettingsChange(data.selectedUser.settings);
    }
    return data;

}

const reducer = (state, action) => {
    console.log(action.type)
    switch (action.type) {
        case "CHANGE_USER":

            const selectedUser = state.users[action.payload.id];
            // TODO verify if the users exists locally

            // TODO fetch updates

            // Merge defaults with user
            selectedUser.settings = initializeSettings(selectedUser.settings);
            // Apply settings to root
            onSettingsChange(selectedUser.settings);

            const newState = {
                ...state,
                selectedUser,
                selectedUserId: action.payload.id
            }
            saveState(newState);

            return newState;
        case "CHANGE_USER_SETTINGS":

            state.selectedUser.settings = { ...state.selectedUser.settings, ...action.payload.settings };
            console.log(action.payload.settings)
            onSettingsChange(state.selectedUser.settings);

            saveState(state);

            return {
                ...state,
            };
        case "CHANGE_APP_DATA":

            state = { ...state, ...action.payload };
            console.log(action.payload)
            saveState(state);

            return {
                ...state,
            };
        case "CHANGE_USER_BOARD":
            state.selectedUser.board = action.payload.data;

            saveState(state);

            return {
                ...state,
            };

        case "DOWNLOAD_STORE_CONTENT":

            const rootCards = state.boards[state.selectedUserId].data.children;
            const publicationData = action.payload.publication.data;

            console.log(rootCards, publicationData)
            // Check for duplicates
            if (rootCards.find(card => card.id === publicationData.id)) {
                toast.warn("You already have this content!")
                return state;
            }

            rootCards.push(publicationData)
            return {
                ...state,
            }

        case "CREATE_NEW_USER":
            // TODO update remote
            const newUser = action.payload.user;
            state.users[newUser.uuid] = newUser;
            state.boards[newUser.uuid] = {
                type: "user",
                data: require("./utils/intellibar-en.json")
            };

            const customDefaults = {}

            if (newUser.motor === "2"){
                // Shows Repetitive movements
                console.log("Enabling Delay of 0.3")
                customDefaults.touchFreezeTime = 0.3
            } else if (newUser.motor === "3"){
                // Imperfect touches
                console.log("Enabling Intellitouch")
                customDefaults.intellitouchEnabled = true;
            }

            newUser.settings = initializeSettings({ ...customDefaults, voiceURI: newUser.voiceURI, voiceLanguage: newUser.language });
            console.log(newUser)


            console.log(state)
            saveState(state);

            return {
                ...state,
                selectedUser: newUser,
                selectedUserId: action.payload.user.uuid
            }
        case "DELETE_USER":
            // TODO update remote
            const deleteTarget = action.payload.uuid;

            delete state.users[deleteTarget]
            delete state.boards[deleteTarget]

            saveState(state);

            window.location.href = ROUTES.LIVOX_WEB.path;
            return {
                ...state,
                selectedUser: null,
                selectedUserId: null
            }
        default:
            throw new Error("Invalid state", action, state);
    }
};

export default function LivoxWebMain() {

    const [holder, dispatch] = useReducer(reducer, null, initialize);

    return (
        <AppContext.Provider value={{ holder, dispatch }} >
            <LivoxWeb />
        </AppContext.Provider>

    )
}
