import React from 'react';
import { useTranslation } from 'react-i18next';
import { Toggler, Slider } from './Interface';
import { AppContext } from '../Reducer';


export default function ControlSettings() {
    const { holder } = React.useContext(AppContext);
    const {settings} = holder.selectedUser;
    const { t } = useTranslation();

    return (
        <div className="display-settings settings-section">
            <div className="title-bar">
                <h2> {t("Controls")} </h2>
            </div>
            <div className="content">
                <h3> {t("Touch correction")} </h3>
                <Toggler label="Enable Intellitouch"
                    description="Recommended for users with motor disabilities. Automatically detects impefect touches."
                    attribute="intellitouchEnabled" />
                <Slider label="Time to wait between touches"
                    description="Manually set time to prevent any further touches. Set zero seconds to disable."
                    lowerLimit={0.0}
                    step={0.1}
                    upperLimit={5.0}
                    decimals={1}
                    disabled={settings.intellitouchEnabled}
                    disabledMessage="Delay will be set dynamically by Intellitouch."
                    postfix="s"
                    attribute="touchFreezeTime" />
            </div>

        </div>
    )
}
