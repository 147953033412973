import React, { useState, useEffect } from 'react';
import { CLOUD_URL } from 'constants.js';
import { post } from 'utils/fetch';
import i18n from "i18n/config"
import { FaUpload } from 'react-icons/fa';
import Media from '../../../../utils/Media';

export default function ImageField({ uuid, setImage, getImage }) {

  const [imageUrl, setimageUrl] = useState(getImage());
  const [status, setStatus] = useState("loading");

  useEffect(() => {
      setTimeout(() => {
        setimageUrl(getImage());
        setStatus("idle");
      }, 1000)
    }
    // eslint-disable-next-line
    , [])

  function imageUpload(file) {
    setStatus("loading");

    const url = `${CLOUD_URL}/content/${uuid}/image-upload`;
    const formData = new FormData();
    formData.append('file', file);

    post(url, formData, 'multipart/form-data').then(res => {

      setImage(res.filename);
      setimageUrl(res.filename)
      setStatus("idle");
    }).catch(err => alert(err && err.errors.message));
  }

  let render;

  if (status === "loading") {
    render = <div className="loader" />
  } else if (imageUrl) {
    render = <Media filename={imageUrl} placeholder={false} contentId={uuid} />
  } else {
    render = <span className="placeholder"> <FaUpload /> {i18n.t('Upload Image')} </span>
  }

  return (
    <div className="image-input">
      <span> {i18n.t('Cover Image')} </span>
      <label htmlFor="uploadImage">
        {render}
      </label>
      <form className="hidden" onChange={(e) => imageUpload(e.target.files[0])}>
        <input type="file" id="uploadImage" accept="image/*" />
      </form>
    </div>
  )

}
