import React from 'react';
import { Link, Route, Switch } from "react-router-dom";
import { ROUTES } from 'routes';
import { logOut } from 'utils/currentUser';
import logo from "media/full-logo.png";
import SponsorDashboard from './SponsorDashboard';
import SponsorOverview from './SponsorOverview';
import SponsorUserRegister from './SponsorRegister';
import './index.scss';

export default function SponsorArea() {

    function logoff(){
        logOut();
        window.location.pathname = "/";
    }

    return (
        <div className="sponsor-dashboard">
            <div className="nav-bar">
                <div className="container">
                    <Link to="/">
                        <img src={logo}/>
                    </Link>
                    <div>
                        <button onClick={() => logoff()}> Logout </button>
                    </div>
                </div>
            </div>
            <Switch>
                <Route
                    path={ROUTES.SPONSOR_DASHBOARD.path}
                    component={SponsorDashboard} />
                <Route
                    path={ROUTES.SPONSOR_OVERVIEW.path}
                    component={SponsorOverview} />
                <Route
                    path={ROUTES.SPONSOR_USER_REGISTER.path}
                    component={SponsorUserRegister} />
            </Switch>
        </div>

    )
}
