import React, { useState, useEffect } from 'react';
import i18n from 'i18n/config';
import { FaTimes } from 'react-icons/fa';
import { getUserData } from 'utils/currentUser';
import {
  FiCornerUpRight,
  FiMic,
  FiMicOff,
  FiPlusCircle,
  FiMinusCircle,
} from 'react-icons/fi';
import { save } from '../utils/history';

export default function Shortcuts({ functions }) {
  const [leitura, setLeitura] = useState('');
  // Todo check shortcut duplication
  // Todo check shortcut duplication
  console.log(functions.currentItem.shortcuts);
  const [newShortcut, setNewShorcut] = useState('');
  if (!functions.currentItem.shortcuts) {
    functions.currentItem.shortcuts = [];
  }
  function deleteShortcut(text) {
    const shortcuts = [...functions.currentItem.shortcuts];
    functions.setAttribute(
      'shortcuts',
      shortcuts.filter((shortcut) => shortcut !== text)
    );
  }

  function addShortcut(shortcut) {
    if (shortcut !== '') {
      const shortcuts = [...functions.currentItem.shortcuts];
      shortcuts.push(shortcut || newShortcut);
      functions.setAttribute('shortcuts', shortcuts);
      setNewShorcut('');
    }
  }
  useEffect(() => {
    addShortcut(leitura);
  }, [leitura]);
  function handleRecognitionResult(result) {
    setLeitura(result);
  }

  const textSize = functions.settings.cardFontSize;
  const setTextSize = (ammount) => {
    functions.updateSetting('cardFontSize', ammount);
  };

  const pageModes = {
    item: 'Regular',
    book: 'Book Mode',
  };

  const { pageMode } = functions.currentItem;

  const changePagemode = (e) => {
    functions.setAttribute('pageMode', e.target.value);
    save(functions);
  };

  return (
    <div className="section shortcuts">
      <div className="setting">
        <span>{i18n.t('Page Mode')}</span>
        <div className="dropdrown">
          <select
            name="language"
            defaultValue={pageMode}
            onChange={changePagemode}
          >
            {Object.entries(pageModes).map(([mode, name]) => (
              <option value={mode} key={mode}>
                {i18n.t(name)}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="setting">
        <span>{i18n.t('Text Size')}</span>
        <div className="stepper">
          <FiMinusCircle
            onClick={() => textSize > 16 && setTextSize(textSize - 2)}
          />
          <span className="count">{textSize}</span>
          <FiPlusCircle
            onClick={() => textSize < 36 && setTextSize(textSize + 2)}
          />
        </div>
      </div>
      <h2 className="header">
        {i18n.t('Shortcuts')}
        <span>{i18n.t('NEW!')}</span>
      </h2>

      <div className="hero">
        <FiCornerUpRight />
      </div>

      <p>
        {' '}
        {i18n.t('Create voice commands to open this board on Livox app!')}{' '}
      </p>
      <p> {i18n.t('ExampleShortcut')} </p>

      <div className="shortcut-title">
        <h5>
          {functions.currentItem.writtenText} {i18n.t('shortcuts')}:
        </h5>
        <Recognition onResult={handleRecognitionResult} />
      </div>
      <div className="content scrollable">
        <div>
          {functions.currentItem.shortcuts.map((shortcut) => (
            <div className="shortcut" key={shortcut}>
              <span>{shortcut}</span>
              <span
                className="remove-shortcut view-hide"
                onClick={() => deleteShortcut(shortcut)}
              >
                <FaTimes />
              </span>
            </div>
          ))}
        </div>
        <div className="view-hide">
          <input
            value={newShortcut}
            onChange={(event) => setNewShorcut(event.target.value)}
            placeholder={i18n.t('Add a shortcut...')}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                addShortcut();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

function Recognition({ onResult }) {
  const canPlaySpeech = 'webkitSpeechRecognition' in window;
  const [status, setStatus] = useState('idle');
  const [recognition, setRecognition] = useState(null);

  useEffect(
    () => {
      if (canPlaySpeech) {
        let recognition;
        recognition = new window.webkitSpeechRecognition();
        recognition.lang = getUserData().language;

        recognition.onstart = function (event) {
          console.log('Speech has started');
          setStatus('recording');
          console.log(event);
        };

        recognition.onend = function (event) {
          console.log(status);
          setStatus('idle');
          console.log('Speech has ended');
          console.log(event);
        };

        recognition.onspeechend = function (event) {
          console.log('Speech has stopped being detected');
          recognition.stop();
          setStatus('idle');
          console.log(event);
        };

        recognition.onerror = function (event) {
          console.log('Speech has crashed');
          console.log(event);
          setStatus('error');
          if (event.error === 'not-allowed') {
            alert(
              i18n.t('You have blocked this website of using the Microphone.')
            );
          } else if (event.error === 'no-speech') {
            alert(
              i18n.t(
                "Couldn't detect speech, please check your microphone settings."
              )
            );
          } else {
            alert(i18n.t('Error: ') + event.error);
          }
        };

        recognition.onresult = function (event) {
          console.log(event);
          console.log(`Detected ${event.results[0][0].transcript}`);
          onResult(event.results[0][0].transcript);
        };

        setRecognition(recognition);
      }
    },
    // eslint-disable-next-line
    []);

  function onPlayStart() {
    if (status === 'recording') {
      recognition.stop();
      setStatus('idle');
      return;
    }

    recognition.start();

    // Check if the user allowed
    setStatus('recording');
  }

  function Button() {
    switch (status) {
      case 'idle':
        return (
          <FiMic
            className="idle"
            title={i18n.t('Record a new shortcut via voice')}
            onClick={onPlayStart}
          />
        );
      case 'recording':
        return (
          <FiMicOff
            className="recording"
            title={i18n.t('Record a new shortcut via voice')}
            onClick={onPlayStart}
          />
        );
      case 'error':
        return (
          <FiMicOff
            className="error"
            title={i18n.t('Error while recording shorcut, please try again')}
            onClick={onPlayStart}
          />
        );
      default:
    }
  }

  return (
    <div className={`speech ${status} view-hide`}>
      {canPlaySpeech ? (
        <Button />
      ) : (
        <FiMicOff
          title={i18n.t(
            'Your browser is not compatible with the Speech Recognition API, but you can manually enter the shortcuts below'
          )}
        />
      )}
    </div>
  );
}
