import React from 'react';
import i18n from "i18n/config"
import { dateToLocale } from "utils/date";
import { Link ,useRouteMatch} from "react-router-dom";
import parameterize from 'parameterize';

  
import './card.scss';
import Media from 'utils/Media';
import { FiStar } from 'react-icons/fi';
import Flag from './flags';

export default function Card({ board, showLanguage }) {
    let match = useRouteMatch().url.replace("/search/", "").replace("/search", "");
    if (match.includes("/profile")){
        match = "/store"
    }
    return (
        <Link className="board" to={`${match}/board/${parameterize(board.title)}/${board.ContentId}`}>
            <div className="img">
                <Media filename={board.coverImage} placeholder contentId={board.ContentId} />
                {showLanguage ?
                    <Flag publication={board} />
                    :
                    null
                }
            </div>
            <div className="info">
                <h3 className="title"> {board.title} </h3>
                <div className="description"> {board.description} </div>
                <div className="score">
                    {board.Content && board.Content.score > 0 ?
                        <span className="likes">
                            <FiStar /> {board.Content.score.toFixed(1)}
                        </span>
                        :
                        null
                    }
                </div>
                <div className="author hidden"> {i18n.t('by')} {board.authorName} </div>
                <div className="date hidden"> {dateToLocale(board.createdAt)} </div>
            </div>
        </Link>
    )

}