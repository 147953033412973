import React from 'react';
import { logOut } from 'utils/currentUser';

export default class CurrentUserLogout extends React.Component {

    componentDidMount(){
        logOut()
        window.location.href = "/"
    }

    render(){
        return null
    }
}