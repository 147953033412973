import React, { useState } from 'react';
import { get } from 'utils/fetch';
import { CLOUD_URL } from 'constants.js';
import i18n from 'i18n/config';
import Card from '../common/card';
import './filters.scss';

export default function Filters({
  query,
  setQuery,
  localization,
  setLocalization,
  history,
}) {
  const [results, setResults] = useState([]);

  function searchStore(input) {
    setQuery(input);
    if (input.length < 2) {
      setResults([]);
    } else {
      get(`${CLOUD_URL}/store/search?title=${encodeURIComponent(input)}`).then(
        (res) => setResults(res.results)
      );
    }
  }

  function changeLocalization(e) {
    setLocalization(e.target.value);
    history.replace({
      search: `?local=${e.target.value}`,
    });
  }

  const languages = {
    any: `🏴 ${i18n.t('All Languages')}`,
    'en-US': `🇺🇸 ${i18n.t('English')}`,
    'pt-BR': `🇧🇷 ${i18n.t('Portuguese')}`,
    'ar-SA': `🇸🇦 ${i18n.t('Arabic')}`,
    'de-DE': `🇩🇪 ${i18n.t('German')}`,
  };

  return (
    <>
      <div className="filter-area">
        <div className="search-area">
          <input
            className="search"
            type="search"
            placeholder={i18n.t('Search')}
            onChange={(e) => searchStore(e.target.value)}
          />
        </div>
        <div className="language-picker">
          <select
            name="language"
            defaultValue={localization}
            onChange={changeLocalization}
          >
            {Object.entries(languages).map(([code, lang]) => (
              <option value={code} key={code}>
                &nbsp;{lang}&nbsp;
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={`${query === '' ? 'hidden' : ''}`}>
        <div className="search-results">
          <h2>
            {' '}
            {i18n.t('Results for')} "{query}"{' '}
          </h2>
        </div>
        <div className="boards">
          {results.map((board) => (
            <Card board={board} key={board.id} />
          ))}
          {results.length === 0 && query.length > 2
            ? i18n.t('No results')
            : null}
        </div>
      </div>
    </>
  );
}
