import React from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import i18n from "i18n/config"

export default class Pagination extends React.Component {

    onNextClick() {
        this.props.updateQuery("offset", this.props.offset + this.props.limit);
    }

    onPrevClick() {
        this.props.updateQuery("offset", this.props.offset - this.props.limit);
    }

    render() {

        if (this.props.pagination === "0"){
            return null
        }

        return (
            <div className="pagination">
                <button onClick={this.onPrevClick.bind(this)} disabled={this.props.offset === 0}>
                    <FaAngleLeft />
                </button>
                <div className="pagination-label">
                    <span className="page">
                        {i18n.t('Page')} ({1 + (this.props.offset / this.props.limit)} {i18n.t('of')} {Math.ceil(this.props.data.count / this.props.limit)})
                    </span>
                    <span className="results">
                        {this.props.data.count} {i18n.t('results')}
                    </span>
                </div>
                <button onClick={this.onNextClick.bind(this)} disabled={this.props.offset + this.props.limit >= this.props.data.count}>
                    <FaAngleRight />
                </button>
            </div>
        );
    }
}
