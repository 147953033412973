import i18n from 'i18n/config';
import { createRef } from 'react';

export default function Modal({
  active,
  content,
  textModal,
  textConfirm,
  colorConfirm,
  onConfirm,
  onCancel,
  useRef = true,
  disableConfirmButton = false,
}) {
  const modalRef = createRef();

  if (useRef && !active) {
    return null;
  }
  if (useRef) {
    setTimeout(() => modalRef.current?.focus(), 100);
  }

  return (
    active && (
      <div
        className="modal modal-view"
        onKeyDown={(e) => {
          e.key === 'Escape' && onCancel && onCancel();
        }}
        tabIndex="-1"
        ref={modalRef}
      >
        <div className="modal-content">
          {content || <div className="text"> {i18n.t(textModal)} </div>}
          <div className="buttons">
            {onCancel ? (
              <button type="button" onClick={onCancel}>
                {i18n.t('Cancel')}
              </button>
            ) : null}
            {onConfirm ? (
              <button
                type="button"
                className="confirm"
                style={{ color: colorConfirm }}
                onClick={onConfirm}
                disabled={disableConfirmButton}
              >
                {i18n.t(textConfirm)}{' '}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    )
  );
}
