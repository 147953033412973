import { Formik, Form, ErrorMessage } from 'formik';
import i18n from 'i18n/config';
import { Component } from 'react';
import { Helmet } from 'react-helmet';
import { post } from '../fetch';
import createField from './fields';

function getFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
}

export default class CreateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
  }

  cancelForm() {
    if (!this.props.onFormCancel) {
      window.history.back();
    } else {
      this.props.onFormCancel();
    }
  }

  render() {
    const { props } = this;
    const initialValues = {};
    this.formFields = props.fields.map((attr) => {
      if (attr.class !== 'InputField') {
        return attr;
      }

      // Multiple options
      initialValues[attr.key] = attr.defaultValue || '';
      const field = createField(attr);
      return (
        <div
          key={attr.key}
          className={`field ${attr.type}`}
          style={{ width: `${attr.size * 100}%` }}
          id={`field-${attr.key}`}
        >
          <label>
            <span> {attr.name} </span>
            {field}
          </label>
          <ErrorMessage name={attr.key} component="span" className="error" />
        </div>
      );
    });

    const that = this;
    const recaptcha_key = '6Lc8_AInAAAAAJSvpGBkXZEdHP1GBg5MhiR0JpL0';
    return (
      <>
        {this.props.recaptchaType && (
          <Helmet>
            <script
              src={`https://www.google.com/recaptcha/enterprise.js?render=${recaptcha_key}`}
            />
          </Helmet>
        )}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            if (this.props.beforeSubmitting) {
              this.props.beforeSubmitting(values);
            }

            const formEnconding = 'application/x-www-form-urlencoded';
            const encoding = this.props.postEncoding || formEnconding;
            if (this.props.recaptchaType) {
              values.tokenRecaptcha = document.getElementById(
                'g-recaptcha-response'
              ).value;
            }
            let data = values;
            if (encoding === formEnconding) {
              data = new URLSearchParams(getFormData(values)).toString();
            }

            if (!this.props.endpoint) {
              setSubmitting(false);
              return false;
            }

            post(this.props.endpoint, data, encoding, this.props.method)
              .then((res) => {
                if (res.success === false) {
                  if (that.props.onFormFail) {
                    that.props.onFormFail(res.errors);
                  } else {
                    alert(res.message);
                  }
                } else {
                  that.props.onFormSuccess(res);
                }
              })
              .catch((error) => {
                if (error.non_field_errors) {
                  that.setState({ errors: [error.non_field_errors] });
                }
                if (that.props.onFormError) that.props.onFormError(error);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ isSubmitting, handleSubmit }) => {
            const customSubmitHandler = async (event) => {
              event.preventDefault();
              if (this.props.recaptchaType) {
                try {
                  event.preventDefault();
                  const token = await grecaptcha.enterprise.execute(
                    recaptcha_key,
                    { action: this.props.recaptchaType }
                  );
                  if (token) {
                    document.getElementById('g-recaptcha-response').value =
                      token;
                  } else {
                    console.log('Recaptcha without token');
                  }
                } catch (error) {
                  console.log('Error Recaptcha');
                }
              }
              handleSubmit();
            };

            return (
              <Form
                className="form form-creation"
                onSubmit={customSubmitHandler}
              >
                {this.props.title && (
                  <legend> {i18n.t(this.props.title)} </legend>
                )}
                {this.formFields}
                {this.props.recaptchaType && (
                  <input
                    type="hidden"
                    id="g-recaptcha-response"
                    name="g-recaptcha-response"
                  />
                )}
                {this.state.errors.length > 0 ? (
                  <div className="form-errors">
                    {this.state.errors.map((error) => i18n.t(error)).join('\n')}
                  </div>
                ) : null}

                <div className="form-buttons">
                  {!this.props.cancelDisabled && (
                    <button
                      type="button"
                      className="cancel"
                      disabled={isSubmitting}
                      onClick={this.cancelForm.bind(this)}
                    >
                      {i18n.t('Cancel')}
                    </button>
                  )}
                  <button type="submit" disabled={isSubmitting}>
                    {i18n.t(this.props.actionName || 'Create')}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}
