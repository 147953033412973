import React from 'react';
// import i18n from "i18n/config"
import { InputField, renderForm } from "utils/form";
import { getUserData } from 'utils/currentUser';

/*
const languages = {
    "en-US": "🇺🇸 " + i18n.t("English"),
    "pt-BR": "🇧🇷 " + i18n.t("Portuguese"),
    "ar-SA": "🇸🇦 " + i18n.t("Arabic")
}
*/
export default class CreateHolderForm extends React.Component {

    
  constructor(props) {
    super(props);

    this.endpoint = `/holders/register/`;
    this.actionName = "Confirm"
    this.cancelDisabled = true
    const state = {};
    state.fields = [
      new InputField("Name", "name", true, { type: "text", size: 1, defaultValue: getUserData().full_name }),
      new InputField("Country", "country", true, { type: "text", size: 1, defaultValue: getUserData().country }),
      new InputField("Language", "language", true, { type: "text", size: 1, defaultValue: getUserData().language }),
      new InputField("Phone Number", "phone_number", true, { type: "text", size: 1, defaultValue: getUserData().full_name }),
    ];

    this.state = state;
  }

  onFormSuccess() {
    this.props.onSucess();
  }

  render() {
    return (renderForm(this));
  }

}
