import i18n from 'i18n/config';
import CreateForm from './formik/CreateForm';
import { get } from './fetch';

export class InputField {
  constructor(name, key, required, params) {
    this.name = i18n.t(name);
    this.en_name = name;
    this.key = key;
    this.required = required;
    this.type = 'text';
    this.class = 'InputField';
    if (params) {
      this.type = params.type;
      if (params.options) {
        if (Array.isArray(params.options)) {
          params.options.forEach(
            (option) => (option.label = i18n.t(option.label))
          );
        } else {
          params.options = Object.entries(params.options).map(
            ([value, label]) => ({ value, label })
          );
        }
      }
      this.options = params.options;
      this.pattern = params.pattern;
      this.size = params.size;
      this.min = params.min;
      this.max = params.max;
      this.defaultValue = params.defaultValue;
      this.disabled = !!params.disabled;
      this.onChange = params.onChange;
    }
  }
}
export function LabelField(name) {
  return new InputField(
    name,
    name
      .trim()
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 -]/, '')
      .replace(/\s/g, '-'),
    null,
    { size: 1, type: 'label' }
  );
}
export const getFormParams = (that, endpoint) =>
  get(endpoint || that.endpoint)
    .then((params) => {
      const newField = that.state.fields;
      if (params.success === false) {
        alert(params.message);
        window.location = `${window.location.origin}/`;
      }
      const { options } = params;
      if (options) {
        // Apply options
        newField
          .filter((field) => options[field.key])
          .forEach((field) => {
            const formOptions = Object.entries(options[field.key]).map(
              ([key, value]) => ({ value: key, label: value })
            );
            field.options = formOptions;
          });
      }
      const defaultValues = params.default_values;
      if (defaultValues) {
        // Apply options
        newField
          .filter((field) => defaultValues[field.key])
          .forEach((field) => {
            field.defaultValue = defaultValues[field.key];
          });
      }
      // Set the new fields
      that.setState({
        fields: newField,
      });
      return params;
    })
    .catch((error) => {
      console.error(error);
      if (error.message) alert(error.message);
      if (that.backIfError) window.history.back();
    });
export const renderForm = (that) => (
  <CreateForm
    fields={that.state.fields}
    title={that.title}
    endpoint={that.endpoint}
    onFormSuccess={that.onFormSuccess && that.onFormSuccess.bind(that)}
    onFormError={that.onFormError && that.onFormError.bind(that)}
    onFormFail={that.onFormFail && that.onFormFail.bind(that)}
    onFormCancel={that.onFormCancel && that.onFormCancel.bind(that)}
    actionName={that.actionName}
    beforeSubmitting={that.beforeSubmitting && that.beforeSubmitting.bind(that)}
    postEncoding={that.postEncoding}
    cancelDisabled={that.cancelDisabled}
    method={that.method}
    recaptchaType={that.recaptchaType}
  />
);
