import React, { useState } from 'react';
import { CLOUD_URL } from 'constants.js';
import i18n from "i18n/config"
import { FaUpload } from 'react-icons/fa';
import { post } from 'utils/fetch';
import { FeaturedContent } from '../home/Featured';
import "./FormPromotion.scss"
import { toast } from 'react-toastify';

export default function FormPromotion({ contentId, onCancel }) {

    const [dataURL, setDataURL] = useState(null);
    const [isSubmitting, setSubmitting] = useState(false);

    const [form, setForm] = useState({
        imageFile: null,
        title: "",
        subtitle: "",
        posX: 50,
        posY: 50,
    })

    const changeForm = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const formSubmit = () => {

        if (form.imageFile && form.title !== "" && form.subtitle !== ""){
            const url = `${CLOUD_URL}/promoted/${contentId}/promote`;
            const formData = new FormData();
            for ( const key in form ) {
                formData.append(key, form[key]);
            }
            setSubmitting(true)
            post(url, formData, 'multipart/form-data').then(res => {
                setSubmitting(false)
                toast.success("Banner created successfully");
                onCancel();
            });
        } else {
            alert("Missing Information")
        }

    }

    const fileLoad = (file) => {

        if (file) {
            setForm({ ...form, imageFile: file });
            const reader = new FileReader();

            reader.onload = function (e) {
                console.log(e.target.result)
                setDataURL(e.target.result)
            }

            reader.readAsDataURL(file); // convert to base64 string
        }
    }

    if(isSubmitting){
        return(
            <>
                <div className="loader" />
                <h2> Uploading Banner...</h2>
            </>
        )
    }

    return (
        <div className="banner-form form">
            <h2>Promote content</h2>
            <form id="imageUploadForm" onChange={(e) => fileLoad(e.target.files[0])}>
                <label htmlFor="uploadImage">
                    <FaUpload /> Upload banner to start
                </label>
                <input type="file" id="uploadImage" accept="image/*" />
            </form>
            {form.imageFile ?
                <>
                    <form className="">
                        <div className="field" id="field-title">
                            <label>
                                <span> Title </span>
                                <input value={form.title} maxLength={26} name="title" required onChange={changeForm} />
                            </label>
                        </div>
                        <div className="field" id="field-subtitle">
                            <label>
                                <span> Subtitle </span>
                                <input value={form.subtitle} maxLength={100} name="subtitle" required onChange={changeForm} />
                            </label>
                        </div>
                    </form>
                    <hr />
                    <div className="preview featured-area">
                        <FeaturedContent content={{
                            image: dataURL,
                            title: form.title || "Sample title",
                            subtitle: form.subtitle || "Sample subtitle",
                            posX: form.posX,
                            posY: form.posY
                        }} />
                    </div>
                </>
                :
                null
            }

            <div className="buttons">
                <button onClick={onCancel} style={{color: "red"}}> {i18n.t("Cancel")} </button>
                <button className="confirm" onClick={formSubmit} disabled={!(form.imageFile && form.title && form.subtitle)}> {i18n.t('Promote')} </button>
            </div>

        </div>
    )

}
