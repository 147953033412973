import React from 'react';
import { FiArrowDown, FiArrowUp, FiExternalLink, FiInfo } from 'react-icons/fi';
import { renderSearchTable } from 'utils/dataview';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/config';

export function DashboardSection({
  view,
  setView,
  total,
  model,
  dashboardData,
  baseUrl,
  charts,
  externalUrl,
  apiEndpoint,
  tableAttributes,
}) {
  // Mobile redirect
  if (view && window.innerWidth < 1024) {
    const fullViewUrl = `${externalUrl + baseUrl + view.params}&message=${
      view.title
    } Licenses`;

    return <Redirect push to={fullViewUrl} />;
  }

  return (
    <div className="dashboard-section">
      <h2> {model} </h2>
      <p>
        {' '}
        {total} {model}{' '}
      </p>
      <div className="dashboard-container">
        <div>
          <div className="dashboard-cards">
            {dashboardData.map((data) => (
              <DashboardCard
                key={data.title + model}
                card={data}
                {...{ view, setView }}
              />
            ))}
          </div>
          <div className="charts-container">{charts}</div>
        </div>

        <div className="dashboard-table">
          {view ? (
            <div className="data">
              <div className="table">
                <MiniTable
                  params={baseUrl + view.params}
                  key={view.title}
                  apiEndpoint={apiEndpoint}
                  tableAttributes={tableAttributes}
                />
                <Link
                  className="see-details"
                  target="_blank"
                  title="Open on a new tab"
                  to={`${externalUrl + baseUrl + view.params}&message=${
                    view.title
                  } Licenses`}
                >
                  <FiExternalLink />
                </Link>
              </div>
            </div>
          ) : (
            <div className="placeholder" />
          )}
        </div>
      </div>
    </div>
  );
}

export function DashboardCard({ card, view, setView }) {
  const { t } = useTranslation();

  return (
    <div className="dashboard-card">
      <div className="card-head">
        <h2> {card.title} </h2>
        {card.hint ? <FiInfo title={card.hint} /> : null}
      </div>
      <div className="card-section">
        {card.sections.map((section, i) => (
          <>
            <div
              key={section.title}
              className={`dash-card ${
                view && view.title === section.title ? 'active' : ''
              }`}
              onClick={() => setView && setView(section)}
            >
              <div className="main">
                <h3> {i18n.t(section.title)} </h3>
                {section.ammount !== undefined &&
                section.ammount !== 'undefined%' ? (
                  <p className={section.ammountClass}> {section.ammount} </p>
                ) : (
                  <p> {t('Loading...')} </p>
                )}
              </div>

              {section.rate !== undefined &&
              Math.round(Math.abs(section.rate)) !== 0 ? (
                <span className="rate">
                  <span className={section.rateClass}>
                    {section.rate > 0 ? <FiArrowUp /> : <FiArrowDown />}
                    {Math.round(Math.abs(section.rate))}%
                  </span>
                </span>
              ) : null}

              <div className="dashboard-aside">{section.aside}</div>
            </div>
            {i !== card.sections.length - 1 ? <div className="vl" /> : null}
          </>
        ))}
      </div>
    </div>
  );
}
export function getRateClass(rate) {
  if (rate > 0) return 'positive';
  if (rate < 0) return 'negative';
  return 'neutral';
}

class MiniTable extends React.Component {
  constructor(props) {
    super(props);
    this.endpoint = props.apiEndpoint;
    this.params = props.params;
    this.attributes = props.tableAttributes;
  }

  render() {
    return renderSearchTable(this);
  }
}
