import React from "react";
import { setUserLanguage } from "utils/currentUser";

import en from "media/flags/en.png";
import ar from "media/flags/ar-SA.png";
import pt from "media/flags/pt-BR.png";
import de from "media/flags/de-DE.png";

export function LanguagePicker() {
  const languages = {
    en: en,
    "ar-SA": ar,
    "pt-BR": pt,
    "de-DE": de,
  };

  return (
    <div className="flags">
      {Object.entries(languages).map(([code, img]) => (
        <img
          key={code}
          onClick={() => setUserLanguage(code)}
          src={img}
          alt={code}
        />
      ))}
    </div>
  );
}
