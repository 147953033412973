import { useSelector } from "react-redux";
import Arrays from "utils/extensions";
import { ActionTypes } from "../action-types";
import { Action } from "../actions/studio";
import { Card, State, StudioState } from "../types";
import { State as StoreState } from ".";

const initialState: StudioState = {
  content: null,
  focused: null,
  cardQueue: [],
  cardList: [],
};

function newState(cardId: string, index: number): State {
  return {
    index,
    card_id: cardId,
    image_id: null,
    media_id: null,
    written_text: "",
    spoken_text: "",
    big_image: false,
    just_text: false,
    is_visible: false,
    symbol: null,
    background_color: "",
  };
}

const studioReducer = (
  state: StudioState = initialState,
  action: Action
): StudioState => {
  switch (action.type) {
    case ActionTypes.ADD_CONTENT:
      return {
        ...state,
        content: {
          ...action.payload,
          data: action.payload.data.map((e) => ({
            ...e,
            rows: e.rows || 3,
            columns: e.columns || 3,
          })),
        },
      };
    case ActionTypes.ADD_STATE: {
      if (!state.focused || !state.content) return state;
      const boards = state.content.data?.map((e) => {
        if (e.old_id === state.focused) {
          return {
            ...e,
            states: [...e.states, newState(e?.old_id, e?.states.length)],
          };
        }
        return e;
      });
      return {
        ...state,
        content: {
          ...state.content,
          data: boards,
        },
      };
    }
    case ActionTypes.UPDATE_STATE: {
      if (!state.focused || !state.content) return state;
      const boards = state.content.data?.map((e) => {
        if (e.old_id === state.focused) {
          return {
            ...e,
            states: e.states.map((s) => {
              if (s.index === action.payload.index) return action.payload;
              return s;
            }),
          };
        }
        return e;
      });
      return {
        ...state,
        content: {
          ...state.content,
          data: boards,
        },
      };
    }
    case ActionTypes.UPDATE_CARD: {
      if (!state.content) return state;
      const boards = state.content.data?.map((e) => {
        if (e.old_id === action.payload.old_id) {
          return action.payload;
        }
        return e;
      });
      return { ...state, content: { ...state.content, data: boards } };
    }
    case ActionTypes.SET_FOCUSED:
      return { ...state, focused: action.payload?.old_id ?? null };
    case ActionTypes.EXPAND_CARD: {
      let filtered: string[] = [];
      let { cardQueue } = state;
      if (state.cardQueue.some((e) => e === action.payload?.old_id)) {
        cardQueue = Arrays.takeWhile(
          cardQueue,
          (e) => e !== action.payload?.old_id
        );
      }
      filtered =
        state.content?.data
          .filter((e) => e.board_id === (action.payload?.old_id ?? null))
          .map((e) => e.old_id) ?? [];
      return {
        ...state,
        cardList: filtered,
        cardQueue: action.payload
          ? cardQueue.concat(action.payload.old_id)
          : [],
      };
    }
    default:
      return state;
  }
};

const useParentCard = (): [Card | null, String | null] => {
  const parentId = useSelector<StoreState, string | null>((state) =>
    Arrays.last(state.studio.cardQueue)
  );
  const parent = useSelector<StoreState, Card | null>((state) =>
    parentId
      ? state.studio.content?.data.find((e) => e.old_id === parentId) ?? null
      : null
  );

  return [parent, parentId];
};

const useFocusedCard = () => {};

export { useParentCard };

export default studioReducer;
