import { Fragment, useEffect } from 'react';
import { FaCaretRight, FaFolder } from 'react-icons/fa';
import i18n from 'i18n/config';
import { FiCornerUpRight, FiZap } from 'react-icons/fi';
import { handleDrop, handleDragOver } from '../utils/drag';
import Media from '../../../../utils/Media';

export default function Path({ functions }) {
  let current = functions.currentItem;
  let path = [current];

  if (current.isMock) {
    path = [];
  }
  while (current?.parent !== null) {
    current = current?.parent;
    path.unshift(current);
  }

  const setEditFolder = () => {
    functions.setEdit(functions.currentItem);
    functions.setSidebar('shortcuts');
  };

  const openRecommendations = () => {
    functions.setEdit(functions.currentItem);
    functions.setSidebar('ai-recommendations');
  };

  useEffect(() => {
    if (functions.sidebarMode === 'shortcuts') {
      setEditFolder();
    }
    if (functions.sidebarMode === 'ai-recommendations') {
      openRecommendations();
    }
  }, [functions.currentItem]);

  const { shortcuts } = functions.currentItem;
  const hasShortcuts = shortcuts && shortcuts.length > 0;

  return (
    <div className="folder-toolbar view-hide">
      <div className="path">
        <span
          key="root"
          className="point root-point"
          onClick={functions.gotoRoot}
        >
          <FaFolder />
        </span>

        {path.map((node) => (
          <Fragment key={node.id}>
            <FaCaretRight />
            <span
              className="point"
              onClick={() => functions.setItem(node)}
              onDragOver={(e) => handleDragOver(e, node)}
              onDragLeave={(e) => e.target.classList.remove('over')}
              onDrop={(e) => handleDrop(e, node, functions)}
              style={{ backgroundColor: node.states[0].backgroundColor }}
            >
              <Media
                filename={node.states[0].image1}
                placeholder
                contentId={functions.contentId}
              />
              {node.states[0].writtenText || node.states[0].spokenText}
            </span>
          </Fragment>
        ))}
      </div>
      {!functions.currentItem.isMock ? (
        <div className="folder-actions">
          <div
            className={`shortcut ${
              functions.sidebarMode === 'shortcuts' ? 'active' : ''
            } 
          ${hasShortcuts ? 'has-items' : ''}`}
          >
            <FiCornerUpRight
              title={i18n.t('Add shortcuts to this folder')}
              onClick={setEditFolder}
            />
          </div>
          <div
            className={`ai-recommendations ${
              functions.sidebarMode === 'ai-recommendations' ? 'active' : ''
            }`}
            onClick={openRecommendations}
          >
            <FiZap />
          </div>
        </div>
      ) : null}
    </div>
  );
}
