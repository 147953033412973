import {
  emojiMultiplier,
  isEmoji,
} from 'components/studio/creator/utils/emoji';
import { HTMLAttributes } from 'react';

const regex = /BG_(0[0-9]|1[0-9]|2[0-9]|3[0-9])/;
export function getMediaURL(media: string) {
  let imageURL = '';
  if (media) {
    if (media.startsWith('livox_')) {
      imageURL = `https://storage.googleapis.com/uploaded-items/media/${media}`;
    } else if (regex.test(media)) {
      const imageName = media.replace(/^BG_0?(\d+)$/, 'bg_$1.webp');
      imageURL = `https://storage.googleapis.com/uploaded-items/media/${imageName}`;
    } else {
      imageURL = `https://storage.googleapis.com/livox-images/full/${media.replace(
        '.png',
        ''
      )}.png`;
    }
  }
  return imageURL;
}

interface Props extends HTMLAttributes<HTMLImageElement> {
  filename?: string;
  placeholder?: string;
}

export default function Media(props: Props) {
  const { filename, placeholder } = props;
  const media = filename || 'communicate';

  if (!filename) {
    if (!placeholder) {
      return null;
    }
  }

  const multiplier = emojiMultiplier(media);

  return !isEmoji(media) ? (
    <img
      {...props}
      className={`${props.className} image Media play-media ${
        !media ? 'transparent' : ''
      }`}
      src={getMediaURL(media)}
      alt={media}
    />
  ) : (
    <svg
      viewBox={`0 0 ${64 * multiplier} ${24 * multiplier}`}
      style={{ width: '100%' }}
    >
      <text
        x="50%"
        y={`${15 * multiplier}px`}
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {media}{' '}
      </text>
    </svg>
  );
}
