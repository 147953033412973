import React from 'react';
import { InputField, renderForm } from "../../utils/form";

export default class PasswordResetForm extends React.Component {

    constructor(props){  
      super(props);
        
      this.title = "Reset Password"
      this.endpoint = `/accounts/password/reset/`;
      const state = {};
      state.fields = [
        new InputField("E-mail", "email", true, {size: 1, type: "email"}),
      ];

      this.actionName = "Reset Password";
      this.state = state;
    }

    onFormSuccess(){
      // 
    }

    onFormError(res){
      alert("Reset email sent!");
      window.history.back();
    }
  
    render (){ 
      return(renderForm(this));
    }
  
}
  

