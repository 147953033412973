import React from 'react';
import { NavLink } from "react-router-dom";
import { ROUTES } from 'routes';

import logo from 'media/navbar-logo.png';
import { FaAngleDown, FaHome, FaEdit, FaStore, FaCog, FaPlay } from 'react-icons/fa';
import "./navbar.scss";
import { useTranslation } from 'react-i18next';
import { AppContext } from '../Reducer';
import UserAvatar from './Avatar';
import SpeechRecognizer from './SpeechRecognizer';

export default function Navbar() {

    const { t } = useTranslation();
    const { holder, dispatch } = React.useContext(AppContext);

    return (
        <header className="nav-bar" id="navbar">
            <div className="container">

                <div className="logo">
                    <NavLink exact to={ROUTES.LIVOX_WEB.path}>
                        <img alt="livox logo" src={logo} />
                    </NavLink>

                </div>

                <div className="controls">
                    <ul>
                        <li>
                            <NavLink exact to={ROUTES.LIVOX_WEB.path}>
                                <FaHome /> <span> {t('Home')} </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={ROUTES.LIVOX_WEB_EDIT.path}>
                                <FaEdit /> <span> {t('Edit')}  </span>
                            </NavLink>
                        </li>
                        <li className="start" aria-label={t('Start')}>
                            <NavLink to={ROUTES.LIVOX_WEB_PLAY.path}>
                                <FaPlay />
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to={ROUTES.LIVOX_WEB_STORE.path}>
                                <FaStore /> <span> {t('Store')} </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={ROUTES.LIVOX_WEB_SETTINGS.path}>
                                <FaCog /> <span> {t('Settings')} </span>
                            </NavLink>
                        </li>
                    </ul>
                </div>

                <SpeechRecognizer/>
                <div className="user-switcher">
                    <div className="current">
                        <UserCard user={holder.selectedUser} />
                        <FaAngleDown />
                    </div>

                    <ul className="submenu">
                        <div className="current-more-info">
                            <UserCard user={holder.selectedUser} />
                        </div>
                        <div className="hover-content">
                            <div className="others">
                                {
                                    Object.entries(holder.users).filter(([id]) => id !== holder.selectedUserId).map(([id, user]) =>
                                        <UserCard key={id} user={user} changeUser={() => dispatch({ type: 'CHANGE_USER', payload: { id } })} />
                                    )
                                }
                                <NavLink to={ROUTES.LIVOX_SETTINGS_USERS.path}>
                                    <span> {t('Manage Users')} </span>
                                </NavLink>
                            </div>

                            <div className="options">
                                <NavLink to={ROUTES.LIVOX_WEB_SETTINGS.path}>
                                    <span> {t('Admin Portal')} </span>
                                </NavLink>
                                <NavLink to={ROUTES.LIVOX_WEB_SETTINGS.path}>
                                    <span> {t('Log out')} </span>
                                </NavLink>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        </header>

    )
}

function UserCard({ user, changeUser }) {
    return (
        <div className="user-card" onClick={() => changeUser && changeUser(user.id)}>
            <UserAvatar user={user} size={changeUser ? 25 : 32} />
            <span className="user-name"> {user.name} </span>
        </div>

    )
}