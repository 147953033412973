import { getUserData } from "./currentUser";

export function dateToday() {
  return inputDateFormat(new Date());
}
export function inputDateFormat(date) {
  let dd = date.getDate();
  let mm = date.getMonth() + 1; // January is 0!
  const yyyy = date.getFullYear();
  if (dd < 10) {
    dd = `0${  dd}`;
  }
  if (mm < 10) {
    mm = `0${  mm}`;
  }
  return `${yyyy  }-${  mm  }-${  dd}`;
}
export const dateToLocale = (date) => {
  if (date) {
    return new Date(date).toLocaleString(getUserData().language);
  }
  return "—";
};
export const toISOString = (date) => (new Date(date).toISOString().split("T")[0]);
