import { useState, useEffect, useMemo } from 'react';
import './studio.scss';
import './view.scss';
import { get } from 'utils/fetch';
import i18n from 'i18n/config';
import { polyfill } from 'mobile-drag-drop';
import { scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop/scroll-behaviour';
import Media, { getMediaURL } from 'utils/Media';
import { getUserData } from 'utils/currentUser';
import Sidebar from './creator/sidebar/Sidebar';
import { CLOUD_URL } from '../../constants';
import Modals from './creator/modal/Modal';
import Player from './creator/utils/player';
import { initializeSettings, onSettingsChange } from './initalizers/settings';
import ItemMenu from './creator/utils/ItemMenu';
import Header from './creator/header/Header';
import Main from './creator/main/Main';
import { undo } from './creator/utils/history';
import StudioClipboard from './creator/utils/clipboard';
import Intellibar from './creator/intellibar/Intellibar';
import CategoryType from './categoryType';

// Polyfill for dragndrop on mobile
polyfill({
  // use this to make use of the scroll behaviour
  dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
  holdToDrag: 300,
});
window.addEventListener('touchmove', () => {});

const addParentReference = (obj, parent) => {
  if (typeof obj === 'string') {
    obj = JSON.parse(obj);
  }

  obj?.children?.forEach((item) => {
    addParentReference(item, obj);
  });
  obj.parent = parent;
};

const saved = JSON.parse(window.localStorage.getItem('studio-settings'));

export default function Studio({ data, match, meta, overrides = {}, isStore }) {
  const { uuid } = match.params;
  const defaultMode = match.params.mode || 'edit';
  const contentId = match.params.contentId || uuid;

  // Current items on studio
  const [tree, setTree] = useState(null);
  // Item being visualized
  const [currentItem, setItem] = useState(tree);
  // Category type
  const [categoryType, setCategoryType] = useState(CategoryType.UNDEFINED);
  // Item being edited
  const [editingNode, setEdit] = useState(null);
  // Sidebar content
  const [sidebarMode, setSidebar] = useState(null);
  // Undo history
  const [history, sethistory] = useState([]);
  // Manual update trigger
  const [, forceUpdate] = useState();
  // Metadata on studio
  const [metadata, setMetadata] = useState(meta);
  // Edit or view mode
  const [mode, setMode] = useState(defaultMode);
  // Sets publishing modals
  const [activeModal, setModal] = useState();
  // Set media playing content
  const [contentPlaying, setContentPlaying] = useState(null);
  // Unpress saving time
  const [saveTimeout, setSaveTimeout] = useState(null);
  // Cloud sync status
  const [savingStatus, setSaving] = useState(null);
  // Cloud sync errors
  const [errorMessage, setError] = useState(null);
  // Item offset on view pagination
  const [pageOffset, setOffset] = useState(0);
  // Studio or Livox settings
  const [settings, setSettings] = useState(
    overrides.settings || initializeSettings(saved)
  );
  // Item that is being right clicked
  const [ctxItem, setCtxItem] = useState({});
  // Controls intellitouch
  const [lastClick, setLastClick] = useState(0);
  // Controls intellitouch
  // const [isClickAllowed, setClickAllow] = useState(true);
  // Intellibar
  const [intellibar, setIntellibar] = useState([]);

  const [selectedState, setSelectedState] = useState({});

  const [multiSelectCards, setMultiSelectCards] = useState([]);

  const [isActiveMultiSelectCards, setIsActiveMultiSelectCards] =
    useState(false);

  const [isActiveCutMultiSelectCards, setIsActiveCutMultiSelectCards] =
    useState(false);

  const [isActivePasteMultiSelectCards, setIsActivePasteMultiSelectCards] =
    useState(false);

  const currentUser = useMemo(() => getUserData(), []);

  const updateSetting = (key, value) => {
    setSettings({ ...settings, [key]: value });
  };

  function searchNode(key, tree) {
    // make a queue array
    const queue = [];
    queue.push(...tree.children);
    // search the queue until it is empty
    while (queue.length > 0) {
      // assign the top of the queue to variable currentNode
      const currentNode = queue[0];

      // if currentNode is the node we're searching for, break & alert
      if (currentNode.id === key) {
        return currentNode;
      }
      queue.push(...currentNode.children);
      queue.shift();
    }
    console.log('Not found!');
    return null;
  }

  useEffect(
    () => {
      if (overrides.onSettingsChange) {
        overrides.onSettingsChange(settings);
      } else {
        onSettingsChange(settings);
        window.localStorage.setItem(
          'studio-settings',
          JSON.stringify(settings)
        );
      }
    },
    // eslint-disable-next-line
    [settings]
  );

  const gotoRoot = () => {
    const mocktree = {
      parent: null,
      isMock: true,
      children: [functions.tree],
    };
    functions.setItem(mocktree);
  };

  const functions = {
    setItem,
    setEdit,
    editingNode,
    tree,
    setTree,
    currentItem,
    history,
    sethistory,
    uuid,
    forceUpdate,
    mode,
    setMode,
    activeModal,
    setModal,
    contentId,
    setContentPlaying,
    contentPlaying,
    gotoRoot,
    saveTimeout,
    setSaveTimeout,
    setSaving,
    savingStatus,
    sidebarMode,
    setSidebar,
    addParentReference,
    setOffset,
    pageOffset,
    settings,
    updateSetting,
    metadata,
    setCtxItem,
    ctxItem,
    overrides,
    getAllowedClick,
    speak,
    intellibar,
    setIntellibar,
    selectedState,
    setSelectedState,
    multiSelectCards,
    setMultiSelectCards,
    isActiveMultiSelectCards,
    setIsActiveMultiSelectCards,
    isActiveCutMultiSelectCards,
    setIsActiveCutMultiSelectCards,
    isActivePasteMultiSelectCards,
    setIsActivePasteMultiSelectCards,
    categoryType,
  };

  // Intelitouch controls
  function getAllowedClick() {
    let freezeTime;
    const now = new Date().getTime();
    const lastClickTime = lastClick;
    if (settings.intellitouchEnabled) {
      freezeTime = 700;
      setLastClick(now);
    } else {
      freezeTime = (settings.touchFreezeTime || 0) * 1000;
    }

    if (now > lastClickTime + freezeTime) {
      setLastClick(now);
      return true;
    }

    return false;
  }

  function speak(text) {
    // remore <tags>
    const textToSpeak = text?.replace(/ *<[^>]*> */g, '');
    const synth = window.speechSynthesis;

    if (synth.speaking) {
      synth.cancel();
    }

    const utter = new SpeechSynthesisUtterance(textToSpeak);
    if (metadata || settings.voiceURI === '') {
      const language =
        (metadata && metadata.language) ||
        (currentUser && currentUser.language);
      const onlineVoice = synth
        .getVoices()
        .find((v) => v.lang === language && !v.localService);
      const offlineVoice = synth
        .getVoices()
        .find((v) => v.lang === language && v.localService);
      const fallback = synth
        .getVoices()
        .find((voice) => voice.lang === 'en-US');

      utter.voice = onlineVoice || offlineVoice || fallback;
    } else {
      // TODO: Check if system has this voice
      utter.voice = synth
        .getVoices()
        .find((voice) => voice.voiceURI === settings.voiceURI);
      utter.rate = settings.voiceSpeed / 100;
      utter.pitch = settings.pitchValue / 100;
    }
    synth.speak(utter);

    return utter;
  }

  // Keyboard listeners
  useEffect(() => {
    function captureKeyEvents(e) {
      const modifierKeyPressed = e.ctrlKey || e.metaKey;

      // Paste clipboard node
      if (modifierKeyPressed && e.key === 'v') {
        StudioClipboard.paste(functions.currentItem, functions);
      }

      // Undo
      if (modifierKeyPressed && e.key === 'z') {
        undo(functions);
      }
    }

    window.addEventListener('keydown', captureKeyEvents);

    return () => {
      window.removeEventListener('keydown', captureKeyEvents);
    };
  }, [functions]);

  useEffect(() => {
    localStorage.removeItem('clipboard');
    const fetchData = async () => {
      // Tries to see if the data is being passed down (store)
      let tree = data;
      // Fetch data if on editor;
      if (!tree) {
        let url;
        if (mode === 'review') {
          url = `${CLOUD_URL}/publications/${uuid}`;
        } else {
          url = `${CLOUD_URL}/content/${uuid}`;
        }

        const result = await get(url);

        if (result.success === false) {
          return setError(
            "Content not found, it might've deleted or the URL is invalid!"
          );
        }

        document.title = `${result.data.title} - Livox Studio`;

        tree = result.data.data;
        setMetadata(result.data);
        if (mode === 'edit') {
          // setSidebar("changelog");
        }
      }

      addParentReference(tree, null);
      setTree(tree);
      setOffset(0);
      if (data) {
        setSidebar(null);
      } else {
        // setSidebar("changelog");
      }
      setEdit(null);

      if (tree.image1 && tree.image1 !== '') {
        document.querySelector("link[rel*='icon']").href = getMediaURL(
          tree.image1,
          contentId
        );
      }

      const hash = window.location.hash.replace('#', '');
      // Do a BFS to find the content
      if (hash !== '') {
        const target = searchNode(hash, tree);
        if (target) {
          setItem(target);
        } else {
          setItem(tree);
        }
      } else {
        setItem(tree);
      }

      // Display shortcuts on store
      /*
            if (mode === "store" && tree.shortcuts && tree.shortcuts.length > 0) {
                setEdit(tree)
                setSidebar("shortcuts");
            }
            */
    };
    fetchData();
    return () => {
      document.querySelector("link[rel*='icon']").href = '/favicon.ico';
    };
    // eslint-disable-next-line
  }, [uuid, data]);

  useEffect(() => {
    setSidebar(null);
    if (!isActiveCutMultiSelectCards && !isActivePasteMultiSelectCards) {
      setIsActiveMultiSelectCards(false);
      setMultiSelectCards([]);
    }
  }, [functions.currentItem]);

  // Hash changes on content view
  useEffect(() => {
    if (functions.currentItem && !isStore) {
      const hash = functions.currentItem.id || '';
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}#${hash}`
      );
    }
  }, [functions.currentItem, isStore]);

  useEffect(() => {
    setCategoryType(
      metadata?.data === undefined
        ? CategoryType.UNDEFINED
        : typeof metadata?.data === 'string'
        ? CategoryType.GAME
        : CategoryType.BOARD
    );
  }, [functions.metadata]);

  if (errorMessage) {
    return <h1>{i18n.t(errorMessage)}</h1>;
  }

  return (
    <div className={`livox-studio ${mode}`}>
      {tree && currentItem ? (
        <>
          <Header functions={functions} />
          {!isStore && functions.currentItem.pageMode !== 'book' && (
            <Intellibar functions={functions} />
          )}
          {functions.categoryType === CategoryType.BOARD && (
            <div className="divider">
              <Main functions={functions} isStore={isStore} />
              <Sidebar functions={functions} />
            </div>
          )}

          {functions.categoryType === CategoryType.GAME && (
            <div
              className="divider"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              }}
            >
              <Media
                filename={metadata.coverImage}
                placeholder={false}
                contentId={metadata.ContentId}
                style={{ width: 540, height: 450 }}
              />
            </div>
          )}

          <Modals functions={functions} />
          <ItemMenu functions={functions} />
          <Player
            filename={contentPlaying}
            contentId={functions.contentId}
            autoplay
            setContentPlaying={functions.setContentPlaying}
          />
        </>
      ) : (
        <h2> {i18n.t('Loading...')} </h2>
      )}
    </div>
  );
}
