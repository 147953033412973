import React from 'react';
import { InputField, renderForm, getFormParams } from 'utils/form';
import { fetchUserData } from 'utils/currentUser';
import FormUpdatePicture from 'components/portal/view/views/auth_users/FormUpdatePicture';
import '../../portal/view/views/auth_users/Profile.scss';

export default class FormUpdateStoreProfile extends React.Component {
  constructor() {
    super();

    this.title = 'Edit Profile';
    this.endpoint = '/authusers/update-profile/';
    this.actionName = 'Save';
    const state = {};
    state.fields = [
      new InputField('E-mail', 'email', false, { disabled: true }),
      new InputField('Language', 'language', false, {
        type: 'select',
        options: [],
      }),
      new InputField('Name', 'full_name', false, { size: 1 }),
      new InputField('About you', 'biography', false, {
        type: 'textarea',
        max: 400,
        size: 1,
      }),
    ];

    this.state = state;
  }

  componentWillMount() {
    getFormParams(this);
  }

  onFormSuccess() {
    window.history.back();
    fetchUserData();
  }

  render() {
    return (
      <>
        <FormUpdatePicture />
        {renderForm(this)}
      </>
    );
  }
}
