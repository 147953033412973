import React from 'react';
import { Field } from 'formik';
import Select from 'react-select';


function CustomSelect({ field, form, options, ...rest }) {
  const handleChange = (selectedOption) => {
    form.setFieldValue(
      field.name,
      selectedOption ? selectedOption.value : null
    );
  };

  const selectedOption = options.find((option) => option.value === field.value);

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={handleChange}
      {...rest}
    />
  );
}

export default function renderField(attr) {
  let options;
  switch (attr.type) {
    case 'select':
      options = attr.options.map((option) => (
        <option key={option.value} value={option.value} label={option.label}>
          {option.label}
        </option>
      ));

      return (
        <Field
          component="select"
          name={attr.key}
          required={attr.required}
          disabled={attr.disabled}
          {...(attr.onChange ? { onChange: attr.onChange } : {})}
        >
          <option value="" label={`${attr.name}...`}>
            {`${attr.name}...`}
          </option>
          {options}
        </Field>
      );

    case 'react-select':
      options = attr.options.map((option) => ({
        value: option.value,
        label: option.label,
      }));

      return (
        <Field name={attr.key} component={CustomSelect} options={options} />
      );
    case 'radio':
      options = attr.options.map((option) => (
        <label name={attr.key} key={option.value}>
          <Field
            type={attr.type}
            name={attr.key}
            key={option.value}
            value={option.value}
            required={attr.required}
            disabled={attr.disabled}
          />
          <span> {option.label} </span>
        </label>
      ));

      return <div className="radio-button">{options}</div>;
    case 'textarea':
      return (
        <Field
          type={attr.type}
          name={attr.key}
          component="textarea"
          required={attr.required}
          disabled={attr.disabled}
          minLength={attr.min}
          maxLength={attr.max}
        />
      );
    case 'label':
      return null;
    case 'hidden':
      return (
        <Field
          type={attr.type}
          name={attr.key}
          defaultValue={attr.value}
          required={attr.required}
        />
      );
    default:
      return (
        <Field
          type={attr.type}
          name={attr.key}
          pattern={attr.pattern}
          required={attr.required}
          disabled={attr.disabled}
          min={attr.min}
          max={attr.max}
          {...(attr.onChange ? { onChange: attr.onChange } : {})}
        />
      );
  }
}
