import UUID from 'uuid/v4';
import { useState, useEffect } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import {
  FiEdit,
  FiTrash,
  FiMaximize,
  FiVolume2,
  FiFilm,
  FiBook,
  FiCornerUpRight,
} from 'react-icons/fi';
import { ContextMenuTrigger } from 'react-contextmenu';
import { toast } from 'react-toastify';
import i18n from 'i18n/config';
import {
  handleDragStart,
  handleDrop,
  handleDragOver,
  handleGutterDragOver,
  handleGutterDrop,
} from '../utils/drag';
import { historyPush, save } from '../utils/history';
import Media from '../../../../utils/Media';
import keyboardEvents from '../utils/keyboard';
import StudioClipboard from '../utils/clipboard';

function Images({ node, functions, currentStateImage }) {
  if (functions.mode === 'view') {
    let fileName = currentStateImage;
    if (typeof currentStateImage !== 'string') {
      fileName = currentStateImage?.image1;
    }

    return (
      <div className="images">
        <Media
          filename={fileName}
          placeholder
          contentId={functions.contentId}
          style={{
            backgroundColor: currentStateImage?.backgroundColor
              ? currentStateImage?.backgroundColor
              : '',
          }}
        />
      </div>
    );
  }

  return (
    <div className="images">
      <Media
        filename={
          node.states && node.states.length > 0
            ? currentStateImage.image1
            : node.image1
        }
        placeholder
        contentId={functions.contentId}
      />
      {node.states && node.states.length > 1 && <FaArrowRight />}
    </div>
  );
}

export default function Item({ node, functions, index }) {
  const hasStates = node.states && node.states.length > 0;
  // Store mode only
  const [currentStateImage, setCurrentStateImage] = useState(
    hasStates ? node.states[0] : node.image1
  );
  const [currentText, setCurrentText] = useState(
    hasStates ? currentStateImage.writtenText : node.writtenText
  );

  useEffect(() => {
    setCurrentStateImage(hasStates ? node.states[0] : node.image1);
    setCurrentText(hasStates ? node.states[0].writtenText : node.writtenText);
  }, [functions.mode, functions.selectedState]);

  const cancelBubble = (e) => {
    if (e) e.stopPropagation();
  };

  const editNode = (e, node) => {
    functions.setEdit(node);
    if (node?.states && node.states.length > 0) {
      functions.setSelectedState(node.states[0]);
    }
    functions.setSidebar('item');
    cancelBubble(e);
  };

  const access = (node) => {
    if (node.parent && node.parent.isBookMode) {
      return;
    }

    editNode(null, null);
    functions.setSidebar(null);
    functions.setItem(node);
    functions.setOffset(0);
  };

  const isEditingNode = (node) =>
    functions.editingNode && functions.editingNode.id === node.id;

  const deleteNode = (e, node) => {
    cancelBubble(e);

    if (isEditingNode(node)) {
      functions.setEdit(null);
      functions.setSidebar(null);
    }

    // Is root node
    if (node.parent === null) {
      return false;
    }

    historyPush(functions);
    node.parent.children = node.parent.children.filter(
      (child) => child.id !== node.id
    );
    save(functions);
  };

  const doubleClickHandler = (e, node) => {
    if (functions.currentItem.pageMode === 'book') {
      toast.warn(
        i18n.t('It is not possible to access cards on page book mode')
      );
      return;
    }
    if (node.states && node.states.length > 1) {
      toast.warn(
        i18n.t('It is not possible to access cards with more than one state')
      );
      return;
    }
    if (functions.mode === 'edit') {
      access(node);
    }
  };

  const dragProps = (node) => {
    if (functions.mode !== 'edit') {
      return {};
    }

    return {
      draggable: true,
      onDragStart: (e) => handleDragStart(e, node),
      onDragOver: (e) => handleDragOver(e, node),
      onDragLeave: (e) => e.target.classList.remove('over'),
      onDragEnd: (e) => e.target.classList.remove('dragging'),
      onDrop: (e) => handleDrop(e, node, functions),
    };
  };

  const expandItem = (e) => {
    const element = e.currentTarget;
    const grid = element.closest('#tree');

    // Consider horizontal
    const scale =
      Math.min(
        grid.offsetHeight / element.offsetHeight,
        grid.offsetWidth / element.offsetWidth
      ) - 0.05;

    const itemBounds = element.getBoundingClientRect();
    const gridBounds = grid.getBoundingClientRect();

    const diff = {
      x: itemBounds.left - gridBounds.left,
      y: itemBounds.top - gridBounds.top,
    };

    const distanceToCenter = {
      x: grid.offsetWidth / 2 - element.offsetWidth / 2 - diff.x,
      y: grid.offsetHeight / 2 - element.offsetHeight / 2 - diff.y,
    };

    element.style.transition = '0.2s transform linear';
    element.style.transformOrigin = `${
      distanceToCenter.x + element.offsetWidth / 2
    }px ${distanceToCenter.y + element.offsetHeight / 2}px`;
    element.style.transform = `scale(${scale}) translate(${distanceToCenter.x}px, ${distanceToCenter.y}px)`;
    element.style.borderRadius = '0';

    element.classList.add('expanded');
    grid.classList.add('item-expanded');

    // element.style.transformOrigin = "top"
    return { element, grid };
  };

  const restoreItem = ({ element, grid }) => {
    element.classList.remove('expanded');
    grid.classList.remove('item-expanded');

    element.style.transform = `none`;
    element.style.transition = '0.3s transform linear';
  };

  const showStateAfterTime = (image) => {
    setTimeout(() => setCurrentStateImage(image), 1500);
  };

  const [timeoutId, setTimeoutId] = useState(null);

  const showTextRecursively = (texto) => {
    if (texto) {
      const words = texto.split(' ');
      if (words.slice(0, 4)[0] !== '') {
        setCurrentText(words.slice(0, 4).join(' '));
        const newTimeoutId = setTimeout(() => {
          showTextRecursively(words.slice(4).join(' '));
        }, 2000);
        setTimeoutId(newTimeoutId);
      }
    }
  };

  function stopTextRecursion() {
    clearTimeout(timeoutId);
  }

  useEffect(() => {
    stopTextRecursion();
    setCurrentText(
      hasStates ? currentStateImage.writtenText : node.writtenText
    );
  }, [functions.mode, setTimeoutId, functions.currentItem.pageMode]);

  useEffect(() => {
    if (
      functions.currentItem.pageMode === 'book' &&
      functions.mode === 'view'
    ) {
      if (functions.pageOffset === 0 && functions.currentItem.id === node.id) {
        showTextRecursively(
          hasStates ? currentStateImage.spokenText : node.spokenText
        );
      } else if (
        functions.currentItem.children[functions.pageOffset - 1].id === node.id
      ) {
        showTextRecursively(
          hasStates ? currentStateImage.spokenText : node.spokenText
        );
      }
    }
  }, [functions.pageOffset, functions.mode, functions.currentItem.pageMode]);

  const clickHandler = (e, node) => {
    if (functions.mode === 'edit') {
      if (window.innerWidth < 1000) {
        // Mobile
        access(node);
      } else {
        editNode(e, node);
      }
    } else if (
      functions.mode === 'view' &&
      functions.currentItem.pageMode !== 'book'
    ) {
      if (!functions.getAllowedClick()) {
        return;
      }

      // If we are playing the media then stop
      functions.setContentPlaying(null);

      // Add to intellibar
      // ICHECK

      if (node.children?.length === 0) {
        functions.setIntellibar((prev) => [
          ...prev,
          { ...currentStateImage, randomId: UUID() },
        ]);
      }
      // Check if already playing media
      if (node.states && node.states.length > 0) {
        const utterance = functions.speak(currentStateImage.spokenText);

        let el;
        if (currentStateImage.isBigImage) {
          el = expandItem(e);
        }

        utterance.addEventListener('end', () => {
          if (currentStateImage.mediaPath !== '') {
            functions.setContentPlaying(currentStateImage.mediaPath);
          }
          if (currentStateImage.isBigImage) {
            restoreItem(el);
          }
        });
      } else {
        const utterance = functions.speak(node.spokenText);

        let el;
        if (node.isBigImage) {
          el = expandItem(e);
        }

        utterance.addEventListener('end', () => {
          if (node.mediaPath !== '') {
            functions.setContentPlaying(node.mediaPath);
          }
          if (node.isBigImage) {
            restoreItem(el);
          }
        });
      }

      if (node.states && node.states.length > 0) {
        let nextImage = {};
        for (let i = 0; i < node.states.length; i++) {
          if (currentStateImage.index === node.states[i].index) {
            // armazenar id para comparar
            if (i + 1 >= node.states.length) {
              nextImage = {
                index: node.states[0].index,
                image1: node.states[0].image1,
                backgroundColor: node.states[0].backgroundColor,
                borderColor: node.states[0].borderColor,
                writtenText: node.states[0].writtenText,
                spokenText: node.states[0].spokenText,
                onlyText: node.states[0].onlyText,
                isBigImage: node.states[0].isBigImage,
                mediaPath: node.states[0].mediaPath,
              };
              showStateAfterTime(nextImage);
              setCurrentText(nextImage.writtenText);
            } else {
              nextImage = {
                index: node.states[i + 1].index,
                image1: node.states[i + 1].image1,
                backgroundColor: node.states[i + 1].backgroundColor,
                borderColor: node.states[i + 1].borderColor,
                writtenText: node.states[i + 1].writtenText,
                spokenText: node.states[i + 1].spokenText,
                onlyText: node.states[i + 1].onlyText,
                isBigImage: node.states[i + 1].isBigImage,
                mediaPath: node.states[i + 1].mediaPath,
              };
              showStateAfterTime(nextImage);
              setCurrentText(nextImage.writtenText);
            }
          }
        }
      }

      if (node.children?.length > 0) {
        access(node);
      }
    }
  };

  const saveContext = (e, data) => {
    const RIGHT_CLICK_CODE = 2;
    if (e.button === RIGHT_CLICK_CODE) {
      functions.setCtxItem(data);
    }
  };

  const captureKeyEvents = (evt, node) => {
    keyboardEvents(
      evt,
      functions,
      () => clickHandler(null, node),
      () => deleteNode(null, node),
      () => StudioClipboard.copy(node, functions)
    );
  };

  const intellitouchClick = (e, node) => {
    if (functions.settings.intellitouchEnabled) {
      clickHandler(e, node);
    }
  };

  return (
    <div className="item">
      <div
        className="gutter left"
        onDragOver={(e) => handleGutterDragOver(e, node)}
        onDragLeave={(e) => e.target.classList.remove('over')}
        onDrop={(e) => handleGutterDrop(e, node, functions, index)}
        onDragEnter={(e) => e.preventDefault()}
      />
      <li
        onClick={(e) => clickHandler(e, node)}
        onDoubleClick={(e) => doubleClickHandler(e, node)}
        className={`${node.children?.length > 0 ? 'has-children ' : ''} ${
          isEditingNode(node) ? 'editing' : ''
        }`}
        onTouchStart={(e) => intellitouchClick(e, node)}
        onMouseDownCapture={(e) => saveContext(e, node)}
        tabIndex="0"
        onKeyDownCapture={(e) => captureKeyEvents(e, node)}
        data-row={Math.floor(index / functions.settings.collumns)}
        data-collumn={index % functions.settings.collumns}
        {...dragProps(node, functions)}
        style={{
          backgroundColor: hasStates
            ? currentStateImage.backgroundColor
            : node.backgroundColor,
          borderRadius: '10px',
          border: hasStates
            ? currentStateImage.borderColor === '' ||
              !currentStateImage.borderColor
              ? null
              : `5px solid ${currentStateImage.borderColor}`
            : node.borderColor === '' || !node.borderColor
            ? null
            : `5px solid ${node.borderColor}`,
        }}
      >
        <ContextMenuTrigger
          holdToDisplay={-1}
          id="item-context-menu"
          disable={functions.mode === 'view'}
        >
          {!currentStateImage?.onlyText && !node?.onlyText && (
            <Images
              node={node}
              functions={functions}
              currentStateImage={currentStateImage}
            />
          )}
          {node.onlyText && <div style={{ height: '123px' }} />}
          {currentStateImage?.onlyText ? (
            <div className="attribute written-text">
              <span
                className="text"
                title={currentText}
                style={{
                  marginTop: functions.mode === 'view' ? '40%' : '50px',
                }}
              >
                {currentText}
              </span>
            </div>
          ) : (
            <div className="attribute written-text">
              <span className="text" title={currentText}>
                {currentText}
              </span>
            </div>
          )}
          <div className="attributes view-hide">
            <div className="attribute spoken-text view-hide">
              {hasStates && currentStateImage.spokenText !== '' && (
                <>
                  <FiVolume2 />
                  <span className="text">
                    {hasStates ? currentStateImage.spokenText : node.spokenText}
                  </span>
                </>
              )}
            </div>
            <div className="attr-flags">
              {node.isBigImage && (
                <FiMaximize title="This card will play fullscreen" />
              )}
              {hasStates && node.states?.some((state) => state.mediaPath) && (
                <FiFilm title="This card will play a music/video" />
              )}
              {node.isBookMode && <FiBook title="This board is a book" />}
              {node.shortcuts && node.shortcuts.length > 0 && (
                <FiCornerUpRight title="A shotcut can be used to acess this board" />
              )}
            </div>
          </div>

          <div className="actions">
            {functions.isActiveMultiSelectCards &&
              !functions.isActivePasteMultiSelectCards &&
              !functions.isActiveCutMultiSelectCards && (
                <input
                  style={{ cursor: 'pointer' }}
                  type="checkbox"
                  onClick={() => {
                    StudioClipboard.multiSelectCards(node, functions);
                  }}
                />
              )}
            {node.parent !== null &&
              functions.mode === 'edit' &&
              !functions.isActiveMultiSelectCards && (
                <span className="delete" onClick={(e) => deleteNode(e, node)}>
                  <FiTrash />
                </span>
              )}
            <span className="edit-node" onClick={(e) => editNode(e, node)}>
              <FiEdit />
            </span>
          </div>
        </ContextMenuTrigger>
      </li>
      <div
        className="gutter right"
        onDragOver={(e) => handleGutterDragOver(e, node)}
        onDragLeave={(e) => e.target.classList.remove('over')}
        onDrop={(e) => handleGutterDrop(e, node, functions, index + 1)}
        onDragEnter={(e) => e.preventDefault()}
      />
    </div>
  );
}
