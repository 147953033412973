import { HTMLAttributes, useMemo } from 'react';

export function getMediaURL(media: string) {
  return `https://storage.googleapis.com/livox-images/thumbs/${media.replace(
    '.png',
    ''
  )}.png`;
}

interface Props extends HTMLAttributes<HTMLImageElement> {
  filename?: string;
}

export default function Thumbs(props: Props) {
  const { filename } = props;
  const media = filename || '';
  if (!filename) {
    return null;
  }

  return (
    <img {...props} className="image" src={getMediaURL(media)} alt={media} />
  );
}
