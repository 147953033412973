import React from 'react';
import { FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import i18n from 'i18n/config';
import timeSince from 'utils/timeSince';
import { routePath, ROUTES } from 'routes';
import { CLOUD_URL } from 'constants.js';
import parameterize from 'parameterize';
import { post } from 'utils/fetch';
import Author from '../common/Author';
import { Attribute, renderSearchTable } from '../../../utils/dataview';

export default class StorePromoted extends React.Component {
  constructor() {
    super();

    this.title = 'Promoted Content';
    this.endpoint = `${CLOUD_URL}/promoted/admin`;
    this.attributes = [
      new Attribute(
        'Cover Art',
        'cover',
        0.7,
        null,
        (_, { ContentId, updatedAt, posX, posY }) => (
          <Banner
            contentId={ContentId}
            updatedAt={updatedAt}
            posX={posX}
            posY={posY}
          />
        ),
        false
      ),
      new Attribute('Description', 'title', 1, null, (_, data) => (
        <PublishedContent data={data} />
      )),
      new Attribute('Published By', 'createdAt', 0.5, null, (_, data) => (
        <PublishedMetadata data={data} />
      )),
      new Attribute(
        'Remove',
        'id',
        0.3,
        null,
        (id) => (
          <button onClick={() => this.handleDeletePromoted(id)}>
            {' '}
            <FaTrash />{' '}
          </button>
        ),
        false
      ),
    ];
  }
  //

  handleDeletePromoted(id) {
    post(`${CLOUD_URL}/promoted/delete/`, { id }).then((res) => {
      if (res.success) {
        window.location.reload();
      }
    });
  }

  componentDidMount() {
    document.title = `${i18n.t(this.title)} - Livox`;
  }

  render() {
    return renderSearchTable(this);
  }
}

function Banner({ contentId, updatedAt, posX, posY }) {
  return (
    <div
      className="image"
      style={{
        backgroundImage: `url(https://storage.googleapis.com/uploaded-items/banners/${contentId}?q=${updatedAt}`,
        width: 200,
        height: 75,
        backgroundSize: 'cover',
        backgroundPosition: `${posX}% ${posY}%`,
      }}
      alt="Banner Image"
    />
  );
}

function PublishedContent({ data }) {
  return (
    <div>
      <div>
        <Link
          to={routePath(ROUTES.STORE_PUBLICATION, {
            title: parameterize(data.title),
            ContentId: data.ContentId,
          })}
        >
          {data.title}
        </Link>
      </div>
      <div style={{ color: 'var(--base-subtext)', fontSize: '14px' }}>
        {data.subtitle}
      </div>
    </div>
  );
}

function PublishedMetadata({ data }) {
  const languages = {
    'en-US': `🇺🇸 ${i18n.t('English')}`,
    'pt-BR': `🇧🇷 ${i18n.t('Portuguese')}`,
    'ar-SA': `🇸🇦 ${i18n.t('Arabic')}`,
  };

  const lang =
    data.Content &&
    data.Content.Publications &&
    data.Content.Publications[0].language;

  return (
    <div>
      <Author id={data.promotedBy} />
      <div style={{ color: 'var(--base-subtext)', fontSize: '14px' }}>
        {languages[lang]}
      </div>
      <div style={{ color: 'var(--base-subtext)', fontSize: '14px' }}>
        {timeSince(data.createdAt)}
      </div>
    </div>
  );
}
