import i18n from "i18n/config";

export default function timeSince(date) {

    const seconds = Math.floor((new Date() - Date.parse(date)) / 1000);
    let interval = Math.floor(seconds / 31536000);
  
    if (interval > 1) {
      return `${interval  } ${  i18n.t("years ago")}`;
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return `${interval  } ${  i18n.t("months ago")}`;
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return `${interval  } ${  i18n.t("days ago")}`;
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return `${interval  } ${  i18n.t("hours ago")}`;
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return `${interval  } ${  i18n.t("minutes ago")}`;
    }
    return `${Math.floor(seconds)  } ${  i18n.t("seconds ago")}`;
  }
  