import Studio from 'components/studio/Studio';
import { useContext } from 'react';
import { AppContext } from './Reducer';

export default function LivoxEdit() {
  const { holder, dispatch } = useContext(AppContext);

  // TODO move to edit file
  const params = {
    uuid: holder.selectedUser.id,
    mode: 'edit',
  };

  const overrides = {
    settings: holder.selectedUser.settings,
    onSettingsChange(settings) {
      dispatch({ type: 'CHANGE_USER_SETTINGS', payload: { settings } });
    },
    onBoardChange(data) {
      dispatch({ type: 'CHANGE_USER_BOARD', payload: { data } });
    },
  };

  return (
    <Studio
      match={{ params }}
      data={holder.boards[holder.selectedUserId].data}
      overrides={overrides}
    />
  );
}
