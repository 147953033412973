import React from 'react';
import { InputField, renderForm, getFormParams } from 'utils/form';
import { toast } from 'react-toastify';
import "./sponsorRegister.scss"

export default class SponsorUserRegister extends React.Component {

    constructor(props){  
      super(props);
  
      this.title = "Welcome to Livox, please setup a password"
      const token = props.match.params.uuid;
      this.endpoint = `/sponsors/users/create/${token}/`
      this.state = {}
      this.state.fields = [
        new InputField("Name", "full_name", true, {size:1, disabled: true}),
        new InputField("Email", "email", true, {type: "email", size:1, disabled: true }),
        new InputField("Password", "password", true, {type: "password", size:1,}),
      ];
    }
    
    componentWillMount(){
      (async() => {
        const params = await getFormParams(this);
        if (!params){
          toast.info("An user was already created for this sponsor")
          window.location.pathname = "/";
        }
      })()
    }

    onFormError(e){
      alert(e.message)
    }

    onFormSuccess(){
      toast.success("Success! Please login with your credentials")
        window.location.pathname = "/";
    }
  
    render (){ 
      return(renderForm(this));
    }
  
}
  