import React, { useRef } from 'react';
import i18n from "i18n/config";
import { post } from 'utils/fetch';
import { fetchUserData, getUserData } from 'utils/currentUser';
import defaultPicture from "media/default.png";
import { FiCamera } from 'react-icons/fi';
import { DEFAULT_URL } from '../../../../../constants';

export default class FormUpdatePicture extends React.Component {

    constructor(){  
      super();
      const state = {};
      this.state = state;
    }

    onFormSuccess(){
      window.history.back();
      fetchUserData();
    }
  
    render (){ 
      return(
        <div className="profile">
            <div className="card">

                <div className="picture-panel-body">
                    <PictureForm />
                </div>
            </div>
        </div>
    );
    }
  
}

export function PictureForm() {

    function imgError(image) {
        image.currentTarget.onerror = "";
        image.currentTarget.src = defaultPicture;
        return true;
    }

    const fileUpload = (file) => {
        const endpoint = '/holders/image-upload/'
        const url = DEFAULT_URL + endpoint
        const formData = new FormData();
        formData.append('file', file);

        post(url, formData, 'multipart/form-data').then(res => {
            fetchUserData().then(data => 
                window.location.reload()
            )
            // window.history.back();
        });
    }

    const {picture} = getUserData();
    let url;
    if (picture) {
      if (picture.startsWith('http://') || picture.startsWith('https://')) {
        url = picture;
      } else {
        url = `http://localhost:8000${  picture}`
      }
    }

    // console.log('Url:', url)

    const fileInput = useRef(null);

    const handleClick = (e) => {
        // this.refs.pictureUploaderRef.click();
        fileInput.current.click();
    }

    return(
        <div className="picture-form" onClick={handleClick}>
            <div className="user-picture">
                <img src={url || defaultPicture} alt="User"  onError={imgError}/>
                <span className="change-photo">
                    <FiCamera/> {i18n.t("Change")}
                </span>
            </div>

            <form id="pictureUploadForm" onChange={(e) => fileUpload(e.target.files[0])}>
                <input type="file" className="hidden" id="uploadMedia" accept="image/*" ref={fileInput}/>
            </form>
        </div>
    )
}