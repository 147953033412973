import { useState, useCallback } from 'react';
import i18n from 'i18n/config';
import { CLOUD_URL } from 'constants.js';
import {
  FiRefreshCw,
  FiScissors,
  FiCopy,
  FiClipboard,
  FiRotateCcw,
  FiChevronLeft,
  FiDownload,
  FiUploadCloud,
  FiThumbsUp,
  FiThumbsDown,
  FiInfo,
  FiCheckCircle,
  FiAlertCircle,
  FiCheckSquare,
  FiXCircle,
  FiTrash,
} from 'react-icons/fi';
import { FaStore } from 'react-icons/fa';
import { post } from 'utils/fetch';
import { ROUTES } from 'routes';
import { Link } from 'react-router-dom';
import { DropDownList } from 'components/common/ddl';
import Modal from 'utils/modal';
import CategoryType from 'components/studio/categoryType';
import { undo, save } from '../utils/history';
import downloadZip from '../utils/download';
import StudioClipboard from '../utils/clipboard';

const handleParent = (functions) => {
  const { parent } = functions.currentItem;
  if (functions.contentPlaying) {
    functions.setContentPlaying(null);
  } else if (parent) {
    functions.setItem(parent);
  } else if (!functions.currentItem.isMock) {
    functions.gotoRoot();
  } else {
    window.location.href = '/store/self';
  }
};

const downloadOptions = [
  { name: 'v1', label: i18n.t('Livox4') },
  { name: 'v2', label: i18n.t('Livox5+') },
];

function Store({ functions }) {
  if (functions.mode === 'view') return null;

  return (
    <button
      type="button"
      className={functions.currentItem.isMock ? 'disabled' : null}
    >
      <Link to={ROUTES.STORE.path}>
        <FaStore /> <span className="hide-mobile"> {i18n.t('Store')} </span>
      </Link>
    </button>
  );
}

function Back({ functions }) {
  if (functions.mode === 'view' || functions.categoryType === CategoryType.GAME)
    return null;

  return (
    <button
      type="button"
      onClick={() => handleParent(functions)}
      className={functions.currentItem.isMock ? 'disabled' : null}
    >
      <FiChevronLeft /> <span className="hide-mobile"> {i18n.t('Back')} </span>
    </button>
  );
}

function Undo({ functions }) {
  if (functions.mode !== 'edit') return null;
  return (
    <button
      type="button"
      onClick={() => undo(functions)}
      disabled={functions.history.length === 0}
    >
      <FiRotateCcw /> <span className="hide-mobile">{i18n.t('Undo')} </span>
    </button>
  );
}

function Clipboard({ functions }) {
  if (functions.mode !== 'edit') return null;

  const [isModalDeleteMultiActive, setIsModalDeleteMultiActive] =
    useState(false);

  const [ddlVisibleMultiSelectCards, setDdlVisibleMultiSelectCards] =
    useState(false);

  function handleConfirmDeleteMultiCards(e) {
    StudioClipboard.deleteAllNodes(e, functions);
    setIsModalDeleteMultiActive(false);
  }

  const handleDownloadZipMultiSelectCards = useCallback((version) => {
    const {
      currentItem,
      multiSelectCards,
      metadata,
      setIsActiveMultiSelectCards,
      setIsActivePasteMultiSelectCards,
      setIsActiveCutMultiSelectCards,
      setMultiSelectCards,
    } = functions;
    const children = currentItem.children.filter((item) =>
      multiSelectCards.some((selectedCard) => selectedCard.id === item.id)
    );
    const tree = { ...currentItem, children };
    downloadZip({ tree, metadata, version });
    setIsActiveMultiSelectCards(false);
    setIsActivePasteMultiSelectCards(false);
    setIsActiveCutMultiSelectCards(false);
    setMultiSelectCards([]);
  });

  return (
    <div className="clipboard">
      <button
        type="button"
        onClick={() => StudioClipboard.cut(functions.editingNode, functions)}
        disabled={!functions.editingNode}
        title={i18n.t('Cut')}
      >
        <FiScissors />
      </button>

      <button
        type="button"
        onClick={() => StudioClipboard.copy(functions.editingNode, functions)}
        disabled={!functions.editingNode}
        title={i18n.t('Copy')}
      >
        <FiCopy />
      </button>

      <button
        type="button"
        onClick={() => StudioClipboard.paste(functions.currentItem, functions)}
        disabled={!StudioClipboard.canPaste()}
        title={i18n.t('Paste')}
      >
        <FiClipboard />
      </button>

      <div
        style={{
          display: 'flex',
          gap: '3px',
          padding: '3px',
          border: '1px solid var(--disabled)',
          borderRadius: '8px',
        }}
      >
        <button
          type="button"
          style={{
            backgroundColor: functions.isActiveMultiSelectCards
              ? 'var(--border)'
              : '',
          }}
          onClick={() => {
            if (functions.isActiveMultiSelectCards) {
              functions.setMultiSelectCards([]);
            }
            functions.setIsActiveMultiSelectCards((prev) => !prev);
          }}
          title={i18n.t('Selecionar Vários')}
        >
          <FiCheckSquare />
        </button>
        {functions.isActiveMultiSelectCards && (
          <div style={{ display: 'flex', gap: '3px' }}>
            <div>
              <button
                type="button"
                title={i18n.t('Download')}
                onClick={() => setDdlVisibleMultiSelectCards((prev) => !prev)}
                disabled={functions.multiSelectCards?.length === 0}
              >
                <FiDownload />
              </button>
              <DropDownList
                options={downloadOptions}
                visible={ddlVisibleMultiSelectCards}
                updateVisible={setDdlVisibleMultiSelectCards}
                onSelect={handleDownloadZipMultiSelectCards}
              />
            </div>
            <button
              type="button"
              style={{
                backgroundColor: functions.isActivePasteMultiSelectCards
                  ? 'var(--border)'
                  : '',
              }}
              title={i18n.t('Copy')}
              onClick={() => {
                functions.setIsActiveCutMultiSelectCards(false);
                functions.setIsActivePasteMultiSelectCards((prev) => !prev);
              }}
              disabled={functions.multiSelectCards?.length === 0}
            >
              <FiCopy />
            </button>
            <button
              type="button"
              style={{
                backgroundColor: functions.isActiveCutMultiSelectCards
                  ? 'var(--border)'
                  : '',
              }}
              title={i18n.t('Cut')}
              onClick={() => {
                functions.setIsActivePasteMultiSelectCards(false);
                functions.setIsActiveCutMultiSelectCards((prev) => !prev);
              }}
              disabled={functions.multiSelectCards?.length === 0}
            >
              <FiScissors />
            </button>
            {(functions.isActivePasteMultiSelectCards ||
              functions.isActiveCutMultiSelectCards) && (
              <div style={{ display: 'flex' }}>
                <button
                  type="button"
                  title={i18n.t('Paste')}
                  onClick={() =>
                    functions.isActivePasteMultiSelectCards
                      ? StudioClipboard.pasteMultiSelectCards(functions)
                      : StudioClipboard.cutMultiSelectCards(functions)
                  }
                >
                  <FiClipboard />
                </button>
                <button
                  type="button"
                  title={i18n.t('Cancelar')}
                  onClick={() => {
                    functions.setMultiSelectCards([]);
                    functions.setIsActivePasteMultiSelectCards(false);
                    functions.setIsActiveMultiSelectCards(false);
                    functions.setIsActiveCutMultiSelectCards(false);
                  }}
                >
                  <FiXCircle />
                </button>
              </div>
            )}
            <button
              type="button"
              style={{
                backgroundColor: functions.isActivePasteMultiSelectCards
                  ? 'var(--border)'
                  : '',
              }}
              title={i18n.t('Excluir')}
              onClick={() => setIsModalDeleteMultiActive(true)}
              disabled={functions.multiSelectCards?.length === 0}
            >
              <FiTrash />
            </button>
            <Modal
              active={isModalDeleteMultiActive}
              onCancel={() => setIsModalDeleteMultiActive(false)}
              textModal={i18n.t(
                "Are you sure you want to delete the card(s)? This can't be undone!"
              )}
              onConfirm={(e) => handleConfirmDeleteMultiCards(e)}
              textConfirm={i18n.t('Confirm')}
              colorConfirm="#fb161b"
            />
          </div>
        )}
      </div>
    </div>
  );
}

function Download({ functions }) {
  const [ddlVisible, setDdlVisible] = useState(false);

  const onSelect = useCallback((option) => {
    downloadZip({
      tree: functions.tree,
      metadata: functions.metadata,
      version: option,
    });
  });

  if (functions.mode !== 'edit') return null;
  return (
    <div>
      <button type="button" onClick={() => setDdlVisible((prev) => !prev)}>
        <FiDownload /> <span className="hide-mobile">{i18n.t('Download')}</span>
      </button>

      <DropDownList
        options={downloadOptions}
        visible={ddlVisible}
        updateVisible={setDdlVisible}
        onSelect={onSelect}
      />
    </div>
  );
}

function Reupload({ functions }) {
  const [status, setStatus] = useState('idle');
  if (functions.mode !== 'edit') return null;

  function fileUpload(e) {
    const file = e.target.files[0];
    const url = `${CLOUD_URL}/content/${functions.uuid}/zip`;
    const formData = new FormData();
    formData.append('file', file);
    setStatus('uploading');
    post(url, formData, 'multipart/form-data').then((res) => {
      functions.addParentReference(res.data, null);
      functions.setTree(res.data);
      functions.setItem(res.data);
      setStatus('idle');
      save(functions);
    });
  }

  return (
    <form
      className="delete upload"
      onClick={(e) =>
        e.stopPropagation() &&
        alert('Warning: This will overwrite current saved data!')
      }
      onChange={(e) => fileUpload(e)}
    >
      <button type="button">
        <label htmlFor="zip-overwrite" className={status}>
          <FiRefreshCw />
          &nbsp;
          <span className="hide-mobile">
            {status === 'idle' ? i18n.t('Zip Overwrite') : i18n.t('Loading...')}
          </span>
        </label>
      </button>
      <input
        className="hidden"
        type="file"
        id="zip-overwrite"
        accept="application/zip"
      />
    </form>
  );
}

function Publish({ functions }) {
  if (functions.mode !== 'edit') return null;
  return (
    <button type="button" onClick={() => functions.setModal('publish')}>
      <FiUploadCloud /> <span className="hide-mobile">{i18n.t('Publish')}</span>
    </button>
  );
}

function Approve({ functions }) {
  if (functions.mode !== 'review') return null;
  return (
    <button type="button" onClick={() => functions.setModal('approve')}>
      <FiThumbsUp /> <span className="hide-mobile">{i18n.t('Accept')}</span>
    </button>
  );
}

function Reject({ functions }) {
  if (functions.mode !== 'review') return null;
  return (
    <button type="button" onClick={() => functions.setModal('reject')}>
      <FiThumbsDown /> <span className="hide-mobile">{i18n.t('Reject')}</span>
    </button>
  );
}

function SaveStatus({ functions }) {
  if (functions.mode !== 'edit') return null;

  if (functions.savingStatus === 'saved') {
    return (
      <div className="save-status">
        <FiCheckCircle style={{ color: 'green' }} />{' '}
        <span className="hide-mobile"> {i18n.t('Saved')} </span>
      </div>
    );
  }
  if (functions.savingStatus === 'saving') {
    return (
      <div className="save-status">
        <FiInfo style={{ color: '#666' }} />{' '}
        <span className="hide-mobile"> {i18n.t('Saving...')} </span>
      </div>
    );
  }
  if (functions.savingStatus === 'fail') {
    return (
      <div className="save-status">
        <FiAlertCircle style={{ color: 'red' }} />{' '}
        <span className="hide-mobile">
          {' '}
          {i18n.t('Error! Please refresh the page.')}{' '}
        </span>
      </div>
    );
  }

  return null;
}

export default function Toolbar(props) {
  const { functions } = props;
  return (
    <div className="toolbar">
      <Store functions={functions} />
      <Back functions={functions} />
      <Undo functions={functions} />
      <Clipboard functions={functions} />

      <Download functions={functions} />
      <Publish functions={functions} />
      <Reupload functions={functions} />
      <Approve functions={functions} />
      <Reject functions={functions} />

      <SaveStatus functions={functions} />
    </div>
  );
}
