import i18n from 'i18n/config';
import { Component } from 'react';

export default class PageSizer extends Component {
  setQueryLimit(e) {
    this.props.updateQuery('limit', e.target.value);
  }

  render() {
    if (this.props.pagination === '0') {
      return null;
    }
    return (
      <div className="page-attributes">
        <label htmlFor="query-attribute"> {i18n.t('Page Size')} </label>
        <select
          className="page-sizer"
          value={this.props.limit}
          onChange={this.setQueryLimit.bind(this)}
        >
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">200</option>
          <option value="400">400</option>
          <option value="1000">1000</option>
          <option value="5000">5000</option>
          <option value="10000">10000</option>
        </select>
      </div>
    );
  }
}
