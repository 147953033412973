import { useContext, useEffect } from 'react';

import Studio from 'components/studio/Studio';
import { AppContext } from './Reducer';
import './play.scss';
import i18n from 'i18n/config';
import { FiSettings, FiChevronLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';

function ControlBar({ functions }) {
  return (
    <div className="studio-controls play-controls">
      <div>
        <Link
          to="/app"
          className={`control settings ${
            functions.sidebarMode === 'settings' ? 'active' : ''
          }`}
        >
          <>
            <FiChevronLeft /> {i18n.t('Back')}
          </>
        </Link>
      </div>

      <div
        className={`control settings ${
          functions.sidebarMode === 'settings' ? 'active' : ''
        }`}
        onClick={() =>
          functions.sidebarMode === 'settings'
            ? functions.setSidebar(null)
            : functions.setSidebar('settings')
        }
      >
        <FiSettings />
      </div>
    </div>
  );
}

export default function LivoxPlay() {
  const { holder, dispatch } = useContext(AppContext);
  useEffect(() => {
    document.getElementById('navbar').classList.add('collapsed');
    return () => {
      document.getElementById('navbar').classList.remove('collapsed');
    };
  }, []);

  const params = {
    uuid: holder.selectedUser.id,
    mode: 'view',
  };
  const overrides = {
    onSettingsChange(settings) {
      dispatch({ type: 'CHANGE_USER_SETTINGS', payload: { settings } });
    },
    settings: holder.selectedUser.settings,
    controlBar: ControlBar,
  };
  return (
    <Studio
      match={{ params }}
      data={holder.boards[holder.selectedUserId].data}
      overrides={overrides}
    />
  );
}
