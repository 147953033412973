import React, { useEffect, useState } from 'react';

// TODO MOVE
import Fuse from "fuse.js";
import { FiMic, FiMicOff } from 'react-icons/fi';
import { useHistory } from "react-router-dom";
import { ROUTES } from 'routes';
import { AppContext } from '../Reducer';

export default function SpeechRecognizer() {

    const [status, setStatus] = useState("WAITING");
    const [recognitionEngine, setRecognition] = useState(null);
    const { holder } = React.useContext(AppContext);
    const history = useHistory();


    function findShortcuts(node, phrases){
        if (node.shortcuts && node.shortcuts.length > 0){
            node.shortcuts.forEach(shortcut => {
                phrases.push({id: node.id, text: shortcut});
            })
        }
        node.children.forEach(child => findShortcuts(child, phrases));
    }

    // Prevent rerenders
    useEffect(() => {
        const canPlaySpeech = ('webkitSpeechRecognition' in window);
        const keywords = holder.selectedUser.settings.naturalConversationKeywords;
        const phrases = []
        findShortcuts(holder.boards[holder.selectedUserId].data, phrases)

        console.log(phrases)
        // Add to fuse
        const options = {
            includeScore: true,
            ignoreLocation: true,
            keys: ['text']
        }

        const fuse = new Fuse(phrases, options)


        let lastKeywordResult = -1;
        let lastPhraseResult = -1;

        if (canPlaySpeech) {
            let recognition;
            recognition = new window.webkitSpeechRecognition();
            // TODO Use user lang
            recognition.lang = "pt-BR";
            recognition.continuous = true;
            // TODO use this for more precision
            recognition.maxAlternatives = 3;
            recognition.interimResults = true;

            recognition.onstart = function (event) {
                console.log('Speech has started');
                console.log(event);
                setStatus("LISTENING");
            }

            recognition.onresult = function (event) {

                const currentIndex = event.resultIndex;
                const result = event.results[currentIndex][0].transcript;

                const regex = new RegExp(keywords.join("|"), "gi");

                if (regex.test(result)) {
                    let lastOcurrence = regex.lastIndex

                    // Interate until last instance
                    while (regex.test(result)) {
                        lastOcurrence = regex.lastIndex;
                    }

                    if (lastKeywordResult !== currentIndex) {
                        console.log("TRIGGER_DETECTED");
                        setStatus("TRIGGER_DETECTED");
                    }

                    const searchTarget = result.slice(lastOcurrence, result.length)
                    console.log("search ", searchTarget)
                    console.log(lastPhraseResult)
                    if (lastPhraseResult !== currentIndex) {
                        const searchResults = fuse.search(searchTarget);
                        console.log(searchResults)
                        const firstResult = searchResults[0];
                        if (firstResult && firstResult.score < 0.2 && firstResult.score !== 0.01) {
                            lastPhraseResult = currentIndex;
                            console.log("TRIGGER_SUCCESS")
                            setStatus("TRIGGER_SUCCESS");
                            history.push(`${ROUTES.LIVOX_WEB_PLAY.path  }#${  firstResult.item.id}`);
                        } else if (event.results[currentIndex].isFinal) {
                            setStatus("PHRASE_FAIL");
                        }
                    }

                    lastKeywordResult = currentIndex;
                }

                console.log(event);
                console.log(`Detected ${  event.results[currentIndex].isFinal  } ${  event.results[event.resultIndex][0].transcript}`)
            }

            recognition.onspeechend = function (event) {
                console.log('Speech has stopped being detected');
                console.log(event);
            }

            recognition.onend = function (event) {
                console.log('Speech has ended');
                setStatus("STOPPED");
                console.log(event);
            }

            if (holder.selectedUser.settings.naturalConversationEnabled){
                recognition.start();
            }
            setRecognition(recognition);
        }


    }, [findShortcuts, history, holder.boards, holder.selectedUser.settings.naturalConversationEnabled, holder.selectedUser.settings.naturalConversationKeywords, holder.selectedUserId])


    function getStatus(status) {

        switch (status) {
            case "LISTENING":
                return (
                    <div title="Livox is listening to keywords.">
                        <FiMic />
                    </div>
                )
            case "TRIGGER_DETECTED":
                return (
                    <div title="Livox is listening to keywords.">
                        <FiMic />
                    </div>
                )
            case "TRIGGER_SUCESS":
                return (
                    <div title="Livox is listening to keywords.">
                        <FiMic />
                    </div>
                )
            case "PHRASE_FAIL":
                return (
                    <div title="Listened to the keyword, but failed to find an associated phrase.">
                        <FiMic />
                    </div>
                )
            case "STOPPED":
                return (
                    <div title="Livox has stopped listening.">
                        <FiMicOff />
                    </div>
                )
            // TODO tutorial on waiting
            case "WAITING":
                return (
                    <div title="Livox has stopped listening.">
                        <FiMicOff />
                    </div>
                )
            default:
                break;
        }
    }

    if (!recognitionEngine || !holder.selectedUser.settings.naturalConversationEnabled){
        return null;
    }

    function toggleSpeechRecognition(){
        return (status === "STOPPED") ? recognitionEngine.start() : recognitionEngine.stop()
    }

    return (
        <div className={`speech-recognizer ${status}`} onClick={toggleSpeechRecognition}>
            {getStatus(status)}
        </div>
    )
}
