import React, { useState } from 'react';
import { FiVolume2 } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { Slider, Picker, Toggler } from './Interface';
import { AppContext } from '../Reducer';
import { getAvailableLanguages, getLanguageVoices, testSpeak } from '../utils/voicePicker';

export default function SoundSettings() {
    const { holder, dispatch } = React.useContext(AppContext);
    const { t } = useTranslation();

    const synth = window.speechSynthesis;

    const [voices, setVoices] = useState(synth.getVoices());

    synth.onvoiceschanged = function() {
        setVoices(synth.getVoices());
    };

    const languages = getAvailableLanguages(voices);
    const voicesOptions = getLanguageVoices(voices, holder.selectedUser.settings.voiceLanguage);
    const {settings} = holder.selectedUser;


    function updateVoiceUri(value){
        const newVoiceUri = voices.find(voice => voice.lang === value).voiceURI;
        dispatch({ type: "CHANGE_USER_SETTINGS", payload: { settings: { voiceURI: newVoiceUri } } })
    }

    return(
        <div className="sound-settings settings-section">
            <div className="title-bar">
                <h2> {t("Voice")} </h2>
                <button
                    onClick={()=> testSpeak(holder.selectedUser.name, settings.voiceURI, settings.voiceSpeed, settings.pitchValue)}>
                    <FiVolume2/>
                    {t("Test")}
                </button>
            </div>
            <div className="content">
                <Picker label="Voice Language" attribute="voiceLanguage" options={languages} onChange={updateVoiceUri} />
                <Picker label="Voice Actor" attribute="voiceURI" options={voicesOptions} />
                <Slider label="Voice speed" attribute="voiceSpeed" upperLimit={200} lowerLimit={1} />
                <Slider label="Pitch Rate" attribute="pitchValue" upperLimit={200} lowerLimit={1} />
                {/* <Toggler label={"Prevent actions while speaking"}
                    description={"Prevents the user of playing another card while an item is currently speaking"}
                    attribute={"utteranceFreezeActions"} /> */}
                <Toggler label="Speak navigation arrows" attribute="arrowUtterance"  />

            </div>
        </div>
    )
}
