import { createRef, useEffect } from 'react';

export default function Player({
  filename,
  contentId,
  autoplay,
  setContentPlaying,
}) {
  const htmlMedia = createRef();

  useEffect(() => {
    const player = htmlMedia.current;
    if (player) {
      player.pause();
      player.load();
      if (autoplay) {
        player.play();
        player.onended = () => {
          setContentPlaying(null);
        };
        player.onpause = () => {
          setContentPlaying(null);
        };
      }
    }
  }, [htmlMedia, filename, autoplay, setContentPlaying]);

  let mediaType;

  if (filename) {
    const mediaExtension = filename.substr(-3);
    if (
      mediaExtension === 'mp3' ||
      mediaExtension === '3gp' ||
      mediaExtension === 'wav'
    ) {
      mediaType = 'audio';
    } else {
      mediaType = 'video';
    }
  }

  let player = null;

  if (mediaType === 'video') {
    player = (
      <video
        controls={!autoplay}
        ref={htmlMedia}
        onClick={() => htmlMedia.current.pause()}
      >
        <source
          src={`https://storage.googleapis.com/uploaded-items/media/${filename}`}
          type="video/mp4"
        />
      </video>
    );
  } else if (mediaType === 'audio') {
    player = (
      <audio controls ref={htmlMedia}>
        <source
          src={`https://storage.googleapis.com/uploaded-items/media/${filename}`}
          type="audio/mpeg"
        />
      </audio>
    );
  }

  return (
    <div
      className={`player ${
        !autoplay || mediaType === 'video' ? 'active' : 'hidden'
      }`}
    >
      {player}
    </div>
  );
}
