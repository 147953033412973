import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { toast } from 'react-toastify';
import { setPreferedMode } from 'utils/darkmode';
import ReactGA from 'react-ga';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import i18n from 'i18n/config';
import FeaturedSection from 'components/store/home/Featured';
import StoreIndex from 'components/store/Index';
import Studio from 'components/studio/Studio';
import CurrentUserLogout from 'components/portal/view/views/auth_users/CurrentUserLogout';
import LivoxWebMain from 'components/livox-web/Reducer';
import SponsorArea from 'components/sponsor-area/Index';
import store from 'state/store';
import { Provider } from 'react-redux';
import i18next from 'i18next';
import { Suspense, lazy } from 'react';
import { ROUTES } from './routes';
import { userLanguage } from './utils/currentUser';

const Portal = lazy(() => import('./Portal'));
const PaymentIndex = lazy(() => import('components/payment/Index'));
const FormRegister = lazy(() =>
  import('./components/portal/view/views/auth_users/FormRegister')
);

const withSuspense = (WrappedComponent, FallbackComponent = null) =>
  function (props) {
    if (!FallbackComponent) FallbackComponent = <div />; // by default
    return (
      <Suspense fallback={FallbackComponent}>
        <WrappedComponent {...props} />
      </Suspense>
    );
  };

ReactGA.initialize('UA-59915754-1');

ReactGA.pageview(window.location.pathname + window.location.search);

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.pageview(location.pathname);
});

i18n.changeLanguage(userLanguage());

i18next.init({
  returnNull: false,
});

const useRTL = userLanguage() === 'ar_SA';

toast.configure({
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: true,
  closeOnClick: true,
  rtl: useRTL,
  pauseOnVisibilityChange: true,
  draggable: true,
  pauseOnHover: true,
});

setPreferedMode();

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <div className={`livox ${useRTL ? 'rtl' : ''}`}>
          <Switch>
            <Route
              path={ROUTES.PAYMENT_INDEX.path}
              component={withSuspense(PaymentIndex)}
            />
            <Route
              exact
              path={ROUTES.REGISTER.path}
              component={withSuspense(FormRegister)}
            />
            <Route path={ROUTES.STORE.path} component={StoreIndex} />
            <Route exact path={ROUTES.STUDIO_CREATOR.path} component={Studio} />
            <Route
              exact
              path={ROUTES.REVIEW_PUBLICATION.path}
              component={Studio}
            />
            <Route
              path={ROUTES.LIVOX_WEB.path}
              component={withSuspense(LivoxWebMain)}
            />
            <Route
              path={ROUTES.FEATURED_BANNER.path}
              component={FeaturedSection}
            />
            <Route path={ROUTES.SPONSOR_AREA.path} component={SponsorArea} />
            <Route path={ROUTES.LOG_OUT.path} component={CurrentUserLogout} />

            <Route component={withSuspense(Portal)} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
