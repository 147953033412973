import React, { useEffect, useState } from 'react';
import { get } from 'utils/fetch';
import i18n from 'i18n/config';
import parameterize from 'parameterize';
import { Link } from 'react-router-dom';

export default function Author({ id }) {
  const [author, setAuthor] = useState(null);
  const endpoint = `/authusers/user/${id}`;

  useEffect(() => {
    get(endpoint).then((res) => setAuthor(res));
  }, [endpoint]);

  if (author === null) return i18n.t('Loading...');

  return (
    <span className="author">
      <Link to={`/store/profile/${parameterize(author.full_name)}/${id}/`}>
        {author.full_name || author.first_name}
      </Link>
    </span>
  );
}
