import i18n from 'i18n/config';
import { FaTrash, FaMusic, FaVideo } from 'react-icons/fa';
import { CLOUD_URL } from 'constants.js';
import { post } from 'utils/fetch';
import Player from '../../utils/player';

function MediaUpload({ isUploaded, contentId, setAttributeIndex }) {
  const fileUpload = (file) => {
    const url = `${CLOUD_URL}/content/${contentId}/media-upload`;
    const formData = new FormData();
    formData.append('file', file);

    post(url, formData, 'multipart/form-data').then((res) => {
      setTimeout(() => {
        setAttributeIndex('mediaPath', res.filename);
      }, 3000);
    });
  };

  if (isUploaded) {
    return null;
  }

  return (
    <form id="mediaUploadForm" onChange={(e) => fileUpload(e.target.files[0])}>
      <label htmlFor="uploadMedia">
        <FaMusic /> / <FaVideo /> {i18n.t('Add an audio or video')}
      </label>
      <input
        type="file"
        className="hidden"
        id="uploadMedia"
        accept="video/*, audio/*"
      />
    </form>
  );
}

function MediaRemove({ setAttributeIndex, isUploaded }) {
  const removeMedia = () => {
    setAttributeIndex('mediaPath', '');
  };

  if (isUploaded) {
    return (
      <button type="button" onClick={removeMedia}>
        <FaTrash />
      </button>
    );
  }

  return null;
}

export default function MediaSection({ functions }) {
  const mediaFile = functions.selectedState.mediaPath;

  return (
    <div className="section media">
      <h2 className="header">
        {i18n.t('Audio / Video')}
        <MediaRemove
          setAttributeIndex={functions.setAttributeIndex}
          isUploaded={!!mediaFile}
        />
      </h2>
      <Player
        filename={functions.selectedState.mediaPath}
        contentId={functions.contentId}
      />
      <MediaUpload
        isUploaded={!!mediaFile}
        contentId={functions.contentId}
        setAttributeIndex={functions.setAttributeIndex}
      />
    </div>
  );
}
