import React, { useEffect, useState } from 'react';
import { get } from 'utils/fetch';
import { CLOUD_URL } from 'constants.js';
import { Link } from 'react-router-dom';
import i18n from 'i18n/config';
import './categories.scss';
import {
  FaGamepad,
  FaComment,
  FaGraduationCap,
  FaSmileBeam,
} from 'react-icons/fa';

export default function StoreCategories() {
  const categoryTemplate = [
    {
      name: 'Communication',
      symbol: FaComment,
      color: '#005aab',
      subcat: [],
    },
    {
      name: 'Education',
      symbol: FaGraduationCap,
      color: '#059a36',
      subcat: [],
    },
    {
      name: 'Fun',
      symbol: FaSmileBeam,
      color: '#af0208',
      subcat: [],
    },
    {
      name: 'Game',
      symbol: FaGamepad,
      color: '#af0208',
      subcat: [],
    },
  ];

  const [categories, setCategories] = useState([]);

  useEffect(
    () => {
      get(`${CLOUD_URL}/store/categories`).then((res) => {
        res.results.forEach((subcat) => {
          const masterCat = categoryTemplate.find(
            (cat) => cat.name.toLowerCase() === subcat.classification
          );
          masterCat.subcat.push(subcat);
        });
        setCategories(categoryTemplate);
      });
    }, // eslint-disable-next-line
        []);

  return (
    <div className="store-categories">
      {categories.map((cat) => (
        <Category cat={cat} key={cat.name} />
      ))}
    </div>
  );
}

function Category({ cat }) {
  return (
    <div className="category">
      <div className="flex">
        <div className="image-container">
          <cat.symbol />
        </div>
        <h2 className="title">{i18n.t(cat.name)}</h2>
      </div>

      <div className="content">
        <div className="subcat">
          {cat.subcat.map((subcat) => (
            <Link
              to={{
                pathname: `store/search/?category=${subcat.slug}`,
                state: {
                  title: subcat.name,
                },
              }}
              key={subcat.slug}
            >
              <li> {i18n.t(subcat.name)} </li>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
