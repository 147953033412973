import React from 'react';
import { CLOUD_URL } from 'constants.js';
import { renderForm } from "utils/form";

export default class ApprovalForm extends React.Component {

  constructor(props) {
    super(props);

    this.title = "Approve Publication";
    this.endpoint = `${CLOUD_URL}/publications/${props.functions.uuid}/approve/`;
    const state = {};
    state.fields = [
    ];
    this.encoding = "application/json";
    this.state = state;
    this.actionName = "Approve";
  }

  onFormCancel(){
    this.props.functions.setModal()
  }

  onFormSuccess() {
    window.location.pathname = "/store/admin/pending";
  }

  render() {
    return (renderForm(this));
  }

}
