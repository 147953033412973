import { useState, useEffect, useMemo } from 'react';
import i18n from 'i18n/config';
import { post, get } from 'utils/fetch';
import { CLOUD_URL } from 'constants';
import Fuse from 'fuse.js';
import { FiArrowRight, FiTrash, FiLoader, FiImage } from 'react-icons/fi';
import { getUserData } from 'utils/currentUser';
import Thumbs from 'utils/Thumbs';
import Media from '../../../../../utils/Media';
import { isEmoji } from '../../utils/emoji';
import AIGenerator from '../AIGenerator';

const options = {
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 100,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  keys: ['name'],
};

function Item({ data, functions, attribute, setImage }) {
  return (
    <div
      className={`item ${data.file.substring(0, data.file.length - 4) ===
        functions.editingNode[attribute]
        ? 'picked'
        : ''
        }`}
      key={data.file}
      onClick={() => setImage(data)}
    >
      <Thumbs filename={data.file} />
      <span>{data.name}</span>
    </div>
  );
}

export default function Gallery(props) {
  const { functions } = props;
  const [query, setQuery] = useState([]);
  const [SearchData, setSearchData] = useState(null);
  const [attribute, setAttribute] = useState('image1');
  const [isUploading, setUploading] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  const fuse = useMemo(
    () => SearchData && new Fuse(SearchData.items, options),
    [SearchData]
  );

  function symbolSearch(query) {
    return fuse.search(query).slice(0, 36);
  }

  // const [fuse, setFuse] = useState(null);
  // Dinamically import symbolstix metadata
  useEffect(() => {
    const availableLanguages = ['en', 'pt-BR', 'ar-SA'];
    let { language } = getUserData();

    if (!availableLanguages.find((lng) => lng === language)) {
      console.error('Coudnt find', language);
      language = 'en';
    }

    get(`${window.location.origin}/symbols/${language}.json`).then((res) => {
      setSearchData(res);
    });
  }, []);

  useEffect(() => {
    if (fuse) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      const timeout = setTimeout(
        () =>
          setSuggestions(
            symbolSearch(functions.selectedState.writtenText).slice(0, 8)
          ),
        1000
      );
      setSearchTimeout(timeout);
    }

    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [fuse, functions.selectedState.writtenText]);

  if (SearchData === null)
    return (
      <>
        <h3>Loading Symbols</h3>
        <div className="loader" />
      </>
    );

  const search = (query) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    const timeout = setTimeout(() => setQuery(symbolSearch(query)), 1000);
    setSearchTimeout(timeout);
  };

  const fileUpload = (file) => {
    // e.preventDefault();
    setUploading(true);
    const url = `${CLOUD_URL}/content/${functions.uuid}/image-upload`;
    const formData = new FormData();
    formData.append('file', file);

    if (attribute === 'image1') {
      functions.editingNode.onlyText = false;
    }

    post(url, formData, 'multipart/form-data')
      .then((res) => {
        functions.setAttributeIndex(attribute, res.filename);
      })
      .finally(() => setUploading(false));
  };

  const setImage = (item) => {
    if (functions.editingNode) {
      if (!functions.currentItem.parent) {
        functions.currentItem.children[0].image1 = item.file;
      }
      if (attribute === 'image1') {
        functions.editingNode.onlyText = false;
      }
      functions.setAttributeIndex(attribute, item.file);
      functions.setAttributeIndex('manualImage', true);
    }
  };

  const resetImage = (attr) => {
    if (functions.editingNode) {
      if (attr === 'image1') {
        functions.editingNode.onlyText = true;
      }
      functions.setAttributeIndex(attr, '');
    }
  };

  const isCustomImage = (filename) => filename && filename.startsWith('livox_');

  const find = (filename) => {
    if (isCustomImage(filename)) {
      return {
        name: i18n.t('Uploaded image'),
        category: '',
        file: filename,
      };
    }

    if (isEmoji(filename)) {
      return {
        name: 'Emoji',
        category: '',
        file: filename,
      };
    }
    return SearchData.items.find((item) => item.file === filename) || {};
  };

  const image1 = find(functions.selectedState.image1);

  return (
    <div>
      {!functions.selectedState?.onlyText && (
        <div className="section gallery">
          <h2 className="header">{i18n.t('Pictures')}</h2>
          <div className="gallery-container">
            <div className="radio-button">
              <label>
                <input
                  type="radio"
                  onChange={() => setAttribute('image1')}
                  checked={attribute === 'image1'}
                />
                <span className="image-attr">
                  <Media
                    filename={image1.file}
                    placeholder
                    contentId={functions.contentId}
                  />

                  <div className="image-description">
                    <span>{image1.name || i18n.t('No image')}</span>
                    {/* <span className="image-category">
                                    {image1.category}
                                </span> */}
                  </div>
                  <span className="actions">
                    {functions.editingNode.image1 && (
                      <FiTrash onClick={() => resetImage('image1')} />
                    )}
                  </span>
                </span>
              </label>
            </div>

            <div className="content scrollable symbol-picker">
              <div className="image-search">
                <input
                  type="text"
                  placeholder={i18n.t('Search an image')}
                  onChange={(e) => search(e.target.value)}
                />
              </div>

              {query.length === 0 ? (
                <div>
                  <div className="uploader">
                    {functions.editingNode.writtenText !== '' && (
                      <h3 className="subtitle">
                        {i18n.t('Suggestions')}
                        <br />
                        <span className="amountImage">
                          ({fuse.list.length} {i18n.t('Images')})
                        </span>
                      </h3>
                    )}
                    <form
                      id="imageUploadForm"
                      onChange={(e) => fileUpload(e.target.files[0])}
                    >
                      {!isUploading ? (
                        <label htmlFor="uploadImage">
                          Upload <FiImage />
                        </label>
                      ) : (
                        <label htmlFor="">
                          Uploading... <FiLoader />
                        </label>
                      )}
                      <input type="file" id="uploadImage" accept="image/*" />
                    </form>
                  </div>
                  <div className="images">
                    {suggestions.slice(0, 8).map((item) => (
                      <Item
                        data={item}
                        key={item.file}
                        functions={functions}
                        attribute={attribute}
                        setImage={setImage}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <div className="images">
                  {query.map((item) => (
                    <Item
                      data={item}
                      key={item.file}
                      functions={functions}
                      attribute={attribute}
                      setImage={setImage}
                    />
                  ))}
                </div>
              )}
            </div>
            <AIGenerator functions={functions} attribute={attribute} />
          </div>
        </div>
      )}
    </div>
  );
}
