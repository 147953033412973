import React from 'react';
import { FaCloudDownloadAlt } from 'react-icons/fa'
import i18n from "i18n/config"

const { Parser } = require('json2csv');

export default class Export extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            exporting: false
        }
    }

    exportToCSV() {
        this.setState({ exporting: true });
        this.csvExport();
    }

    csvExport() {
        const table = this.props.results;

        const converter = new Parser({unwind: ['customer', 'holder']});
        const csv = converter.parse(table);
        let encodedUri = encodeURI(`data:text/csv;charset=utf-8,\uFEFF${csv}`);
        encodedUri = encodedUri.replace(/#/g, '%23'); // Uri won't normally encode '#'
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "export.csv");
        document.body.appendChild(link);
        link.click();
    
    }


    render() {

        return (
            <div className="page-attributes">
                <button className="export" onClick={this.exportToCSV.bind(this)}>
                    <FaCloudDownloadAlt />
                    <span>
                        {i18n.t('Download')}
                    </span>
                </button>
            </div>
        );
    }
}