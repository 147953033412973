
    const constrain = (min, max, value) => {
        if (value < min){
            value = max;
        } else if (value > max) {
            value = min;
        }
        return value;
    }

    export default function keyboardEvents(e, functions, onTrigger, onDelete, onCopy) {
        
        // Trigger node click
        if (e.key === " " || e.key === "Enter") {
            e.preventDefault();
            onTrigger && onTrigger();
        }

        // Delete node
        if (e.key === "Delete" || e.key === "Backspace") {
            onDelete && onDelete();
        }

        const modifierKeyPressed = (e.ctrlKey || e.metaKey);
        
        // Copy node
        if (modifierKeyPressed && e.key === "c") {
            onCopy && onCopy();
        }

        const data = e.target.dataset;
        let row = parseInt(data.row);
        let collumn = parseInt(data.collumn);
        const itemsCount = functions.currentItem.children.length + 1;

        const horizontalLimit = functions.settings.collumns - 1;

        // Pass left node
        if (e.key === "a" || e.key === "ArrowLeft") {
            collumn = constrain(0, horizontalLimit, collumn - 1)
        }

        // Pass left node
        if (e.key === "d" || e.key === "ArrowRight") {
            collumn = constrain(0, horizontalLimit, collumn + 1)
        }

        let verticalLimit = functions.settings.rows - 1;

        if (functions.mode === "edit") {
            // Get full rows
            verticalLimit = Math.floor(itemsCount / functions.settings.collumns) - 1;
            // Add with partial row
            verticalLimit += (itemsCount % functions.settings.collumns) > collumn
        }

        // Pass down node
        if (e.key === "s" || e.key === "ArrowDown") {
            row = constrain(0, verticalLimit, row + 1)
        }

        // Pass up node
        if (e.key === "w" || e.key === "ArrowUp") {
            row = constrain(0, verticalLimit, row - 1)
        }

        const target = document.querySelector(`.item li[data-row='${row}'][data-collumn='${collumn}']`)
        if (target) {
            target.focus();
        } else{
            console.error("Invalid target ", row,collumn)
        }

        

    }
