import React, { useState } from 'react';
// import i18n from "i18n/config"
import "./createUserForm.scss";
import { FiVolume2 } from 'react-icons/fi';
// import { getUserData } from 'utils/currentUser';
import UUID from "uuid/v4";
import { useTranslation } from 'react-i18next';
import { AppContext } from '../Reducer';
import UserAvatar from '../common/Avatar';
import locales from '../utils/locales';
import { emojiCountry, testSpeak } from '../utils/voicePicker';

export default function CreateUserForm({user, callback}) {
	const { dispatch } = React.useContext(AppContext);
    const { t } = useTranslation();

	const synth = window.speechSynthesis;
	const voices = synth.getVoices();

	const availableVoices = [...new Set(voices.map(voice => voice.lang))].sort();

	const deficiencies = [
		'Autism',
		'Amyotrophic Lateral Sclerosis',
		'Cerebral Palsy',
		'No physical disabilities, just unable to speak',
		'Stroke Sequelae',
		'Down Syndrome',
		'Brain Injury',
		'Another motor impairment',
		'Another cognitive impairment'
	]

	const motorSkills = [
		"Normal skills",
		"Shows Repetitive movements",
		"Imperfect touches",
		"No Hands movement"
	]

	const visualSkills = [
		"Normal vision",
		"Moderate vision",
		"Severe visual impairment",
		"Blindness"
	]

	const newUserFields = {
		uuid: UUID(),
		name: "",
		voiceURI: undefined,
		language: "",
		birth_date: "",
		// TODO
		isLiterate: undefined,
	}

	const [newUser, setNewUser] = useState(user || newUserFields)

	const changeForm = (e) => {
		setNewUser({ ...newUser, [e.target.name]: e.target.value })
	}

	const onLanguageSelect = (e) => {
		// Select first voice
		if (e.target.value === "") {
			return changeForm(e);
		}

		const firstVoice = voices.find(voice => voice.lang === e.target.value).voiceURI;
		setNewUser({ ...newUser, voice: firstVoice, [e.target.name]: e.target.value });
	}

	const createUser = () => {
		dispatch({ type: "CREATE_NEW_USER", payload: { user: newUser } });
		if (callback) {
			callback();
		}
	}

	return (
		<form className="create-user" onSubmit={(e) => e.preventDefault()}>
			<div className="user-info">

				<UserAvatar user={newUser} size={100} />
				<input className="user-name" placeholder="User Name" name="name" value={newUser.name} onChange={changeForm} required />

				<label>
					{t("Birth Date")}
				</label>
				<input type="date" name="birth_date" value={newUser.birth_date} onChange={changeForm} required />

				<label htmlFor="voice">{t("Voice")}</label>
				<div className="voice-picker">
					<div>
						<select name="language" id="language" onChange={onLanguageSelect} value={newUser.language} required>
							<option value="">
								{/* // eslint-disable-next-line */}
								🌎 {t("Language")}
							</option>

							{availableVoices.map(lang =>
								<option key={lang} value={lang}>
									{emojiCountry(lang)} {locales[lang]}
								</option>
							)}
						</select>
					</div>
					<div>
						<select name="voiceURI" id="voice" onChange={changeForm} disabled={newUser.language === ""} value={newUser.voiceURI} required>
							<option>
								{t("Select a voice...")}
							</option>

							{voices.filter(voice => voice.lang === newUser.language).map(voice =>
								<option key={voice.voiceURI} value={voice.voiceURI}>
									{voice.name}
								</option>
							)}
						</select>
					</div>
					<button type="button" className="voice-test" onClick={() => testSpeak(newUser.name, newUser.voice)}>
						<FiVolume2 />
					</button>
				</div>

			</div>


			<div className="cognitive-quiz">
				<fieldset className="radio-question">
					<legend>
						What is {newUser.name}'s deficiency?
					</legend>
					{deficiencies.map((def, i) =>
						<label name="deficiency" key={def}>
							<input name="deficiency" id="deficiency" 
								onChange={changeForm} 
								checked={newUser.deficiency === String(i + 1)} 
								type="radio" 
								value={i + 1} 
							/>
							<span> {t(def)}  </span>
						</label>
					)}
				</fieldset>


				<fieldset className="radio-question">
					<legend>
						How are {newUser.name}'s visual habilities?
				</legend>

					{visualSkills.map((def, i) =>
						<label name="visual" key={def}>
							<input name="visual" id="visual" 
							onChange={changeForm} 
							checked={newUser.visual === String(i + 1)} 
							type="radio" 
							value={i + 1} />
							<span> {t(def)}  </span>
						</label>
					)}

				</fieldset>

				<fieldset className="radio-question">
					<legend>
						How are {newUser.name}'s motor habilities?
				</legend>

					{motorSkills.map((def, i) =>
						<label name="motor" key={def}>
							<input name="motor" id="motor" 
							onChange={changeForm} 
							checked={newUser.motor === String(i + 1)} 
							type="radio" 
							value={i + 1} />
							<span> {t(def)}  </span>
						</label>
					)}

				</fieldset>

			</div>

			<button className="create" type="submit" onClick={createUser}>
				{!user ?
					t("Create User")
				:
					t("Update")
				}
			</button>

		</form>
	)
}