import { useState } from 'react';
import { FiZap } from 'react-icons/fi';
import { get } from 'utils/fetch';
import { CLOUD_URL } from 'constants';
import './aiGenerator.scss';
import { useTranslation } from 'react-i18next';
import { historyPush, save } from '../utils/history';

export default function AIGenerator({ functions, attribute }) {
  const [style, setStyle] = useState("");
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  function changeHandler(e) {
    setStyle(e.target.value);
  }

  const { t } = useTranslation();

  const stylesPrompt = {
    "realistic": "Realistic",
    "symbol": "Symbol",
    "cute_3d": "Cute",
    "drawing": "Drawing",
    "watercolor": "Watercolor",
    "oil_painting": "Oil painting",
    "sticker": "Sticker",
    "storybook": "Storybook",
    "realistic_3d": "3D",
    "pixel_art": "Pixel art",
    "photo": "Close-up"
  }

  async function generatePrompt() {
    setLoading(true);
    try {
      const { success, filename } = await get(
        `${CLOUD_URL}/ai/generate-image?prompt=${prompt}&style=${style}`
      );
      if (success) {
        setLoading(false);
        setError(null);
        functions.setAttributeIndex(attribute, filename)
      } else {
        setError(apiError);
        setLoading(false);
      }
    } catch (e) {
      setError(e?.message || 'Error');
      setLoading(false);
    }
  }


  return (
    <div className="content scrollable symbol-picker ai-generator">
      <h3 className="subtitle">
        Draw image with AI
      </h3>
      <div className="parameters">
        <div>
          <input placeholder={"A green parrot..."} onChange={(e) => setPrompt(e.target.value)} />
          <select
            onChange={changeHandler}
            value={style}>
            <option value="" disabled selected>Drawing style</option>
            {Object.entries(stylesPrompt).map(([key, val]) =>
              <option key={key} value={key}>
                {t(val)}
              </option>
            )}
          </select>
        </div>
        <button disabled={!prompt || loading} onClick={() => generatePrompt()}>
          <FiZap />
          Draw
        </button>
      </div>
      <div className="error">
        {error}
      </div>
    </div>
  )
}
