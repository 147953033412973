import i18n from 'i18n/config';
import {
  FaStoreAlt,
  FaBriefcase,
  FaUserAlt,
  FaUserShield,
} from 'react-icons/fa';

class Route {
  constructor(name, path, permission) {
    this.name = i18n.t(name);
    this.path = path;
    this.permission = permission;
  }
}

export const routePath = (route, params) => {
  params = params || [];
  let { path } = route;
  Object.entries(params).forEach(
    ([key, value]) => (path = path.replace(`:${key}`, value))
  );
  return path;
};

export const ROUTES = {
  DASHBOARD: new Route('Home', '/'),
  DASHBOARD_USERS_SEARCH: new Route('Your Users', '/dashboard/users'),
  DASHBOARD_LICENSES_SEARCH: new Route('Your Licenses', '/dashboard/licenses'),
  // Search
  LICENSES_SEARCH: new Route('Licenses', '/licenses', 'Distributor Support'),
  CUSTOMERS_SEARCH: new Route('Customers', '/customers', 'Distributor Support'),
  LIVOX_USERS_SEARCH: new Route(
    'Livox Users',
    '/livox-users',
    'Distributor Support'
  ),
  DISTRIBUTOR_TOTALS_SEARCH: new Route(
    'Distributor Stats',
    '/distributors',
    'Distributor Support'
  ),
  HOLDERS_SEARCH: new Route('Holders', '/holders', 'Distributor Support'),
  SUPPORT_AREAS: new Route('Support Areas', '/support-areas', 'Superuser'),
  SPONSORS: new Route('Sponsors', '/manage-sponsors', 'Superuser'),

  AUDIT_LICENSES: new Route('Audit Licenses', '/audits/licenses', 'Superuser'),
  // Account
  PORTAL_ADMIN: new Route('Portal Administration', '/admin', 'Superuser'),
  ACCOUNT_DEVICES: new Route('My Devices', '/account/devices'),
  ACCOUNT_LIVOX_USERS: new Route('My Livox Users', '/account/livox-users'),
  ACCOUNT_LICENSES: new Route('My Licenses', '/account/licenses'),
  ACCOUNT_EVALUATIONS: new Route('My Evaluations', '/account/evaluations'),

  LIVOX_USER_DASHBOARD: new Route('My Evaluations', '/livox-users/:id/'),

  // Account > Livox User
  LIVOX_USER_LIST_EVALUATIONS: new Route(
    'Evaluations',
    '/livox-users/:id/evaluations'
  ),
  LIVOX_USER_EVALUATE: new Route('Evaluate', '/livox-users/:id/evaluate'),
  // USAGE_STATS: new Route(".Usage Stats", "/"),
  ACCOUNT_BRANCHES: new Route(
    'My Branches',
    '/account/branches',
    'Customer Support'
  ),
  // Branches > View Branch
  VIEW_BRANCH: new Route('View Branch', '/branch/:id'),
  ADD_LICENSE_TO_BRANCH: new Route(
    'Add License to Branch',
    '/branch/addlicense/:id'
  ),

  // AuthUsers
  ACCOUNT_AUTH_USERS: new Route(
    'My Portal Users',
    '/account/auth-users',
    'Customer Support'
  ),
  CREATE_AUTH_USER: new Route('New Portal User', '/account/auth-users/new'),
  // Top bar
  ACCOUNT_PROFILE: new Route('Profile', '/profile'),
  // Forms
  CUSTOMER_CREATE: new Route('New Customer', '/customers/create'),
  CUSTOMER_NOTIFICATION: new Route(
    'Notify Customer',
    '/customers/notification'
  ),
  BRANCH_CREATE: new Route('New Branch', '/account/branches/create'),

  // Subroutes

  /// Licenses
  INSTALL_KEY: new Route('Install Key', '/install-key/:installKey'),
  AUDIT_INDIVIDUAL_LICENSE: new Route(
    'Key',
    '/key/:licenseKey/audit',
    'Superuser'
  ),
  CUSTOMER: new Route('Customer', '/customers/:id'),
  HOLDER: new Route('Holder', '/holder/:id'),
  /// /Licenses > Customer
  LICENSE_SHOW_QRCODE: new Route(
    'Show License',
    '/customers/:id/licenses/qrcode'
  ),
  LICENSE_CREATE: new Route('New License', '/customers/:id/licenses/create'),
  ACCOUNT_AUTH_USER_CREATE: new Route(
    'New Portal User',
    '/customers/:id/auth-users/create'
  ),
  VIEW_BRANCHES: new Route('View Branches', '/customers/:id/branches/'),
  CUSTOMER_BRANCH_CREATE: new Route(
    'Add Branch',
    '/customers/:id/branch/create'
  ),
  /// /Licenses > Customer > License
  REGISTRATION_LINK: new Route('New Registation Link', '/key/:key/link'),
  // Distribution Totals > Distributor customers
  DISTRIBUTOR_CUSTOMERS: new Route(
    'Distributor Customers',
    '/distributor/:id/customers'
  ),
  // Support Areas > New Support Area
  SUPPORT_AREAS_ADD_NEW_AREA: new Route(
    'New Support Area',
    '/support-areas/create'
  ),
  // Support Areas > Users
  SUPPORT_AREAS_USERS: new Route('Users', '/support-area/:id/users'),
  SUPPORT_AREAS_ADD_USERS: new Route(
    'Assign Users',
    '/support-area/:id/users/add'
  ),
  // Support Areas > Customers
  SUPPORT_AREAS_CUSTOMERS: new Route(
    'Customers',
    '/support-area/:id/customers/'
  ),
  SUPPORT_AREAS_ADD_CUSTOMERS: new Route(
    'Assign Customers',
    '/support-area/:id/customers/add'
  ),

  SPONSOR_NEW: new Route('New Sponsor', '/sponsor/new', 'Superuser'),
  SPONSOR_LICENSES: new Route('Sponsor Licenses', '/sponsor/:id/licenses/'),
  SPONSOR_ADD_LICENSES: new Route(
    'Assign Licenses',
    '/sponsor/:id/licenses/add'
  ),

  SPONSOR_AREA: new Route('Sponsor', '/sponsors'),
  SPONSOR_DASHBOARD: new Route('Sponsor', '/sponsors/dashboard/:customer_id/'),
  SPONSOR_OVERVIEW: new Route('Sponsor', '/sponsors/overview/:uuid'),
  SPONSOR_USER_REGISTER: new Route('Sponsor', '/sponsors/register/:uuid'),

  // Profile
  CUSTOMER_EDIT: new Route(
    'Edit Customer',
    '/profile/customer',
    'Customer Admin'
  ),
  PROFILE_EDIT: new Route('Edit Profile', '/profile/edit'),
  PICTURE_EDIT: new Route('Edit Picture', '/picture/edit'),
  LOG_OUT: new Route('Log Out', '/logout'),
  LOG_IN: new Route('New User', '/login'),

  CHANGE_PASSWORD: new Route('Change Password', '/profile/change-pass'),
  // Register
  GENERIC_REGISTER: new Route('New user', '/signup'),
  ACTIVE_USER: new Route('Active User', '/active-user/:token'),

  REGISTER: new Route('Register', '/register/:token'),
  PASSWORD_RESET: new Route('I forgot my password', '/password-reset'),

  // STORE
  ACCOUNT_CONTENT: new Route('My Boards', '/store/self'),
  NEW_CONTENT: new Route('Create Board', '/boards/new'),
  SEARCH_CONTENT: new Route('Boards', '/boards', 'Superuser'),
  PENDING_APPROVAL: new Route(
    'Pending Approval',
    '/store/pending',
    'Superuser'
  ),
  REVIEW_PUBLICATION: new Route(
    'Review Publication',
    '/review/:mode/:uuid/:contentId',
    'Superuser'
  ),
  BOARD_HISTORY: new Route('Board History', '/boards/:uuid/history'),
  STUDIO_CREATOR: new Route('Creator', '/studio/:uuid/:mode'),

  STORE: new Route('Livox Store', '/store'),
  STORE_PROFILE_EDIT: new Route('User Profile', '/store/profile/edit'),

  // Partial routes
  STORE_PUBLICATION: new Route('Publication', 'board/:title/:ContentId'),
  STORE_SEARCH: new Route('Search', 'search'),
  STORE_PROFILE: new Route('User Profile', 'profile/:title/:UserId'),

  /// /Promotion
  STORE_PROMOTED: new Route('Promoted Content', '/store/promoted'),
  // Admin
  STORE_ADMIN: new Route('Manage Boards', '/store/admin'),
  STORE_ADMIN_STATS: new Route('Manage Boards', '/store/admin/stats'),
  STORE_ADMIN_BOARDS: new Route('Manage Boards', '/store/admin/boards'),
  STORE_ADMIN_PENDING: new Route('Manage Boards', '/store/admin/pending'),
  STORE_ADMIN_PROMOTED: new Route('Manage Boards', '/store/admin/promoted'),

  // Home page frame
  FEATURED_BANNER: new Route('Success', '/banner'),

  // Payment
  PAYMENT_INDEX: new Route('Buy Livox', '/payment'),
  PAYMENT_REGISTER: new Route('Register', '/payment/register'),
  PAYMENT_PLANS: new Route('Select a Plan', '/payment/plans'),
  PAYMENT_BILLING_STRIPE: new Route('Billing', '/payment/billing/intl'),
  PAYMENT_SUCCESS: new Route('Success', '/payment/success'),

  PAYMENT_MANAGE: new Route('Billing', '/billing/'),
  CREATE_CARD: new Route('New Card', '/billing/create-card-pm'),
  CREATE_BOLETO: new Route('New Boleto', '/billing/create-boleto-pm'),
  PAYMENT_UPDATE: new Route(
    'Update Payment',
    '/billing/update/:customerId/:cardId'
  ),
  PAYMENT_PLAN_CHANGE: new Route('Update Payment', '/billing/change-plan/:id'),

  // Livox Web
  LIVOX_WEB: new Route('Livox Web', '/app'),
  LIVOX_WEB_STORE: new Route('Livox Web', '/app/store'),
  LIVOX_WEB_EDIT: new Route('Livox Web', '/app/my-board'),
  LIVOX_WEB_PLAY: new Route('Livox Web', '/app/play'),
  LIVOX_WEB_SETTINGS: new Route('Livox Web', '/app/settings'),
  LIVOX_WEB_SETUP: new Route('Livox Web', '/app/setup'),
  LIVOX_WEB_SETUP_HOLDER: new Route('Livox Web', '/app/setup/create-holder'),
  LIVOX_WEB_SETUP_USER: new Route('Livox Web', '/app/setup/first-user'),
  LIVOX_WEB_SETUP_COMPLETE: new Route('Livox Web', '/app/setup/all-set'),

  LIVOX_SETTINGS_DISPLAY: new Route(
    'Settings - Display',
    '/app/settings/display'
  ),
  LIVOX_SETTINGS_SOUND: new Route('Settings - Display', '/app/settings/sound'),
  LIVOX_SETTINGS_CONTROLS: new Route(
    'Settings - Display',
    '/app/settings/controls'
  ),
  LIVOX_SETTINGS_AI: new Route('Settings - Display', '/app/settings/ai'),
  LIVOX_SETTINGS_INFO: new Route('Settings - Display', '/app/settings/info'),

  LIVOX_SETTINGS_USERS: new Route('Settings - Display', '/app/settings/users'),
  LIVOX_SETTINGS_USERS_VIEW: new Route('Livox Web', '/app/settings/users/:id'),
  LIVOX_SETTINGS_USERS_EDIT: new Route(
    'Livox Web',
    '/app/settings/users/:id/edit'
  ),
  LIVOX_SETTINGS_USERS_CREATE: new Route(
    'Livox Web',
    '/app/settings/users/create'
  ),

  LIVOX_SETTINGS_HOLDER: new Route(
    'Settings - Display',
    '/app/settings/holder'
  ),
  LIVOX_SETTINGS_ABOUT: new Route('Settings - Display', '/app/settings/about'),
};

export const NAV_BAR = [
  {
    name: '',
    content: [ROUTES.DASHBOARD, ROUTES.ACCOUNT_PROFILE, ROUTES.LOG_OUT],
  },
  {
    name: 'Store',
    icon: FaStoreAlt,
    content: [ROUTES.STORE, ROUTES.ACCOUNT_CONTENT, ROUTES.PENDING_APPROVAL],
  },
  {
    name: 'Manage',
    icon: FaBriefcase,
    content: [
      ROUTES.LICENSES_SEARCH,
      ROUTES.CUSTOMERS_SEARCH,
      ROUTES.HOLDERS_SEARCH,
      ROUTES.LIVOX_USERS_SEARCH,
      // ROUTES.SEARCH_CONTENT,
      // ROUTES.DISTRIBUTOR_TOTALS_SEARCH,
    ],
  },
  {
    name: 'Account',
    icon: FaUserAlt,
    content: [
      // ROUTES.ACCOUNT_DEVICES,
      ROUTES.ACCOUNT_LIVOX_USERS,
      ROUTES.ACCOUNT_LICENSES,
      // ROUTES.USAGE_STATS,
      ROUTES.ACCOUNT_BRANCHES,
      ROUTES.ACCOUNT_AUTH_USERS,
      ROUTES.ACCOUNT_EVALUATIONS,
    ],
  },
  {
    name: 'Admin',
    icon: FaUserShield,
    content: [
      ROUTES.PORTAL_ADMIN,
      ROUTES.AUDIT_LICENSES,
      ROUTES.SUPPORT_AREAS,
      ROUTES.SPONSORS,
    ],
  },
];
