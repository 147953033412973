import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import parameterize from 'parameterize';
import UUID from 'uuid/v4';
import removeParentReference from './stringifier';
import { emojiMultiplier, isEmoji } from './emoji';

function isLocalFile(image) {
  return image?.includes('.jpg');
}

function getStates(card, cardId) {
  if (card.states && card.states.length > 0) {
    return card.states.map((state) => ({
      index: state.index,
      state_id: state.id,
      card_id: cardId,
      image_id: isLocalFile(state.image1)
        ? state.image1?.replace('.jpg', '')
        : null,
      media_id: state?.mediaPath?.split('.')?.slice(0, -1)?.join('.') || null,
      written_text: state.writtenText,
      spoken_text: state.spokenText,
      border_color: state.borderColor,
      big_image: state.isBigImage,
      just_text: state.onlyText,
      is_visible: true,
      symbol: isLocalFile(state.image1) ? null : state.image1,
      background_color: state.backgroundColor || '#FFFFFF',
    }));
  }

  const states = [];
  const media = card.mediaPath?.split('.')?.slice(0, -1)?.join('.');

  states.push({
    index: 0,
    card_id: cardId,
    image_id: isLocalFile(card.image1)
      ? card.image1?.replace('.jpg', '')
      : null,
    media_id: media || null,
    written_text: card.writtenText,
    spoken_text: card.spokenText,
    border_color: card.borderColor,
    big_image: card.isShowBigImage,
    just_text: card.onlyText === 'S',
    is_visible: true,
    symbol: isLocalFile(card.image1) ? null : card.image1,
    background_color: card.backgroundColor || '#FFFFFF',
  });

  if (card.image2) {
    states.push({
      index: 1,
      card_id: cardId,
      image_id: isLocalFile(card.image2)
        ? card.image2?.replace('.jpg', '')
        : null,
      media_id: media || null,
      written_text: card.writtenText,
      spoken_text: card.spokenText,
      border_color: card.borderColor,
      big_image: card.isShowBigImage,
      just_text: card.onlyText === 'S',
      is_visible: true,
      symbol: isLocalFile(card.image2) ? null : card.image2,
      background_color: card.backgroundColor,
    });
  }

  return states;
}

function isShortcutStringArray(shortcuts) {
  return (
    Array.isArray(shortcuts) &&
    shortcuts.every(function (e) {
      return typeof e === 'string';
    })
  );
}

function convertToV5(content, id) {
  const _content = [];
  const GUIDs = [];

  function transformShortcutV4toV5(strings, id) {
    if (strings) {
      if (isShortcutStringArray(strings)) {
        return strings.map((string) => ({
          board_id: id,
          sentence: string,
          user_id: '',
        }));
      }
    }
    return [];
  }

  const getGUID = (index) => {
    if (index <= 0) return null;
    if (!GUIDs[index - 1]) GUIDs[index - 1] = UUID();
    return GUIDs[index - 1];
  };

  content.reverse().forEach((card) => {
    const old_id = getGUID(card.id);
    const board_id = getGUID(card.itemOriginReference);
    _content.push({
      board_id,
      visibility: true,
      card_mode: card.pageMode
        ? card.pageMode === 'item'
          ? 'REGULAR'
          : card.pageMode === 'book'
          ? 'BOOK_MODE'
          : 'REGULAR'
        : 'REGULAR',
      states: getStates(card, old_id),
      old_id,
      shortcuts: transformShortcutV4toV5(card.shortcuts, old_id),
      change_board_id: board_id === null,
      collumns: card.collumns,
      rows: card.rows,
    });
  });

  return _content;
}

const flattenTree = (children, parentID, id, results) => {
  children.forEach((node, index) => {
    const itemId = id.counter++;
    const nodeChildren = node.children;

    node.id = itemId;
    node.itemId = itemId;
    node.itemOriginReference = parentID;
    node.screenPosition = index;
    node.onlyText = node.onlyText ? 'S' : 'N';
    node.isVisible = 'S';
    node.isShowBigImage = node.isBigImage;

    // caso tenha estados deve pegar os dados do primeiro estado e apenas a imagem do segundo estado
    // pois é download para o livox 4
    if (node.states && node.states.length > 0) {
      node.backgroundColor = node.states[0].backgroundColor;
      node.borderColor =
        node.states[0].borderColor || node.states[0].backgroundColor;
      node.image1 = node.states[0].image1;
      node.onlyText = node.states[0].onlyText ? 'S' : 'N';
      node.isVisible = 'S';
      node.isShowBigImage = node.states[0].isBigImage;
      node.mediaPath = node.states[0].mediaPath;
      node.spokenText = node.states[0].spokenText;
      node.writtenText = node.states[0].writtenText;
      if (node.states.length > 1) {
        node.image2 = node.states[1].image1;
      }
    }

    delete node.isBigImage;
    delete node.children;
    results.push(node);
    flattenTree(nodeChildren, node.id, id, results);
  });
};

function convertToV4(results) {
  return results.map((card) => {
    let { image1 } = card;
    let { image2 } = card;
    let { shortcuts } = card;
    if (card.states && card.states.length > 0) {
      image1 = card.states[0].image1;
      if (card.states.length > 1) {
        image2 = card.states[1].image1;
      }
    }
    if (!isShortcutStringArray(shortcuts) && shortcuts) {
      shortcuts = shortcuts.map((shortcut) => shortcut.sentence);
    }
    delete card.states;
    return { ...card, image1, image2, shortcuts };
  });
}

const toList = (originalTree, version) => {
  const tree = JSON.parse(JSON.stringify(originalTree, removeParentReference));
  const results = [];
  const idStart = { counter: 1 };
  flattenTree([tree], 0, idStart, results);

  if (version === 'v2') {
    return convertToV5(results);
  }

  return convertToV4(results);
};

function getFileNames(version) {
  const file_names = {
    v1: {
      metadata: 'metadata.json',
      cards: 'shareSQLData.txt',
      version: 'version.txt',
    },
    v2: {
      metadata: 'metadata',
      cards: 'cards_1',
      version: 'version',
    },
  };

  return file_names[version];
}

function downloadFromGCS(filename) {
  // returns a Promise
  return fetch(filename).then((res) => {
    // console.log(res);
    if (res.status === 200) {
      return res.blob();
    }
    // console.log("ERROR");
  });
}

export default async function downloadZip({ tree, metadata, version }) {
  const zip = new JSZip();

  const nome = tree.writtenText || 'Board';

  const zipName = (v) => {
    const _ = {
      v1: (metadata && parameterize(nome)) || 'livox-studio',
      v2: (metadata && `${parameterize(nome)}.zip`) || 'livox-studio.zip',
    };
    return _[v];
  };

  const file_names = getFileNames(version);

  // Add shared data
  const itemsList = toList(tree, version);

  const buildContent = {
    v1: (e) => e,
    v2: (e) => e,
  };

  const content = await buildContent[version](itemsList);

  const coverImage =
    tree.states && tree.states.length > 0 ? tree.states[0].image1 : tree.image1;

  const files = {
    metadata: {
      file_name: file_names.metadata,
      data: metadata
        ? {
            id: metadata.id,
            title: tree.writtenText || metadata.title,
            description: metadata.description,
            language: metadata.language || 'en-US',
            hasShortcuts: metadata.hasShortcuts || false,
            coverImage: metadata.coverImage || coverImage,
            createdAt: metadata.createdAt,
          }
        : null,
    },
    version: {
      file_name: file_names.version,
      data: version === 'v1' ? '4.8.1' : version,
    },
    cards: {
      file_name: file_names.cards,
      data: content,
    },
  };

  zip.file(files.metadata.file_name, JSON.stringify(files.metadata.data));
  zip.file(files.cards.file_name, JSON.stringify(files.cards.data));
  zip.file(files.version.file_name, files.version.data);

  // Search each item for custom content for ZIP Insertion
  // Add shared items
  const targets = ['image1', 'image2', 'mediaPath', 'image_id', 'media_id'];
  const queue = [];

  itemsList.forEach((item) => {
    if (item.shortcuts && item.shortcuts.length > 0 && version === 'v1') {
      const values = item.shortcuts.map((shortcut) => ({
        content: shortcut,
        id: item.itemId,
      }));

      const key = {
        values,
        branches: [],
        id: item.writtenText,
      };

      zip.file(
        `shortcut_${item.itemId}_${item.writtenText}`,
        JSON.stringify(key)
      );
    }

    delete item.shortcut;

    if (item.image1 && isEmoji(item.image1)) {
      const canvas = new OffscreenCanvas(256, 256);
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.font = `${120 / emojiMultiplier(item.image1) ** 1.2}px sans-serif`;
      ctx.textAlign = 'center';
      ctx.fillText(item.image1, canvas.width / 2, canvas.height / 1.5);
      console.log(item.image1);
      queue.push(
        canvas
          .convertToBlob({ type: 'image/jpeg', quality: 0.95 })
          .then((blob) =>
            version === 'v1'
              ? zip.file(item.image1, blob)
              : zip.file(`${item.image1}.jpg`, blob)
          )
      );
    }

    targets.forEach((target) => {
      if (version === 'v1') {
        if (
          item[target] &&
          (item[target].startsWith('livox_') || target === 'mediaPath')
        ) {
          const filename = `https://storage.googleapis.com/uploaded-items/media/${item[target]}`;
          const download = downloadFromGCS(filename);
          // Add to promise queue
          // Add to zip
          queue.push(
            download.then(
              (res) => {
                // Replace because Android App cant handle .jpg
                zip.file(item[target].replace('.jpg', ''), res);
              },
              (err) => {
                console.log(err);
              }
            )
          );
        }
      } else {
        item.states?.forEach((state) => {
          // Search if custom content
          if (
            state[target] &&
            (state[target].startsWith('livox_') ||
              state[target].startsWith('audio_') ||
              state[target].startsWith('video_'))
          ) {
            let extension = '.jpg';
            if (
              state[target].startsWith('audio_') ||
              state[target].startsWith('video_')
            ) {
              extension = `.${state[target].split('_')[1]}`;
            }
            const filename = `https://storage.googleapis.com/uploaded-items/media/${state[target]}${extension}`;

            const download = downloadFromGCS(filename);
            // Add to promise queue
            // Add to zip
            queue.push(
              download.then(
                (res) => {
                  // Replace because Android App cant handle .jpg
                  zip.file(state[target], res);
                },
                (err) => {
                  console.log(err);
                }
              )
            );
          }
        });
      }
    });
  });

  // Wait for all files to be inserted
  return Promise.all(queue).then(() => {
    zip.generateAsync({ type: 'blob' }).then((blob) => {
      saveAs(blob, zipName(version));
      return { success: true };
    });
  });
}
