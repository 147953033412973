import React from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { AppContext } from '../Reducer';
import "./checkbox.scss";
import { useTranslation } from 'react-i18next';

export function updateSetting(key, value, dispatch) {

    dispatch({ type: "CHANGE_USER_SETTINGS", payload: { settings: { [key]: value } } })

}
export function Stepper({ label, description, attribute, upperLimit, lowerLimit, increment }) {
    const { holder, dispatch } = React.useContext(AppContext);
    const value = parseInt(holder.selectedUser.settings[attribute]) || 0;
    const { t } = useTranslation();

    return (
        <div className="stepper app-setting">
            <div className="configure">
                <div className="label">
                    {t(label)}
                </div>

                <div className="stepper">
                    <span className="count value">
                        {value}
                    </span>
                    <div className="buttons">
                        <FiMinus onClick={() => value > lowerLimit && updateSetting(attribute, value - (increment || 1), dispatch)} />
                        <div className="divider" />
                        <FiPlus onClick={() => value < upperLimit && updateSetting(attribute, value + (increment || 1), dispatch)} />
                    </div>
                </div>
            </div>
            <div>
                <span className="description">
                    {t(description)}
                </span>
            </div>
        </div>
    )

}

export function Slider({ label, description, attribute, upperLimit, lowerLimit, step, postfix, decimals = 0, disabled, disabledMessage }) {
    const { holder, dispatch } = React.useContext(AppContext);
    const value = parseFloat(holder.selectedUser.settings[attribute]) || 0;
    const { t } = useTranslation();

    return (
        <div className="slider app-setting">
            <div className="configure">
                <div className="label">
                    {t(label)}
                </div>

                <div className="stepper">
                    <span className="count value">
                        {value.toFixed(decimals)}{postfix}
                    </span>
                    <div className="buttons">
                        <input type="range"
                            min={lowerLimit}
                            max={upperLimit}
                            step={step}
                            value={value}
                            className="slider"
                            disabled={disabled}
                            onChange={(e) => updateSetting(attribute, e.target.value, dispatch)} />
                    </div>
                </div>
            </div>
            <div className="description">
                <span>
                    {t(description)}
                </span>
            </div>
            {disabled ?
                <div className="disabled-message">
                    <span>
                        {t(disabledMessage)}
                    </span>
                </div>
                :
                null
            }
        </div>
    )

}

export function Picker({ label, description, attribute, options, onChange }) {
    const { holder, dispatch } = React.useContext(AppContext);
    const value = holder.selectedUser.settings[attribute];
    const { t } = useTranslation();

    function changeHandler(e){
        onChange && onChange(e.target.value);
        updateSetting(attribute, e.target.value, dispatch);
    }

    return (
        <div className="picker app-setting">
            <div className="configure">
                <div className="label">
                    {t(label)}
                </div>

                <div>
                    <div className="buttons">

                        <select
                            onChange={changeHandler}
                            value={value}>

                            {options.map(opt =>
                                <option key={opt.value} value={opt.value}>
                                    {t(opt.label)}
                                </option>
                            )}
                        </select>

                    </div>
                </div>
            </div>
            <div className="description">
                <span>
                    {t(description)}
                </span>
            </div>
        </div>
    )

}

export function Toggler({ label, description, attribute, disabled, disabledMessage }) {
    const { holder, dispatch } = React.useContext(AppContext);
    const value = holder.selectedUser.settings[attribute];
    const { t } = useTranslation();

    return (
        <div className="toggler app-setting">
            <div className="configure">
                <div className="label">
                    {t(label)}
                </div>

                <div>
                    <div className="buttons">

                        <label className="form-switch">
                            <input type="checkbox"
                                checked={value}
                                onChange={(e) => updateSetting(attribute, e.target.checked, dispatch)}
                                disabled={disabled}
                            />
                            <i />
                        </label>
                    </div>
                </div>
            </div>
            <div className="description">
                <span>
                    {t(description)}
                </span>
            </div>
            {disabled ?
                <div className="disabled-message">
                    <span>
                        {t(disabledMessage)}
                    </span>
                </div>
                :
                null
            }
        </div>
    )

}