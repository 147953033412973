import Gallery from './item/Gallery';
import ColorPicker from './item/ColorPicker';
import Attributes from './item/Attributes';
import MediaSection from './item/MediaSection';
import States from './item/States';
import BorderColorPicker from './item/BorderColorPicker';

export default function ItemEdit(props) {
  const { functions } = props;

  if (functions.mode === 'view') return null;

  return (
    <div className="editor">
      <States functions={functions} />
      <Attributes functions={functions} />
      <Gallery functions={functions} />
      <MediaSection functions={functions} />
      <ColorPicker functions={functions} />
      <BorderColorPicker functions={functions} />
    </div>
  );
}
