import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchUserData, loginSuccess } from 'utils/currentUser';
import { post } from 'utils/fetch';

export function ActiveUser() {
  const { token } = useParams<{ token: string }>();
  const [isActivate, setIsActivate] = useState('');

  useEffect(() => {
    if (token) {
      post(`/authusers/activate/${token}`)
        .then(() => {
          setIsActivate('OK');
          loginSuccess(token);
          fetchUserData().then(() => {
            window.location.pathname = '/store';
          });
        })
        .catch(() => {
          alert('An unexpected error ocurred');
          setIsActivate('ERROR');
        });
    }
  }, [token]);

  return (
    <div style={{ textAlign: 'center' }}>
      {isActivate === '' ? (
        <h2 style={{ color: 'black' }}>Ativando...</h2>
      ) : isActivate === 'OK' ? (
        <h2 style={{ color: 'black' }}>Usuário ativado com sucesso</h2>
      ) : (
        <h2 style={{ color: 'red' }}>Erro ao ativar</h2>
      )}
    </div>
  );
}
