import LANGUAGE_BY_LOCALE from "./locales";

// TODO: Deduplicate all this
export function emojiCountry(country) {
    if (!country || country.length < 4) return country

    const firstChar = String.fromCodePoint(country[3].charCodeAt() + 0x1f185 + 32);
    const secondChar = String.fromCodePoint(country[4].charCodeAt() + 0x1f185 + 32);

    return firstChar + secondChar
}

export function getAvailableLanguages(voices) {
    const availableVoices = [...new Set(voices.map(voice => voice.lang))].sort();
    return availableVoices.map(lang => ({ label: `${emojiCountry(lang)} ${LANGUAGE_BY_LOCALE[lang.replace("_", "-")] || lang}`, value: lang }))
}

export function getLanguageVoices(voices, language) {
    return voices.filter(voice => voice.lang === language)
        .map(voice => ({ value: voice.voiceURI, label: voice.name }));
}

function sayHello(name, targetVoice) {
    const phrases = {
        "en": "Hello {name}, how are you?",
        "pt": "Olá {name}, como voce está?",
        "fr": "Bonjour {name}, comment vas-tu?",
        "ru": "Privet {name}, kak ty?",
        "zh": "你好 {name}，你好吗？",
        "ar": "مرحبا {name}، كيف حالك؟"
    }

    if (!targetVoice) return ""
    const voices = window.speechSynthesis.getVoices();
    const lang = voices.find(voice => voice.voiceURI === targetVoice).lang.slice(0, 2);
    let phrase = phrases[lang] || phrases.en;
    phrase = phrase.replace("{name}", name);
    return phrase;
}

export function testSpeak(name, targetVoice, rate, pitch) {
    const speech = window.speechSynthesis;
    const voices = speech.getVoices();
    const utter = new SpeechSynthesisUtterance(sayHello(name, targetVoice));
    utter.voice = voices.find(voice => voice.voiceURI === targetVoice);
    utter.rate = (rate / 100) || 1;
    utter.pitch = (pitch / 100) || 1;
    speech.speak(utter);
}