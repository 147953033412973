import i18n from 'i18n/config';
// import { symbolSearch } from './Gallery';

export default function Attributes({ functions }) {
  const setWritten = (text) => {
    // Echoes to spoken text
    if (
      !functions?.selectedState?.spokenText ||
      functions?.selectedState?.spokenText === '' ||
      functions?.selectedState?.spokenText ===
        functions.selectedState.writtenText
    ) {
      // functions.setAttribute('spokenText', text);
      functions.setAttributeIndex('spokenText', text);
    }

    // Recomendations
    // if (!functions.editingNode.onlyText &&
    //     !functions.editingNode.image1.startsWith("livox_") &&
    //     !functions.editingNode.manualImage) {

    //     const recommended = symbolSearch(text)[0];
    //     if (recommended) {
    //         console.log(recommended.file.substring(0, recommended.file.length - 4))
    //         functions.setAttribute("image1", recommended.file.substring(0, recommended.file.length - 4));

    //     }
    // }

    // Write to text
    // functions.setAttribute('writtenText', text);
    functions.setAttributeIndex('writtenText', text);
  };

  return (
    <div className="section attributes">
      <h2 className="header">{i18n.t('Card Properties')}</h2>
      <div className="content">
        <div className="field">
          <h4>{i18n.t('Display Text')}</h4>
          <input
            value={functions.selectedState?.writtenText}
            onChange={(e) => setWritten(e.target.value)}
          />
        </div>
        <div className="field">
          <h4>{i18n.t('Spoken Phrase')}</h4>
          <input
            value={functions.selectedState?.spokenText}
            onChange={(e) =>
              functions.setAttributeIndex('spokenText', e.target.value)
            }
          />
        </div>
        <button
          type="button"
          className="field divide"
          onClick={() =>
            functions.setAttributeIndex(
              'isBigImage',
              !functions.selectedState.isBigImage
            )
          }
        >
          <span>{i18n.t('Expand image on click')}</span>
          <input
            type="checkbox"
            id="isBigImage"
            checked={functions.selectedState.isBigImage}
            readOnly
          />
        </button>
        <button
          type="button"
          className="field divide"
          style={{ marginTop: 10 }}
          onClick={() =>
            functions.setAttributeIndex(
              'onlyText',
              !functions.selectedState.onlyText
            )
          }
        >
          <span>{i18n.t('Only text')}</span>
          <input
            type="checkbox"
            id="onlyText"
            checked={functions.selectedState.onlyText}
            readOnly
          />
        </button>
      </div>
    </div>
  );
}
