export function initializeSettings(saved) {
  saved = saved || {};

  const defaultSettings = {
    // Ammount of collumns
    collumns: 4,
    // Ammount of rows
    rows: 4,
    // Passing mode
    // "swipe" - TODO, display as a navigation drawer
    // "fixed" TODO, display pagination on bottom
    // "item", display pagination as a item
    pageMode: 'item',
    // Size of the card text
    cardFontSize: 20,
    // Voice speed
    voiceSpeed: 100,
    // Pitch Rate
    pitchValue: 100,
    // Preferred Voice Actor
    voiceURI: '',
    // Prevent Actions when speaking
    utteranceFreezeActions: false,
    // Speak Navigation Arrows
    arrowUtterance: false,
    // Intellibar
    intellibar: false,
  };

  // Merge saved with defaults
  return { ...defaultSettings, ...saved };
}

export function onSettingsChange(settings) {
  // Update styling
  const root = document.documentElement;
  root.style.setProperty('--collumns-amount', settings.collumns);
  root.style.setProperty('--rows-amount', settings.rows);
  root.style.setProperty('--card-font', `${settings.cardFontSize}px`);
  root.style.setProperty(
    '--navigation-card',
    settings.printNavigationCard ? 'flex' : 'none'
  );
}
