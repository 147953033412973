import i18n from 'i18n/config';
import DataView from './data-view/DataView';
import { LICENSES_STATUS_OPTIONS } from '../constants';

export class Attribute {
  constructor(name, key, size, searchKey, render, orderKey = true) {
    if (typeof name === 'string' || name instanceof String) {
      this.name = i18n.t(name);
    } else {
      this.name = name;
    }
    this.key = key;
    this.size = size;
    if (searchKey === true) {
      this.searchKey = key;
    } else {
      this.searchKey = searchKey;
    }
    this.orderKey = orderKey;
    this.render = render;
  }
}

export const renderSearchTable = (that) => (
  <DataView
    endpoint={that.endpoint}
    attributes={that.attributes}
    title={that.title}
    links={that.links || []}
    id={that.id}
    params={that.params}
    parentData={(that.props.location && that.props.location.state) || {}}
    customQuery={that.customQuery || (() => null)}
  />
);

export const statusFilter = ({ handleChange }) => {
  const options = LICENSES_STATUS_OPTIONS.map((status) => (
    <option key={status.value} value={status.value}>
      {status.label}
    </option>
  ));
  return (
    <select name="status" onChange={handleChange} data-attr="status">
      <option value="">{i18n.t('Filter Status')}</option>
      {options}
    </select>
  );
};
