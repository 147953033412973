import { useCallback } from 'react';
import './styles.scss';

export function DropDownList({ options, onSelect, visible, updateVisible }) {
  const onSelectItem = useCallback(
    (option) => {
      updateVisible(false);
      onSelect(option);
    },
    [onSelect, updateVisible]
  );

  return (
    <div className="ddl-wrapper">
      <div className={`ddl-container${visible ? ' visible' : ''}`}>
        {options.map((option) => (
          <div
            className="ddl-item"
            key={option.name}
            onClick={() => onSelectItem(option.name)}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
}
