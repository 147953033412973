import { useState, useEffect } from 'react';
import {
  FaEdit,
  FaTrash,
  FaUpload,
  FaEye,
  FaHistory,
  FaPlus,
} from 'react-icons/fa';
import { Redirect, Link } from 'react-router-dom';
import i18n from 'i18n/config';
import { CLOUD_URL } from 'constants.js';
import { post, get } from 'utils/fetch';
import Media from 'utils/Media';
import './boards.scss';
import Modal from 'utils/modal';
import { Helmet } from 'react-helmet';
import img1 from 'media/stock/02.png';
import img2 from 'media/stock/03.png';
import img3 from 'media/stock/04.png';
import timeSince from 'utils/timeSince';

function BoardsTutorial({ boards }) {
  if (!boards || boards.length > 0) return null;
  return (
    <div className="tutorial">
      <h2>{i18n.t('Welcome to your boards page')}</h2>
      <div className="steps">
        <div className="step">
          <img src={img1} alt={i18n.t('Assistive Tecnology')} />
          <h3>{i18n.t('1. Create')}</h3>
          <p>
            {' '}
            {i18n.t(
              'To get started use the Livox Studio to create cards, upload images, music and videos'
            )}{' '}
          </p>
        </div>
        <div className="step">
          <img src={img2} alt={i18n.t('Assistive Tecnology')} />
          <h3>{i18n.t('2. Publish')}</h3>
          <p> {i18n.t('Submit you content to be published in the portal')} </p>
        </div>
        <div className="step">
          <img src={img3} alt={i18n.t('Assistive Tecnology')} />
          <h3>{i18n.t('3. Get feedback')}</h3>
          <p>
            {' '}
            {i18n.t(
              'Receive feedback and satisfaction on seeing your content being used all around the globe!'
            )}{' '}
          </p>
        </div>
      </div>
    </div>
  );
}

function NewContent() {
  const [newId, setNewId] = useState(null);

  const createNewContent = () => {
    get(`${CLOUD_URL}/content/new?language=${i18n.language}`).then(({ id }) => {
      setNewId(id);
    });
  };

  if (newId) {
    return <Redirect push to={`/studio/${newId}/edit`} />;
  }

  return (
    <button type="button" className="form-button" onClick={createNewContent}>
      <FaPlus /> {i18n.t('Create Board')}
    </button>
  );
}

function UploadZip() {
  const [loading, setLoading] = useState(false);
  const [textModal, setTextModal] = useState(false);

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  }

  const fileUpload = (file) => {
    const url = `${CLOUD_URL}/content/upload`;
    /*
        const formData = new FormData();
        formData.append('file', file);

        setLoading(true);
        setTextModal("Loading...")

        post(url, formData, 'multipart/form-data').then(res => {
            document.location.href = `/studio/${res.id}/edit`;
        }).catch(e => {
            console.log(e)

        });
        */

    setLoading(true);
    setTextModal('Loading...');

    const xhr = new XMLHttpRequest();

    const formData = new FormData();
    formData.append('file', file);

    xhr.responseType = 'json';
    xhr.open('POST', url);
    xhr.onload = () => {
      const res = xhr.response;
      if (res.success) {
        document.location.href = `/studio/${res.id}/edit`;
      } else if (res.error && res.error.code === 'LIMIT_FILE_SIZE') {
        setTextModal('The file you uploaded is too large.');
      } else if (res.errors.message) {
        setTextModal(`Error: ${res.errors.message}`);
      } else {
        setTextModal(
          i18n.t(
            'Sorry we just encoutered an unexpected error, please try again'
          )
        );
      }
    };

    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        const percentComplete = Math.ceil((e.loaded / e.total) * 100);
        if (percentComplete > 100) {
          setTextModal(
            `${i18n.t('Uploading...')} ${percentComplete}% (${formatBytes(
              e.total
            )})`
          );
        } else {
          setTextModal(i18n.t('Processing files...'));
        }
      }
    };

    xhr.onerror = () => {
      setTextModal(
        'Sorry we just encoutered an unexpected error, please try again'
      );
    };

    xhr.setRequestHeader(
      'Authorization',
      `JWT ${window.localStorage.getItem('token')}`
    );
    xhr.send(formData);
  };

  const handleCloseModal = () => {
    setLoading(false);
  };

  if (loading) {
    return (
      <Modal
        active={loading}
        onConfirm={handleCloseModal}
        textConfirm="Close"
        colorConfirm="#fb161b"
        textModal={textModal}
      />
    );
  }

  return (
    <button type="button" className="form-button" key="upload">
      <form id="zipUploadForm" onChange={(e) => fileUpload(e.target.files[0])}>
        <label htmlFor="overwriteZip">
          <FaUpload /> {i18n.t('Upload from .zip')}
        </label>
        <input
          type="file"
          id="overwriteZip"
          className="hidden"
          accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
        />
      </form>
    </button>
  );
}

function BoardActions({ board }) {
  const [targetContent, setTarget] = useState(false);

  const deleteContent = (id) => {
    post(`${CLOUD_URL}/content/${id}/delete`, {}).then((res) => {
      if (res.success) {
        window.location.reload();
      }
    });
  };

  return (
    <div className="actions">
      <Modal
        active={targetContent !== false}
        onCancel={() => setTarget(false)}
        textModal="Are you sure you want to delete this content?"
        onConfirm={() => deleteContent(targetContent)}
        textConfirm="Remove"
        colorConfirm="#fb161b"
      />
      <Link to={`/studio/${board.id}/view`} title={i18n.t('View')}>
        {' '}
        <button type="button">
          {' '}
          <FaEye />{' '}
        </button>{' '}
      </Link>
      <Link to={`/studio/${board.id}/edit`} title={i18n.t('Edit')}>
        {' '}
        <button type="button">
          {' '}
          <FaEdit />{' '}
        </button>{' '}
      </Link>
      <button
        type="button"
        onClick={() => setTarget(board.id)}
        title={i18n.t('Delete')}
      >
        {' '}
        <FaTrash />{' '}
      </button>
      {board.Publications && board.Publications.length > 0 ? (
        <Link to={`/boards/${board.id}/history`} title={i18n.t('History')}>
          {' '}
          <button type="button">
            {' '}
            <FaHistory />{' '}
          </button>{' '}
        </Link>
      ) : null}
    </div>
  );
}

export default function AccountBoards() {
  const [boards, setBoards] = useState(null);

  useEffect(() => {
    get(`${CLOUD_URL}/content/self`).then((res) => setBoards(res.results));
  }, []);

  if (boards === null) return 'Loading...';

  return (
    <div className="my-boards">
      <Helmet>
        <title>{i18n.t('My Boards')}</title>
      </Helmet>
      <div className="header">
        <h1 style={{ padding: '0 15px' }}> {i18n.t('My Boards')} </h1>
        <div className="actions">
          <UploadZip />
          <NewContent />
        </div>
      </div>
      <BoardsTutorial boards={boards} />
      <div className="boards">
        {boards.map((board) => (
          <div className="board" key={board.id}>
            <div className="content">
              <div className="img">
                <Link to={`/studio/${board.id}/edit`}>
                  <Media
                    filename={
                      board.data.states && board.data.states.length > 0
                        ? board.data.states[0].image1
                        : ''
                    }
                    placeholder
                    contentId={board.id}
                  />
                </Link>
              </div>
              <div className="info">
                <Link to={`/studio/${board.id}/edit`}>
                  <h3 className="title">
                    {' '}
                    {board.title || i18n.t('Untitled Content')}{' '}
                  </h3>
                </Link>
                <div className="date"> {timeSince(board.updatedAt)} </div>
              </div>
            </div>
            <BoardActions board={board} />
          </div>
        ))}
      </div>
    </div>
  );
}
