import { useState, useEffect } from 'react';
import { Redirect, NavLink } from 'react-router-dom';
import i18n from 'i18n/config';
import { CLOUD_URL } from 'constants.js';
import { get } from 'utils/fetch';
import logo from 'media/full-logo.png';
import darkLogo from 'media/livox-outline.png';

import './navbar.scss';
import { getUserLogin, getUserData, hasPermission } from 'utils/currentUser';
import parameterize from 'parameterize';
import {
  FiLogOut,
  FiDatabase,
  FiUser,
  FiClipboard,
  FiPlus,
  FiShield,
} from 'react-icons/fi';
import { ColorSchemeToggler, hasDarkMode } from 'utils/darkmode';
import { FaGooglePlay } from 'react-icons/fa';
import { ROUTES } from 'routes';
import UserImage from './userImage';

function AdminArea() {
  const [hasNew, setNew] = useState(false);

  useEffect(() => {
    if (hasPermission('Superuser')) {
      get(`${CLOUD_URL}/publications/pending`).then((res) =>
        setNew(res.count !== 0)
      );
    }
  }, []);

  if (!hasPermission('Superuser')) return null;

  return (
    <li>
      <NavLink
        to={
          hasNew
            ? ROUTES.STORE_ADMIN_PENDING.path
            : ROUTES.STORE_ADMIN_STATS.path
        }
        title={i18n.t('Promoted Content')}
      >
        <button type="button" className="form-button review">
          <FiShield />
          {hasNew ? <div className="dot" /> : null}
        </button>
      </NavLink>
    </li>
  );
}

function NewContent() {
  const [newId, setNewId] = useState(null);

  const createNewContent = () => {
    get(`${CLOUD_URL}/content/new?language=${i18n.language}`).then(({ id }) => {
      setNewId(id);
    });
  };

  if (newId) {
    return <Redirect push to={`/studio/${newId}/edit`} />;
  }

  return (
    <button type="button" className="form-button" onClick={createNewContent}>
      <FiPlus />
    </button>
  );
}

export default function StoreNavbar({ match }) {
  // Mobile menu
  const [expanded, setExpanded] = useState(false);

  let actions;
  if (getUserLogin()) {
    const userData = getUserData();
    actions = (
      <ul className="main-menu">
        <li>
          <NavLink
            to={{
              pathname:
                'https://play.google.com/store/apps/details?id=br.com.livox',
            }}
            target="_blank"
            className="main-link"
            title={i18n.t('Go to Play Store')}
          >
            <FaGooglePlay />
          </NavLink>
        </li>
        <AdminArea />

        <li>
          <NewContent />
        </li>
        <li>
          <div className="submenu">
            <textbox onClick={() => setExpanded(!expanded)}>
              <UserImage />
            </textbox>
            <ul
              className={`sections ${expanded ? 'show' : 'hide'}`}
              onClick={() => setExpanded(!expanded)}
            >
              <NavLink to="/store/self">
                <li>
                  <FiClipboard /> {i18n.t('My Boards')}
                </li>
              </NavLink>
              <NavLink
                to={`/store/profile/${parameterize(userData.full_name)}/${
                  userData.uuid
                }`}
              >
                <li>
                  <FiUser /> {i18n.t('My Profile')}
                </li>
              </NavLink>
              {getUserData().customer_name !== null ? (
                <NavLink to="/">
                  <li>
                    <FiDatabase /> {i18n.t('Admin Portal')}
                  </li>
                </NavLink>
              ) : null}
              <li className="color-toggler" style={{ cursor: 'pointer' }}>
                <ColorSchemeToggler />
              </li>
              <NavLink to="/logout">
                <li>
                  <FiLogOut /> {i18n.t('Log out')}
                </li>
              </NavLink>
            </ul>
          </div>
        </li>
      </ul>
    );
  } else {
    actions = (
      <ul className="main-menu">
        <li>
          <NavLink
            to={`/signup/?next=${window.location.pathname}`}
            className="main-link"
          >
            {i18n.t('Sign Up')}
          </NavLink>
        </li>

        <li>
          <NavLink
            to={`/?next=${window.location.pathname}`}
            className="main-link"
          >
            {i18n.t('Login')}
          </NavLink>
        </li>
      </ul>
    );
  }

  return (
    <div className="store-navbar">
      <div className="container">
        <div className="logo">
          <NavLink to="/store">
            <img
              src={hasDarkMode() ? darkLogo : logo}
              alt="logo"
              className="normal-img"
            />
          </NavLink>
        </div>
        {actions}
      </div>
    </div>
  );
}
