import React from 'react';
import { InputField, renderForm } from '../../utils/form';

export default class AuthForm extends React.Component {
  constructor(props) {
    super(props);

    this.endpoint = `/tokens/auth/v2/`;
    const state = {};
    state.fields = [
      new InputField('E-mail', 'username', false, { size: 1 }),
      new InputField('Password', 'password', true, {
        type: 'password',
        size: 1,
      }),
      new InputField('tokenRecaptcha', 'tokenRecaptcha', false, {
        type: 'hidden',
        size: 1,
      }),
    ];
    this.recaptchaType = 'LOGIN';
    this.actionName = 'Log In';
    this.state = state;
  }

  onFormSuccess(res) {
    this.props.loginSuccess(res.token);
  }

  render() {
    return renderForm(this);
  }
}
