import React from 'react';
import './admin.scss';
import { Switch, Route, NavLink } from 'react-router-dom';
import { ROUTES } from 'routes';
import { FiTrendingUp, FiLayout, FiStar, FiInbox } from 'react-icons/fi';
import ManageBoards from './Manage';
import StoreStats from './Stats';
import PendingApproval from './PendingApproval';
import StorePromoted from '../promoted/Promoted';

export default function StoreAdmin({ match }) {

    return (
        <div className="admin-area">
            <div className="admin-header">
                <ul className="picker">
                    <li>
                        <NavLink to={ROUTES.STORE_ADMIN_STATS.path}>
                            <FiTrendingUp />
                            <span>
                                Statistics
                            </span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={ROUTES.STORE_ADMIN_BOARDS.path}>
                            <FiLayout />
                            <span>
                                Boards
                            </span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={ROUTES.STORE_ADMIN_PROMOTED.path}>
                            <FiStar />
                            <span>
                                Promoted
                            </span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={ROUTES.STORE_ADMIN_PENDING.path}>
                            <FiInbox />
                            <span>
                                Pending
                            </span>
                        </NavLink>
                    </li>

                </ul>
            </div>
            <Switch>
                <Route exact path={ROUTES.STORE_ADMIN_BOARDS.path} component={ManageBoards} />
                <Route exact path={ROUTES.STORE_ADMIN_PROMOTED.path} component={StorePromoted} />
                <Route exact path={ROUTES.STORE_ADMIN_PENDING.path} component={PendingApproval} />
                <Route component={StoreStats} />
            </Switch>
        </div>

    )

}

