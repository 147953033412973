import { DEFAULT_URL } from '../constants';
import { getUserLogin } from './currentUser';

export const fetchData = (that) => {
  that.loading = true;
  get(that.endpoint).then((json) => {
    console.log('fetchData: ', json);
    that.setState({
      data: json,
      loading: false,
    });
  });
};

export const defaultState = () => ({
  loading: true,
  data: {
    results: [],
  },
});

export const get = async (endpoint, type = 'json') => {
  let url = DEFAULT_URL + endpoint;
  if (endpoint.startsWith('http://') || endpoint.startsWith('https://')) {
    url = endpoint;
  }

  const headers = {};
  if (getUserLogin()) {
    headers.Authorization = `JWT ${window.localStorage.getItem('token')}`;
  }

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((response) => {
        if (response.status === 200) {
          if (type === 'blob') {
            return response.blob();
          }
          return response.json();
        }
        return response.json().then((json) => reject(json));
      })
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        // toast.error("Error: could not retrieve " + url);
        console.log(`Unexpected error${error}`);
        reject(error);
      });
  });
};

export const post = async (
  endpoint,
  postData,
  contentType = 'application/json',
  method = 'POST'
) => {
  let data = postData;
  let responseOk;
  if (contentType === 'application/json') {
    data = JSON.stringify(postData);
  }

  let url = DEFAULT_URL + endpoint;
  if (endpoint.startsWith('http://') || endpoint.startsWith('https://')) {
    url = endpoint;
  }

  const headers = {
    'Content-Type': contentType,
  };

  if (getUserLogin()) {
    headers.Authorization = `JWT ${window.localStorage.getItem('token')}`;
  }

  // Form data bug, that if specified will break the uploading
  // https://github.com/github/fetch/issues/505
  if (contentType === 'multipart/form-data') {
    delete headers['Content-Type'];
  }

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: method || 'POST',
      headers,
      body: data,
    })
      .then((response) => {
        responseOk = response.ok;
        return response.json();
      })
      .then((json) => {
        if (responseOk) {
          resolve(json);
        } else {
          // .error(json.message);
          reject(json);
        }
      })
      .catch((error) => {
        console.log(`Unexpected error${error}`);
        reject(error);
      });
  });
};
