import { Attribute, renderSearchTable } from 'utils/dataview';
import { Link } from 'react-router-dom';
import i18n from 'i18n/config';
import { CLOUD_URL } from 'constants.js';
import Media from 'utils/Media';
import timeSince from 'utils/timeSince';
import { routePath, ROUTES } from 'routes';
import { FiStar } from 'react-icons/fi';
import parameterize from 'parameterize';
import { Component } from 'react';
import Author from '../common/Author';
import {
  EditButton,
  PromoteButton,
  DeleteButton,
} from '../publication/Publication';
import './admin.scss';

function PublishedContent({ data }) {
  return (
    <div>
      <div>
        <Link
          to={`${routePath(ROUTES.STORE_PUBLICATION, {
            title: parameterize(data.title),
            ContentId: data.ContentId,
          })}`}
        >
          {data.title}
        </Link>
      </div>

      <div style={{ color: 'var(--base-subtext)', fontSize: '14px' }}>
        {data.description}
      </div>
    </div>
  );
}

function PublishedMetadata({ data }) {
  const languages = {
    'en-US': `🇺🇸 ${i18n.t('English')}`,
    'pt-BR': `🇧🇷 ${i18n.t('Portuguese')}`,
    'ar-SA': `🇸🇦 ${i18n.t('Arabic')}`,
  };

  const lang = data.language;

  const styles = {
    Archived: '#c39a04',
    Pending: '#aeaeb2',
    Approved: '#1fa240',
    Rejected: '#ec2e24',
    Deleted: '#ec2e24',
    Canceled: '#f76700',
  };

  return (
    <div>
      <div className="bdge" style={{ color: styles[data.status] }}>
        {/* {i18n.t(data.status)} */}
      </div>
      <Author id={data.Content.authorId} />
      <div className="likes">
        <FiStar /> {data.Content.score.toFixed(1)}
      </div>

      <div style={{ color: 'var(--base-subtext)', fontSize: '14px' }}>
        {languages[lang]}
      </div>
      <div style={{ color: 'var(--base-subtext)', fontSize: '14px' }}>
        {data.Content.downloadsCount} downloads
      </div>
      <div style={{ color: 'var(--base-subtext)', fontSize: '14px' }}>
        {timeSince(data.createdAt)}
      </div>
    </div>
  );
}

export default class ManageBoards extends Component {
  constructor() {
    super();

    this.title = 'Manage Boards';
    this.endpoint = `${CLOUD_URL}/store/manage`;
    this.attributes = [
      new Attribute(
        'Cover Art',
        'coverImage',
        0.5,
        null,
        (image, { ContentId }) => (
          <Media
            filename={image}
            placeholder={false}
            contentId={ContentId}
            style={{ width: 90, height: 75 }}
          />
        ),
        false
      ),
      new Attribute('Title', 'title', 1.7, true, (_, data) => (
        <PublishedContent data={data} />
      )),
      new Attribute('Metadata', 'createdAt', 0.7, false, (_, data) => (
        <PublishedMetadata data={data} />
      )),
      new Attribute(
        'Actions',
        'id',
        0.7,
        null,
        (id, { ContentId }) => (
          <>
            <EditButton ContentId={ContentId} />
            <PromoteButton ContentId={ContentId} />
            <DeleteButton id={id} />
          </>
        ),
        false
      ),
    ];
  }

  //
  componentDidMount() {
    document.title = `${i18n.t(this.title)} - Livox`;
  }

  render() {
    return renderSearchTable(this);
  }
}
