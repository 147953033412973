import React, { useEffect, useState } from 'react';
import "./home.scss"
import { FaStore, FaBook, FaChartPie, FaEdit, FaPlay, FaUser } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'routes';
import { CLOUD_URL } from 'constants.js';
import { get } from 'utils/fetch';
import Card from 'components/store/common/card';
import user from 'media/user.png'
import { AppContext } from './Reducer';


export default function LivoxHome() {
    const { holder } = React.useContext(AppContext);

    const [recommended, setRecommended] = useState([])
    
    useEffect(() => {
        get(`${CLOUD_URL}/store/popular?lang=any`).then(res => setRecommended(res.results.slice(0,12)))
    }, []);
    
    return (
        <div className="home">

            <div className="tutorial">
                <div className="image">
                    <img src={user}/>
                </div>
                <div className="description">
                    <h4 className="mission"> Welcome to Livox </h4>

                    <p>
                        To start the process of alternative communication we made a tutorial on how to introduce Livox to you children!
                    </p>
                    <p>
                        Alternative communication, like a language, may seem hard to grasp but will be worth it in the long run, let's start with small steps that can make a big difference in your child's daily life and teach them how to use Livox.
                    </p>
                    <p>
                        A lot of our daily communication can be put in two words: Yes and No.
                    </p>
                    <p>
                        Let's try to communicate with yes or no questions! First by asking questions and answering yourself and then asking your children to answer them to you.
                    </p>
                    <p className="progress">
                        Yes or No responses obtained:
                    </p>
                    <div className="bar">
                        <div className="fill" />
                    </div>
                    <div className="score">
                        12/15
                    </div>

                <div className="controls">
                    <button>
                        Skip
                    </button>
                    <button disabled>
                        Next
                    </button>

                </div>

                </div>

            </div>

            {/* <div className="btn-grid">
                <div className="btn btn-play">
                    <NavLink to={ROUTES.LIVOX_WEB_PLAY.path}>
                        <FaPlay /> <span> Play </span>
                    </NavLink>
                    <NavLink to={ROUTES.LIVOX_WEB_EDIT.path} className="edit-btn">
                        <FaEdit /> <span> Editar </span>
                    </NavLink>
                </div>
                <div className="btn btn-store">
                    <NavLink to={ROUTES.LIVOX_WEB_STORE.path}>
                        <FaStore /> <span> Store </span>
                    </NavLink>
                </div>
                <div className="btn btn-user">
                    <NavLink to={ROUTES.LIVOX_WEB_STORE.path}>
                        <FaUser /> <span> {holder.selectedUser.name} </span>
                    </NavLink>
                </div>
                <div className="btn btn-tutorial">
                    <NavLink to={ROUTES.LIVOX_WEB_STORE.path}>
                        <FaBook /> <span> Manual </span>
                    </NavLink>
                </div>
                <div className="btn btn-settings">
                    <NavLink to={ROUTES.LIVOX_WEB_SETTINGS.path}>
                        <FaBook /> <span> Settings </span>
                    </NavLink>
                </div> */}
                {/* <button className="btn btn-store hidden">
                    <NavLink to={ROUTES.LIVOX_WEB_STORE.path}>
                        <FaStore /> <span> Store </span>
                    </NavLink>
                </button>
                <div className="btn-store">
                    <div className="btn-header">
                        <FaStore /> <span> Store </span>
                    </div>
                    <h3>Popular Content</h3>
                    <div className="boards hidden">
                        {
                            recommended.map(board => <Card board={board} key={board.id} />)
                        }
                    </div>
                </div>
                <button className="btn btn-stats">
                    <NavLink to={ROUTES.LIVOX_WEB_SETTINGS.path}>
                        <FaChartPie /> <span> Stats </span>
                    </NavLink>
                </button>
                <button className="btn btn-manual">
                    <NavLink to={ROUTES.LIVOX_WEB_SETTINGS.path}>
                        <FaBook /> <span> Manual </span>
                    </NavLink>
                </button> */}

            {/* </div> */}

        </div>
    )

}