import i18n from 'i18n/config';
import { Component } from 'react';

export default class DatePicker extends Component {
  setDaysFilter(e) {
    const selectedValue = parseInt(e.target.value, 10);
    this.props.handleDateChange(selectedValue);
  }

  render() {
    return (
      <div className="date-attributes">
        <label htmlFor="query-attribute"> {i18n.t('Period')} </label>
        <select onChange={this.setDaysFilter.bind(this)}>
          <option value="30">{i18n.t('30 days')}</option>
          <option value="45">{i18n.t('45 days')}</option>
          <option value="60">{i18n.t('2 months')}</option>
          <option value="180">{i18n.t('6 months')}</option>
          <option value="365">{i18n.t('1 year')}</option>
        </select>
      </div>
    );
  }
}
