import i18n from 'i18n/config';
import { useRef } from 'react';
import { FaPlay, FaStop, FaPrint, FaExpand } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import ReactToPrint from 'react-to-print';
import CategoryType from 'components/studio/categoryType';
import { AllBoardToPrint } from './allBoardToPrint/AllBoardToPrint';

export default function Controls({ functions }) {
  const componentRef = useRef();

  function toggleFullscreen() {
    const elem = document.querySelector('.livox-studio');
    const requestMethod =
      elem.requestFullScreen || elem.webkitRequestFullScreen;

    if (!document.fullscreenElement) {
      functions.setSidebar(null);
      requestMethod.call(elem);
    } else {
      document.exitFullscreen();
    }
  }

  function canPlayFullscreen() {
    const elem = document.querySelector('.livox-studio');
    return elem.requestFullScreen || elem.webkitRequestFullScreen;
  }

  function toggleModes() {
    if (functions.categoryType === CategoryType.GAME) {
      functions.setMode('game');
    } else if (functions.mode === 'view') {
      functions.setMode('edit');
      functions.setIntellibar([]);
    } else {
      functions.setSidebar(null);
      functions.setEdit(null);
      functions.setMode('view');
    }
  }

  if (functions.overrides.controlBar) {
    return <functions.overrides.controlBar functions={functions} />;
  }

  return (
    <div className="studio-controls">
      {functions.mode === 'view' &&
        functions.categoryType !== CategoryType.GAME && (
          <div>
            <ReactToPrint
              trigger={() => (
                <div className="control button-print store-hide">
                  <FaPrint /> {i18n.t('Print')}
                </div>
              )}
              content={() => componentRef.current}
            />
            <div style={{ display: 'none' }}>
              <AllBoardToPrint ref={componentRef} functions={functions} />
            </div>
          </div>
        )}
      {functions.mode === 'view' && canPlayFullscreen() ? (
        <div
          className="control button-print"
          onClick={() => toggleFullscreen()}
        >
          <FaExpand /> {i18n.t('Full Screen')}
        </div>
      ) : null}
      {functions.categoryType !== CategoryType.GAME && (
        <>
          <div className="control button-play store-hide" onClick={toggleModes}>
            {functions.mode === 'edit' ? (
              <>
                <FaPlay />
                <span>{i18n.t('Play')}</span>
              </>
            ) : (
              <>
                <FaStop />
                <span>{i18n.t('Back to Edit')}</span>
              </>
            )}
          </div>

          <div
            className={`control settings ${
              functions.sidebarMode === 'settings' ? 'active' : ''
            }`}
            onClick={() =>
              functions.sidebarMode === 'settings'
                ? functions.setSidebar(null)
                : functions.setSidebar('settings')
            }
          >
            <FiSettings />
          </div>
        </>
      )}
    </div>
  );
}
