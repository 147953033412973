import React from 'react';
import logo from 'media/navbar-logo.png';
import CreateUserForm from './CreateUserForm';
import { Route, Switch, NavLink } from "react-router-dom";
import "./setup.scss";
import { ROUTES } from 'routes';
import { FiCheck, FiCheckCircle, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import CreateHolderForm from './CreateHolderForm';
import { AppContext } from '../Reducer';

export default function Setup() {
    const { holder, dispatch } = React.useContext(AppContext);

    // Steps
    // Introduction - Show information
    // Create Holder - Check if it has a holder or create new holder
    // Create User - Check if it has a livox user on web or create new web user
    // User questionnaire

    let level = 1;
    if (holder.hasHolder){
        level = 2;
    } 
    if (Object.entries(holder.users).length > 0) {
        level = 3;
    }

    return (
        <div className="livox-app">
            <div className="setup">

                <div className="wizard">
                    <div className="mobile-back" onClick={() => window.history.back()}>
                        <FiChevronLeft />
                    </div>
                    <div className="steps">
                        <ol>
                            <li>
                                <NavLink exact to={ROUTES.LIVOX_WEB_SETUP.path}>
                                    Welcome
                                </NavLink>
                            </li>
                            <FiChevronRight />
                            <li>
                                <NavLink to={ROUTES.LIVOX_WEB_SETUP_HOLDER.path}>
                                    Setup Holder
                                </NavLink>
                            </li>
                            <FiChevronRight />
                            <li className={level < 2 ? "disabled" : ""}>
                                <NavLink to={ROUTES.LIVOX_WEB_SETUP_USER.path}>
                                    First user
                                </NavLink>
                            </li>
                            <FiChevronRight />
                            <li className={level < 3 ? "disabled" : ""}>
                                <NavLink to={ROUTES.LIVOX_WEB_SETUP_COMPLETE.path}>
                                    Start
                                </NavLink>
                            </li>
                        </ol>
                    </div>
                </div>

                <div className="content">

                    <Switch>
                        <Route exact path={ROUTES.LIVOX_WEB_SETUP_HOLDER.path} component={HolderScreen} />
                        <Route exact path={ROUTES.LIVOX_WEB_SETUP_USER.path} component={NewUserScreen} />
                        <Route exact path={ROUTES.LIVOX_WEB_SETUP_COMPLETE.path} component={FinishScreen} />
                        <WelcomeScreen />
                    </Switch>
                </div>

            </div>
        </div>
    )
}

function WelcomeScreen() {
    return (
        <div className="welcome-message">
            <img alt="livox logo" src={logo} />
            <h2> Welcome to Livox Web </h2>
            <p className="hype-text"> Accessible communication now anywhere and everywhere! </p>
            <p>
                This is a experimental version for web browsers, but with some
                features exclusive of Livox. If you want the full livox experience you can upgrade for the Android version anytime.
            </p>
            <p>
                This quick setup will guide you to the creation of the first user according to it's needs.
            </p>
            <div className="btn-area">
                <div />

                <NavLink exact to={ROUTES.LIVOX_WEB_SETUP_HOLDER.path}>
                    <button className="button">
                        Next
                    </button>
                </NavLink>
            </div>
        </div>
    )
}

function NewUserScreen() {
    const { holder } = React.useContext(AppContext);

    if (Object.entries(holder.users).length > 0){
        return (
            <div className="welcome-message">
                <FiCheckCircle/>
                <h2> Congratulations </h2>
                <p> You have created your first user! If you want to add another one in the future you will be able to do so</p>
            </div>
        )
    }

    return (
        <div className="welcome-message">
            <h2> Create your first user </h2>
            <CreateUserForm />
        </div>
    )
}

function HolderScreen() {
    return (
        <div className="welcome-message">
            <h2> Confirm your credentials </h2>
            <p style={{textAlign: "center"}}> Please fill out your information (responsible for Livox)</p>
            <CreateHolderForm />
        </div>
    )
}

function FinishScreen() {
    const { dispatch } = React.useContext(AppContext);

    function finishSetup(){
        dispatch({ type: "CHANGE_APP_DATA", payload: { hasFinishedTutorial: true }});
    }

    return (
        <div className="last-message">
            <h2> You're all set! </h2>
            <p>
                You successfully create your first user!
            </p>
            <p>
                For a better experience we strongly recommend to install this as a desktop app, doing so will make Livox faster and unlock new features (like working even offline!)
            </p>
            <div className="btn-area">
                <button className="button" onClick={finishSetup} >
                    Start
                </button>
            </div>
        </div>
    )
}