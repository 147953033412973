import React, { useState } from "react";
import { FiMoon, FiSun } from "react-icons/fi";
import i18n from "i18n/config";

export function setPreferedMode() {
  const preferedScheme = window.localStorage.getItem("colorScheme");
  if (preferedScheme) {
    setMode(preferedScheme);
  } else if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    setMode("dark");
  } else {
    setMode("light");
  }
}

export function toggleDarkMode() {
  const preferedScheme = window.localStorage.getItem("colorScheme");
  const newScheme = preferedScheme === "dark" ? "light" : "dark";
  setMode(newScheme);
  return newScheme;
}

export function setMode(mode) {
  window.localStorage.setItem("colorScheme", mode);
  document.documentElement.className = mode;
}

export function hasDarkMode() {
  return window.localStorage.getItem("colorScheme") === "dark";
}

export function ColorSchemeToggler() {
  const [mode, setMode] = useState(
    window.localStorage.getItem("colorScheme") || "light"
  );

  function toggle() {
    setMode(toggleDarkMode());
  }

  return (
    <div className={`color-toggler ${mode}`} onClick={toggle}>
      {mode === "dark" ? (
        <>
          <FiSun /> {i18n.t("Light")}
        </>
      ) : (
        <>
          <FiMoon /> {i18n.t("Dark")}
        </>
      )}
    </div>
  );
}
